import { useEffect } from 'react';

import useTimeStandardsDwData from '../../../state/topTimes/timeStandardsDw/UseTimeStandardsDwData';

// import { DEFAULT_ID } from '../../../../common/utils/Constants';
import { compareNumerical } from '../../../../common/utils/SortingUtils';

const useTimeStandardsDwDropdown = (organizationCode) => {
  const { timeStandardsDwState, getTimeStandardsDw, setTimeStandardsDwState
  } = useTimeStandardsDwData();

  useEffect(() => {
    if (organizationCode) {
      if (timeStandardsDwState.isArrayLoaded !== true) {
        getTimeStandardsDw(organizationCode);
      } else if (timeStandardsDwState.isArrayLoaded === true) {
        const sortedData = timeStandardsDwState.arrayData.sort((a, b) => compareNumerical(a.sortOrder, b.sortOrder) * -1);
        const options = sortedData.map(x => { return { id: x.timeStandardKey, name: x.timeStandardName } });
        // options.unshift({ id: DEFAULT_ID, name: '--' });

        setTimeStandardsDwState({
          ...timeStandardsDwState,
          areOptionsLoaded: true,
          options
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardsDwState.isArrayLoaded, organizationCode]);

  return {
    timeStandardsDwState
  };
};

export default useTimeStandardsDwDropdown;