import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getEventCompetitionGendersDwData = (state, setState) => {
  const api = initApi(API_NAMES.COMMON, state, setState);
  const url = `/EventCompetitionGenderType/DW`;

  return api?.executeArray ? api.executeArray(url, 'GET') : null;
};

const INITIAL_EVENTS_COMPETITION_GENDERS_DW_STATE = {
  ...BASIC_INITIAL_STATE,
  areOptionsLoaded: false,
  options: []
};

const EventCompetitionGendersDwData = {
  INITIAL_EVENTS_COMPETITION_GENDERS_DW_STATE,
  getEventCompetitionGendersDwData
};

export default EventCompetitionGendersDwData;