//import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';
import { BASIC_INITIAL_STATE } from '../../../utils/HttpHelper';

import getRecordGroupData from './GetRecordGroupData';
import getRecordGroupByOrgUnitIdData from './GetRecordGroupByOrgUnitIdData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  options: [],
  isOptionsLoaded: false
};

const RecordGroupData = {
  INITIAL_STATE, getRecordGroupData, getRecordGroupByOrgUnitIdData
};

export default RecordGroupData;