import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const IndividualBackstrokeEventRowsSmall = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <div className={global.TableRow} key={"50BK"}>
      <div className={global.TableRowHead}>50 BK
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId11FSwimTime"
          error={errorState.eventId11FSwimTime}
          message={errorState.eventId11FSwimTime}
          value={formState.eventId11FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId11FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId38FSwimTime"
          error={errorState.eventId38FSwimTime}
          message={errorState.eventId38FSwimTime}
          value={formState.eventId38FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId38FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId65FSwimTime"
          error={errorState.eventId65FSwimTime}
          message={errorState.eventId65FSwimTime}
          value={formState.eventId65FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId65FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId11MSwimTime"
          error={errorState.eventId11MSwimTime}
          message={errorState.eventId11MSwimTime}
          value={formState.eventId11MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId11MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId38MSwimTime"
          error={errorState.eventId38MSwimTime}
          message={errorState.eventId38MSwimTime}
          value={formState.eventId38MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId38MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId65MSwimTime"
          error={errorState.eventId65MSwimTime}
          message={errorState.eventId65MSwimTime}
          value={formState.eventId65MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId65MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"100BK"}>
      <div className={global.TableRowHead}>100 BK
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId12FSwimTime"
          error={errorState.eventId12FSwimTime}
          message={errorState.eventId12FSwimTime}
          value={formState.eventId12FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId12FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId39FSwimTime"
          error={errorState.eventId39FSwimTime}
          message={errorState.eventId39FSwimTime}
          value={formState.eventId39FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId39FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId66FSwimTime"
          error={errorState.eventId66FSwimTime}
          message={errorState.eventId66FSwimTime}
          value={formState.eventId66FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId66FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId12MSwimTime"
          error={errorState.eventId12MSwimTime}
          message={errorState.eventId12MSwimTime}
          value={formState.eventId12MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId12MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId39MSwimTime"
          error={errorState.eventId39MSwimTime}
          message={errorState.eventId39MSwimTime}
          value={formState.eventId39MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId39MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId66MSwimTime"
          error={errorState.eventId66MSwimTime}
          message={errorState.eventId66MSwimTime}
          value={formState.eventId66MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId66MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"200BK"}>
      <div className={global.TableRowHead}>200 BK
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId13FSwimTime"
          error={errorState.eventId13FSwimTime}
          message={errorState.eventId13FSwimTime}
          value={formState.eventId13FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId13FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId40FSwimTime"
          error={errorState.eventId40FSwimTime}
          message={errorState.eventId40FSwimTime}
          value={formState.eventId40FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId40FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId67FSwimTime"
          error={errorState.eventId67FSwimTime}
          message={errorState.eventId67FSwimTime}
          value={formState.eventId67FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId67FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId13MSwimTime"
          error={errorState.eventId13MSwimTime}
          message={errorState.eventId13MSwimTime}
          value={formState.eventId13MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId13MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId40MSwimTime"
          error={errorState.eventId40MSwimTime}
          message={errorState.eventId40MSwimTime}
          value={formState.eventId40MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId40MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId67MSwimTime"
          error={errorState.eventId67MSwimTime}
          message={errorState.eventId67MSwimTime}
          value={formState.eventId67MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId67MSwimTime', value) }} /></div>
      </div>
    </div>
  </Fragment>
);

export default IndividualBackstrokeEventRowsSmall;