import useEventData from '../../../../state/event/UseEventData';

const useTimesEvents = () => {
  const { eventState } = useEventData();

  return {
    eventState
  };
};

export default useTimesEvents;