import { isValidDate } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';

export const localValidate = (formState) => {
  const effectiveDate = new Date(formState.effectiveDate);
  const expirationDate = new Date(formState.expirationDate);
  let errors = {};

  if (formState.orgUnit.length === 0) {
    errors.orgUnit = 'Organization is required';
  }

  if (formState.standardName.trim() === '') {
    errors.standardName = 'Time Standard Name is required';
  } else if (formState.standardName.trim().length > 100) {
    errors.standardName = 'Time Standard Name cannot exceed 100 characters';
  }

  if (formState.standardDescription.trim() === '') {
    errors.standardDescription = 'Description is required';
  } else if (formState.standardDescription.trim().length > 200) {
    errors.standardDescription = 'Description cannot exceed 200 characters';
  }

  if (formState.timeStandardTypeId < 0) {
    errors.timeStandardTypeId = 'Time Standard Type is required';
  }

  if (formState.effectiveDate === Constants.BLANK_DATE_STRING || formState.effectiveDate === '') {
    errors.effectiveDate = 'Qualifying Start Date is required';
  } else if (!isValidDate(formState.effectiveDate)) {
    errors.effectiveDate = 'Qualifying Start Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.effectiveDate = 'Qualifying Start Date must be before the Qualifying End Date';
  }

  if (formState.expirationDate === Constants.BLANK_DATE_STRING || formState.expirationDate === '') {
    errors.expirationDate = 'Qualifying End Date is required';
  } else if (!isValidDate(formState.expirationDate)) {
    errors.expirationDate = 'Qualifying End Date must be a valid date';
  } else if (expirationDate < effectiveDate || formState.effectiveDate === formState.expirationDate) {
    errors.expirationDate = 'Qualifying End Date must be after the Qualifying Start Date';
  }

  return errors;
};

const TimeStandardsDetailValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimeStandardsDetailValidation;