import Input from '../../../../common/components/inputs/Input';

import useLegsRow from './UseLegsRow';

import global from '../../../../common/components/GlobalStyle.module.css';

const LegRow = ({ rowState, onRowChanged }) => {
  const { onTimeChanged } = useLegsRow(rowState, onRowChanged);
  return (
    <tr>
      <td style={{ width: 100 }}>{rowState.legNumber}</td>
      <td>{rowState.competitor}</td>
      <td>{rowState.eventDescription}</td>
      <td style={{ width: 100 }}>
        <Input
          label=""
          name={`legTime${rowState.legNumber}`}
          value={rowState.legTime}
          onChange={onTimeChanged} />
      </td>
    </tr>
  );
};

const RelayLegsLargeGrid = ({ timeState, relayLegsState, onRowChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Relay Leg</th>
          <th>Competitor</th>
          <th>Event</th>
          <th>Time</th>
        </tr>
      </thead>
      <tbody>
        {
          timeState.isArrayLoading === true || relayLegsState.relayLegs === undefined
            ? (<tr>
              <td colSpan="4">Loading...</td>
            </tr>)
            :
            relayLegsState.relayLegs.length > 0 ?
              relayLegsState.relayLegs.map((rowState, i) => <LegRow onRowChanged={onRowChanged} rowState={rowState} key={i} />)
              :
              (<tr>
                <td colSpan="4">No Splits</td>
              </tr>)
        }
      </tbody>
    </table>
  );
};

export default RelayLegsLargeGrid;