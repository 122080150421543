const useLegsRow = (rowState, onRowChanged) => {
  const onTimeChanged = (value) => {
    onRowChanged({
      ...rowState,
      legTime: value
    });
  };

  return {onTimeChanged};
};

export default useLegsRow;