export const localValidate = (formState) => {
  let errors = {};

  if (formState.timeStandardName.trim() !== '' && formState.timeStandardId < 0) {
    errors.timeStandardId = 'Must be a valid Time Standard Name';
  }

  return errors;
};

const SearchMyTimeStandardsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchMyTimeStandardsValidation;