import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import NavLinks from '../NavLinks';

const useOrgUnitSelection = () => {
  const { RECORDS_ADMIN_ROLE_GROUP_ID } = useEnvironmentVariableData();
  return {
    NavLinks,
    RECORDS_ADMIN_ROLE_GROUP_ID,
  };
};

export default useOrgUnitSelection;