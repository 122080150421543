import { useContext } from 'react';

import TimesAnomalyData from './TimesAnomalyData';
import { TimesAnomalyStateContext } from './TimesAnomalyContextProvider';

const useTimesAnomalyData = () => {
  const [timesAnomalyState, setTimesAnomalyState] = useContext(TimesAnomalyStateContext);

  const searchTimesAnomaly = (orgUnitId) => {
    TimesAnomalyData.searchTimesAnomaly(orgUnitId, timesAnomalyState, setTimesAnomalyState);
  };

  const putTimesAnomalyStatusUpdate = (swimTimeAnomalyId, orgUnitId) => {
    TimesAnomalyData.putTimesAnomalyStatusUpdate(swimTimeAnomalyId, orgUnitId, timesAnomalyState, setTimesAnomalyState);
  }

  const deleteTimesAnomaly = (swimTimeAnomalyId, orgUnitId) => {
    TimesAnomalyData.deleteTimesAnomaly(swimTimeAnomalyId, orgUnitId, timesAnomalyState, setTimesAnomalyState);
  }

  return {
    timesAnomalyState,
    searchTimesAnomaly,
    putTimesAnomalyStatusUpdate,
    deleteTimesAnomaly
  };
};

export default useTimesAnomalyData;