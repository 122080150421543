import { useNavigate, useLocation } from '../../../common/wrappers/ReactRouterDom';
import NavLinks from './NavLinks';

import useSelectOrgUnitData from '../../../common/state/selectOrgUnit/UseSelectOrgUnitData';

const useTimesAnomalyContextView = () => {
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const navigate = useNavigate();
  const location = useLocation();

  const onClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    navigate(NavLinks.TIMES_ANOMALY_SEARCH);
  }

  return {
    location,
    selectOrgUnitState,
    backToLabel: 'Back to Time Anomaly Search',
    onClicked
  };
};

export default useTimesAnomalyContextView;