import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../common/wrappers/ReactRouterDom';

import { navLinks } from './UseManageTimes';

import useTimeData from '../../../common/state/time/UseTimeData';
import useMemberPersistentStorage from '../../../common/state/memberPersistentStorage/UseMemberPersistentStorage';
import useGlobalRoutesData from '../../../common/state/security/UseGlobalRoutesData';

import Constants from '../../../common/utils/Constants';
import formatCrossUiRoute, { navigateToCrossUiRoute } from '../../../common/utils/FormatCrossUiRoute';
import { formatDate } from '../../../common/utils/DateFunctions';

const BACK_TO_LABELS = {
  timesBackToLabel: 'Back to Time Search',
  meetBackToLabel: 'Back to Meet',
  memberBackToLabel: 'Back to Member'
};

const INITIAL_STATE = {
  competitorNames: '',
  meetName: '',
  meetStartDate: '',
  meetEndDate: '',
  dataIsLoading: true
};

const useManageTimesContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getGlobalRoute } = useGlobalRoutesData();
  const { setPersistentStorage } = useMemberPersistentStorage();
  const { timeState, timeFiltersState, clearObjData } = useTimeData();
  const [state, setState] = useState(INITIAL_STATE);

  const goToTimeSearch = () => {
    clearObjData();
    navigate(navLinks.SEARCH);
  };

  const goToMeet = () => {
    const relativePath = `/meet/byid/${timeState.objData.meetId}/times`;
    const formattedUrlObject = formatCrossUiRoute(Constants.UI_PROJECT_NAMES.MEET, relativePath, false);
    window.location.href = formattedUrlObject.route;
  };

  const goToMember = () => {
    const returnUrl = getGlobalRoute('TIMES_BY_ID')?.route?.replace(':timeId', timeState.objData?.swimTimeId)?.replace(':returnSystem', 'times');
    setPersistentStorage(
      timeState.objData?.personId,
      import.meta.env.VITE_PROJECT_NAME,
      `${returnUrl}?pill=athleteroster`,
      'Back to Club'
      );

    const routeObj = getGlobalRoute('MEMBER_INFO');
    navigateToCrossUiRoute(routeObj?.uIProjectName, routeObj?.route, navigate);
  };

  const onHomeClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.DETAIL);
  };

  const onClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    switch ((timeFiltersState.backToSystem || '').toLowerCase()) {
      case 'meet':
        goToMeet();
        break;

      case 'member':
        goToMember();
        break;

      default:
        goToTimeSearch();
        break;
    }
  };

  const deriveAgeAtMeet = (personsBirthDate, meetEndDate) => {
    const _MS_PER_YEAR = 1000 * 60 * 60 * 24 * 365;
    const dtBirthDate = new Date(personsBirthDate);
    const dtMeetEndDate = new Date(meetEndDate);
    const utc1 = Date.UTC(dtBirthDate.getFullYear(), dtBirthDate.getMonth(), dtBirthDate.getDate());
    const utc2 = Date.UTC(dtMeetEndDate.getFullYear(), dtMeetEndDate.getMonth(), dtMeetEndDate.getDate());
    const dateDiff = (utc2 - utc1);
    return Math.floor(dateDiff / _MS_PER_YEAR);
  };

  const formatCompetitorsAsString = (timeRelay, meetEndDate) => {
    const competitors = timeRelay.map((teamMembers) => {
      const teamMembersAge = deriveAgeAtMeet(teamMembers.person.birthDate, meetEndDate);
      return `${teamMembers.person.firstName} ${teamMembers.person.lastName} (${teamMembersAge})`;
    });
    return competitors.join(', ');
  };

  const getBackToLabel = () => {
    return BACK_TO_LABELS[`${(timeFiltersState.backToSystem || '').toLowerCase()}BackToLabel`] || BACK_TO_LABELS.timesBackToLabel;
  };

  useEffect(() => {
    if (Object.keys(timeState.objData).length > 0 && timeState.isObjLoaded) {
      const timeObj = { ...timeState.objData };
      let competitorNames = state.competitorNames;
      if ((Array.isArray(timeObj.swimTimeRelay) && timeObj.swimTimeRelay.length > 0 && timeObj.meet !== null)
        || timeObj.person === null) {
        competitorNames = formatCompetitorsAsString(timeObj.swimTimeRelay, timeObj.meet.endDate);
      } else {
        const teamMembersAge = deriveAgeAtMeet(timeObj.person.birthDate, timeObj.meet.endDate);
        competitorNames = `${timeObj.person.firstName} ${timeObj.person.lastName} (${teamMembersAge})`;
      }
      setState({
        ...state,
        competitorNames,
        meetName: timeObj.meet?.meetName || timeObj.meet?.meetAbbreviation,
        meetStartDate: timeObj.meet?.startDate ? formatDate(timeObj.meet?.startDate) : '',
        meetEndDate: timeObj.meet?.endDate ? formatDate(timeObj.meet?.endDate) : '',
        dataIsLoading: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState.isObjLoaded]);

  return {
    state,
    timeState,
    backToLabel: getBackToLabel(),
    onClicked,
    onHomeClicked: location.pathname === '/' ? undefined : onHomeClicked
  };
};

export default useManageTimesContextView;