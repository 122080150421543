import React, { Fragment } from 'react';

import useTimeStandardsContextView from './UseTimeStandardsContextView';

import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';

import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';

const TimeStandardsContextView = ({ children }) => {
  const {
    location,
    timeStandardState,
    backToLabel,
    onClicked
  }
    = useTimeStandardsContextView();

  if (!timeStandardState || (Object.keys(timeStandardState.objData).length === 0 && timeStandardState.isObjLoading !== true &&
    !location.state?.timeStandardId && !location.state?.timeStandardAgeGroupId)) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {children}
    </Fragment >
  );
};

export default TimeStandardsContextView;