import { Fragment } from "react";

import useTopTimesClubSearch from "./UseTopTimesClubSearch";

import SearchTopTimes from "../../../components/searchTopTimes/SearchTopTimes";

const TopTimesClubSearch = () => {
  const { routeName, orgUnitId, orgUnitName } = useTopTimesClubSearch();

  return (
    <Fragment>
      <SearchTopTimes pageTitle={'Club Top Times Search'} routeName={routeName} clubOrgUnitObj={{ orgUnitId, orgUnitName }} />
    </Fragment>
  );
};

export default TopTimesClubSearch