import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import validate from './TimeStandardsDetailValidation';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';

import useTimeStandardTypeData from '../../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useOrgUnitData from '../../../../common/state/orgUnit/UseOrgUnitData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatDate } from '../../../../common/utils/DateFunctions';

import NavLinks from '../NavLinks';

const useTimeStandardDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({ tryRedirect: false, setInitialState: true });
  const { timeStandardState, clearObjData, getTimeStandard, postTimeStandard, putTimeStandard } = useTimeStandardData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { orgUnitState } = useOrgUnitData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged, onFormValueChanged, setFormState,
    setFormData, setIsDirty, isSubmitting } = useForm(getInitialFormState, submitFormCallback, validate);

  const createTimeStandardObj = () => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    timeStandardCopy = {
      ...timeStandardCopy,
      standardName: formState.standardName.trim(),
      standardDescription: formState.standardDescription.trim(),
      orgUnitId: formState.orgUnit[0].id,
      timeStandardTypeId: formState.timeStandardTypeId,
      effectiveDate: formState.effectiveDate,
      expirationDate: formState.expirationDate,
      sortOrder: parseInt(formState.sortOrder),
      timeStandardAgeGroup: timeStandardCopy.timeStandardAgeGroup ?
        timeStandardCopy.timeStandardAgeGroup : []
    }

    return timeStandardCopy;
  }

  function submitFormCallback(formState) {
    //Edit
    if (formState.timeStandardId > 0) {
      putTimeStandard(timeStandardState.objData.timeStandardId, createTimeStandardObj());
    }
    //Add
    else {
      postTimeStandard(createTimeStandardObj());
    }
    setState({ ...state, tryRedirect: true });
  }

  const onOrgUnitChanged = (newValue) => {
    setFormState({
      ...formState,
      orgUnit: newValue
    });
    setIsDirty(true);
  };

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    clearObjData();
    navigate(NavLinks.TIME_STANDARDS_SEARCH);
  }

  useEffect(() => {
    //Edit
    if (location.state && location.state.timeStandardId > 0) {
      setFormData({
        ...formState,
        timeStandardId: location.state.timeStandardId
      });
      getTimeStandard(location.state.timeStandardId);
    }
    //Add
    else {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  useEffect(() => {
    if (Object.keys(timeStandardState.objData).length > 0
      && location.state && location.state.timeStandardId > 0) {
      setFormData({
        ...formState,
        timeStandardId: timeStandardState.objData.timeStandardId,
        orgUnit: [{ id: timeStandardState.objData.orgUnitId, name: timeStandardState.objData.orgUnit.orgUnitName }],
        standardName: timeStandardState.objData.standardName || '',
        standardDescription: timeStandardState.objData.standardDescription || '',
        timeStandardTypeId: timeStandardState.objData.timeStandardTypeId || Constants.DEFAULT_ID,
        timeStandardTypeName: timeStandardState.objData.timeStandardType?.typeName || '',
        sortOrder: timeStandardState.objData.sortOrder >= 0 ? timeStandardState.objData.sortOrder : '',
        effectiveDate: formatDate(timeStandardState.objData.effectiveDate) || Constants.BLANK_DATE_STRING,
        expirationDate: formatDate(timeStandardState.objData.expirationDate) || Constants.BLANK_DATE_STRING
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData])

  useEffect(() => {
    // Handle repopulating time standard type on the form
    if (Object.keys(timeStandardState.objData).length > 0
      && location.state && location.state.timeStandardId > 0 && state.setInitialState === true) {
      if (state.setInitialState === true && timeStandardTypeState.isArrayLoaded === true && timeStandardTypeState.isOptionsLoaded === true && orgUnitState.isArrayLoaded === true) {
        if ((timeStandardState.objData.orgUnit && formState.orgUnit[0]?.orgUnitId === timeStandardState.objData.orgUnit[0]?.id && timeStandardTypeState.orgUnitId === formState.orgUnit[0]?.id) ||
          (timeStandardState.objData.orgUnit === undefined && formState.orgUnit.length === 0 && timeStandardTypeState.orgUnitId === '')) {
          setState({ ...state, setInitialState: false });
          setFormData({
            ...formState,
            timeStandardTypeId: timeStandardState.objData.timeStandardTypeId || Constants.DEFAULT_ID,
            timeStandardTypeName: timeStandardState.objData.timeStandardType?.typeName || '',
          });
          setIsDirty(true);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData, formState.orgUnit, timeStandardTypeState, orgUnitState.isArrayLoaded]);

  useEffect(() => {
    if (state.tryRedirect === true && timeStandardState.isObjLoaded === true && isSubmitting === false) {
      navigate(NavLinks.TIME_STANDARDS_AGE_GROUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, timeStandardState.isObjLoaded, isSubmitting])

  function getInitialFormState() {
    return {
      timeStandardId: Constants.DEFAULT_ID,
      orgUnitName: '',
      orgUnit: [],
      standardName: '',
      standardDescription: '',
      timeStandardTypeId: Constants.DEFAULT_ID,
      timeStandardTypeName: '',
      sortOrder: '',
      effectiveDate: Constants.BLANK_DATE_STRING,
      expirationDate: Constants.BLANK_DATE_STRING
    };
  };

  return {
    timeStandardState,
    orgUnitState,
    timeStandardTypeState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onOrgUnitChanged,
    onCancelClicked
  }
};

export default useTimeStandardDetail;
