import React, { createContext, useState } from 'react';

import RecordGroupData from './RecordGroupData';

export const RecordGroupStateContext = createContext();

const RecordGroupContextProvider = ({ children }) => {
  const stateHook = useState(RecordGroupData.INITIAL_STATE);

  return (
    <RecordGroupStateContext.Provider value={stateHook}>
      {children}
    </RecordGroupStateContext.Provider>
  );
};

export default RecordGroupContextProvider;