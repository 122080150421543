import React, { Fragment } from 'react';

import RelayLegsLargeGrid from './RelayLegsLargeGrid';
import RelayLegsSmallGrid from './RelayLegsSmallGrid';

const RelayLegsGrid = ({ timeState, relayLegsState, onRowChanged }) => {
  return (
    <Fragment>
      <RelayLegsLargeGrid
        timeState={timeState}
        relayLegsState={relayLegsState}
        onRowChanged={onRowChanged}
      />
      <RelayLegsSmallGrid
        timeState={timeState}
        relayLegsState={relayLegsState}
        onRowChanged={onRowChanged}
      />
    </Fragment>
  )
};

export default RelayLegsGrid;