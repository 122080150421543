import { useState } from 'react';

import RecordEligibleTimesData from './RecordEligibleTimesData';

const useRecordEligibleTimesData = () => {
  const [recordEligibleTimesState, setRecordEligibleTimesState] = useState(RecordEligibleTimesData.INITIAL_STATE);

  const getRecordEligibleTimes = (recordListInstanceId) => RecordEligibleTimesData.getRecordEligibleTimesData(
      recordListInstanceId, 
      recordEligibleTimesState, 
      setRecordEligibleTimesState);

  return {
    recordEligibleTimesState,
    getRecordEligibleTimes
  };
};

export default useRecordEligibleTimesData;