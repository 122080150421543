import { useEffect } from 'react';

import useTimeStandardTypeData from '../../../common/state/timeStandardType/UseTimeStandardTypeData';

const useTimeStandardTypeDropdown = (orgUnitId) => {
  const { timeStandardTypeState, getTimeStandardTypes, getTimeStandardTypesByOrgUnitId } = useTimeStandardTypeData();

  useEffect(() => {
    if (timeStandardTypeState.isArrayLoading !== true && ((orgUnitId === '' && orgUnitId !== timeStandardTypeState.orgUnitId) ||
      (orgUnitId === '' && timeStandardTypeState.isArrayLoaded !== true))) {
      getTimeStandardTypes();
    }
    if (timeStandardTypeState.isArrayLoading !== true && ((orgUnitId !== '' && orgUnitId !== timeStandardTypeState.orgUnitId) ||
      (orgUnitId !== '' && timeStandardTypeState.isArrayLoaded !== true))) {
      getTimeStandardTypesByOrgUnitId(orgUnitId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, timeStandardTypeState.orgUnitId, timeStandardTypeState.isArrayLoading]);

  return {
    timeStandardTypeState
  };
};

export default useTimeStandardTypeDropdown;