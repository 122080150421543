const NavLinks = {
  MY_TIME_STANDARDS_ROOT: '/mytimestandards',
  MY_TIME_STANDARDS_SELECTION: '/mytimestandards/selection',
  MY_TIME_STANDARDS_SEARCH: '/mytimestandards/search',
  MY_TIME_STANDARDS_DETAIL: '/mytimestandards/detail',
  MY_TIME_STANDARDS_AGE_GROUP: '/mytimestandards/agegroup',
  MY_TIME_STANDARDS_AGE_GROUP_ADD: '/mytimestandards/agegroup/add',
  MY_TIME_STANDARDS_AGE_GROUP_CUTS: '/mytimestandards/agegroup/cuts'
};

export default NavLinks;