import React, { Fragment } from 'react';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import ReadOnly from '../../../../common/components/readOnly/ReadOnly';

const TimesDeletionConfirmation = ({ state, formState, onDelete, onClosePopup }) => {

  return (
    <Fragment>
      <PopUpModal
        title={"Delete Time Confirmation"}
        displayPopUp={state.showPopup}
        onModalCanceled={onClosePopup}>
        <Fragment>
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Event"
                name="eventName"
                value={formState.eventName} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Event Gender"
                name="eventGenderName"
                value={formState.eventGenderName} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Session"
                name="sessionName"
                value={formState.sessionName} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Entry Time"
                name="entryTime"
                value={formState.entryTime} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Swim Time"
                name="swimTime"
                value={formState.swimTime} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Swim Date"
                name="swimDate"
                value={formState.swimDate} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Place"
                name="place"
                value={formState.place} />
            </div>
            <div className="col-xs-12 col-sm-6">
              <ReadOnly
                label="Relay Lead-Off?"
                name="isRelayLeadOff"
                value={formState.isRelayLeadOff === true ? "Yes" : "No"}
              />
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className="col-xs-12">
              <p><b>Disclaimer: Time is in record tracking system and will be deleted.</b></p>
              <p><b>Are you sure you want to delete this time?</b></p>
            </div>
          </div>
          <div className="row  usas-extra-top-margin">
            <div className="col-xs-12">
              <PrimaryButton type="button" onClick={onDelete}>Yes, Delete</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onClosePopup}>Cancel</SecondaryButton>
            </div>
          </div>
        </Fragment>
      </PopUpModal>
    </Fragment>
  );
};

export default TimesDeletionConfirmation;