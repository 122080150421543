import React, { createContext, useState } from 'react';

import RecordAgeGroupData from './RecordAgeGroupData';

export const RecordAgeGroupStateContext = createContext();

const RecordAgeGroupContextProvider = ({ children }) => {
  const stateHook = useState(RecordAgeGroupData.INITIAL_STATE);

  return (
    <RecordAgeGroupStateContext.Provider value={stateHook}>
      {children}
    </RecordAgeGroupStateContext.Provider>
  );
};

export default RecordAgeGroupContextProvider;