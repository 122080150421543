import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getRecordTimes from './GetRecordTimesData';
import deleteRecordTimeForAllTables from './DeleteRecordTimeForAllTablesData';
import deleteRecordTime from './DeleteRecordTimeData'
import postRecordTimes from './PostRecordTimesData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RecordTimesData = {
  INITIAL_STATE,
  getRecordTimes,
  deleteRecordTimeForAllTables,
  deleteRecordTime,
  postRecordTimes
};

export default RecordTimesData;