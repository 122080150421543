import { useContext } from "react";

import TimeStandardsDwData from "./TimeStandardsDwData";
import { TimeStandardsDwStateContext } from "./TimeStandardsDwContextProvider";

const useTimeStandardsDwData = () => {
  const [timeStandardsDwState, setTimeStandardsDwState] = useContext(TimeStandardsDwStateContext);

  const getTimeStandardsDw = (organizationCode) => {
    return TimeStandardsDwData.getTimeStandardsDwData(organizationCode, timeStandardsDwState, setTimeStandardsDwState);
  };

  return {
    timeStandardsDwState,
    getTimeStandardsDw,
    setTimeStandardsDwState
  };
};

export default useTimeStandardsDwData;