import { useState } from 'react';

import RecordListData from './RecordListData';

const useTimeStandardNameData = () => {
  const [recordListState, setRecordListState] = useState(RecordListData.INITIAL_STATE);

  const getRecordList = (recordGroupId) => RecordListData.getRecordListData(recordGroupId, recordListState, setRecordListState);

  return {
    recordListState,
    getRecordList
  };
};

export default useTimeStandardNameData;