import { useContext } from 'react';

import { RecordAgeGroupStateContext } from './RecordAgeGroupContextProvider';

import RecordAgeGroupData from './RecordAgeGroupData';

const useRecordAgeGroupData = () => {
  const [recordAgeGroupState, setRecordAgeGroupState] = useContext(RecordAgeGroupStateContext);

  const getRecordAgeGroups = () => {
    return RecordAgeGroupData.getRecordAgeGroups(recordAgeGroupState, setRecordAgeGroupState);
  };

  return {
    recordAgeGroupState,
    setRecordAgeGroupState,
    getRecordAgeGroups
  };
};

export default useRecordAgeGroupData;