import React, { Fragment } from 'react';
import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';
import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const { 
    NavLinks, 
    ANOMALY_DETECTION_SELECTOR_ROLE_GROUP_ID
  } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'Times Anomalies'}
        navLink={NavLinks.TIMES_ANOMALY_SEARCH}
        roleGroupId={ANOMALY_DETECTION_SELECTOR_ROLE_GROUP_ID}
        />
    </Fragment>
  );
};

export default OrgUnitSelection;