import React, { Fragment } from 'react';

import useSearchTimeStandards from './UseSearchMyTimeStandards';
import useExpandedRow from '../../../common/components/grids/UseExpandedRow';

import LargeMyTimeStandardsGrid from './LargeMyTimeStandardsGrid';
import SmallMyTimeStandardsGrid from './SmallMyTimeStandardsGrid';

import global from '../../../common/components/GlobalStyle.module.css'

const MyTimeStandardsGrid = ({ onEditTimeStandard, onDeleteTimeStandard, onEditAgeGroup, onDeleteAgeGroup, searchTimeStandardState }) => {
  const { timeStandardState } = useSearchTimeStandards();
  const { onClick, expandedId } = useExpandedRow();

  return (
    <Fragment>
      {timeStandardState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {timeStandardState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{timeStandardState.arrayData.length === 1 ? `${timeStandardState.arrayData.length} Search Result` : `${timeStandardState.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      : <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
      <LargeMyTimeStandardsGrid
        state={timeStandardState}
        onEditTimeStandard={onEditTimeStandard}
        onDeleteTimeStandard={onDeleteTimeStandard}
        onEditAgeGroup={onEditAgeGroup}
        onDeleteAgeGroup={onDeleteAgeGroup}
        expandedId={expandedId}
        onClick={onClick}
      />
      <SmallMyTimeStandardsGrid
        state={timeStandardState}
        onEditTimeStandard={onEditTimeStandard}
        onDeleteTimeStandard={onDeleteTimeStandard}
        onEditAgeGroup={onEditAgeGroup}
        onDeleteAgeGroup={onDeleteAgeGroup}
        expandedId={expandedId}
        onClick={onClick}
      />
    </Fragment>
  )
};

export default MyTimeStandardsGrid;