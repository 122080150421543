import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isArrayLoading: false,
  isArrayLoaded: false,
  data: [],
  message: ''
};

const getRecordEligibleTimesData = (recordListInstanceId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const recordListInstanceIdForUrl = recordListInstanceId ? encodeURIComponent(recordListInstanceId) : 0;

    const url = `/Records/RecordListInstance/${recordListInstanceIdForUrl}/EligibleTimes`;
    TimesHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            data: data,
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
}

const RecordEligibleTimesData = { INITIAL_STATE, getRecordEligibleTimesData };

export default RecordEligibleTimesData;