import Input from '../../../../common/components/inputs/Input';
import useSplitsRow from './UseSplitsRow';

import global from '../../../../common/components/GlobalStyle.module.css';

const SplitRow = ({ rowState, onRowChanged }) => {
  const { onTimeChanged } = useSplitsRow(rowState, onRowChanged);
  return (
    <tr>
      <td>{rowState.splitNumber}</td>
      <td>{rowState.cumulativeDistance}</td>
      <td>
        <Input
          label=""
          name={`splitTime${rowState.splitNumber}`}
          value={rowState.splitTime}
          onChange={onTimeChanged} />
      </td>
      <td>{rowState.calculatedSwimTime}</td>
    </tr>
  );
};

const TimeSplitsIndividualLargeGrid = ({ timeState, splitsState, onRowChanged }) => {
  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Split</th>
          <th>Cumulative Distance</th>
          <th>Time</th>
          <th>Calculated Swim Time</th>
        </tr>
      </thead>
      <tbody>
        {
          timeState.isArrayLoading === true || splitsState.timeSplits === undefined
            ? (<tr>
              <td colSpan="4">Loading...</td>
            </tr>)
            :
            splitsState.timeSplits.length > 0 ?
              splitsState.timeSplits.map((rowState, i) => <SplitRow onRowChanged={onRowChanged} rowState={rowState} key={i} />)
              :
              (<tr>
                <td colSpan="4">No Splits</td>
              </tr>)
        }
      </tbody>
    </table>
  );
};

export default TimeSplitsIndividualLargeGrid;