import React, { Fragment } from 'react';

import useMyTimeStandardsContextView from './UseMyTimeStandardsContextView';

import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';
import Constants from '../../../common/utils/Constants';

const MyTimeStandardsContextView = ({ children }) => {
  const {
    isLoading,
    isLoggedIn,
    selectOrgUnitState,
    onBackClicked,
    onBackToSelectionClicked
  } = useMyTimeStandardsContextView();

  if (isLoading === true || isLoggedIn === false) {
    return (
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={true} />
    );
  }

  if (!selectOrgUnitState.orgUnitId) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>)
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onBackClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              My Time Standards
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      {selectOrgUnitState.showBackToSelection &&
        <div className="row">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onBackToSelectionClicked}>
              <LeftArrowIcon />  Back to Selection
            </button>
          </div>
        </div>}
      <div className="row usas-extra-bottom-margin">
        <div className={["col-xs-12", global.HeaderText].join(' ')}>
          <p className={global.HeaderText}><strong>Editing:</strong> {selectOrgUnitState.orgUnitName}</p>
        </div>
      </div>
      {children}
    </Fragment>
  );
};

export default MyTimeStandardsContextView;