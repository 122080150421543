import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import searchRecords from './SearchRecordsData';
import postRecordListInstance from './PostRecordListInstanceData';
import postRecordListInstanceReturnArray from './PostRecordListInstanceReturnArrayData';
import deleteRecordListInstance from './DeleteRecordListInstanceData';
import deleteRecordListInstanceReturnArray from './DeleteRecordListInstanceReturnArrayData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RecordsData = {
  INITIAL_STATE,
  searchRecords,
  postRecordListInstance,
  postRecordListInstanceReturnArray,
  deleteRecordListInstance,
  deleteRecordListInstanceReturnArray
};

export default RecordsData;