import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const FreestyleRelayEventRowsSmall = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <div className={global.TableRow} key={"200FR-R"}>
      <div className={global.TableRowHead}>200 FR-R
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId23FSwimTime"
          error={errorState.eventId23FSwimTime}
          message={errorState.eventId23FSwimTime}
          value={formState.eventId23FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId23FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId50FSwimTime"
          error={errorState.eventId50FSwimTime}
          message={errorState.eventId50FSwimTime}
          value={formState.eventId50FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId50FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId76FSwimTime"
          error={errorState.eventId76FSwimTime}
          message={errorState.eventId76FSwimTime}
          value={formState.eventId76FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId76FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId23XSwimTime"
          error={errorState.eventId23XSwimTime}
          message={errorState.eventId23XSwimTime}
          value={formState.eventId23XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId23XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId50XSwimTime"
          error={errorState.eventId50XSwimTime}
          message={errorState.eventId50XSwimTime}
          value={formState.eventId50XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId50XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId76XSwimTime"
          error={errorState.eventId76XSwimTime}
          message={errorState.eventId76XSwimTime}
          value={formState.eventId76XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId76XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId23MSwimTime"
          error={errorState.eventId23MSwimTime}
          message={errorState.eventId23MSwimTime}
          value={formState.eventId23MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId23MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId50MSwimTime"
          error={errorState.eventId50MSwimTime}
          message={errorState.eventId50MSwimTime}
          value={formState.eventId50MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId50MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId76MSwimTime"
          error={errorState.eventId76MSwimTime}
          message={errorState.eventId76MSwimTime}
          value={formState.eventId76MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId76MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"400FR-R"}>
      <div className={global.TableRowHead}>400 FR-R
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId24FSwimTime"
          error={errorState.eventId24FSwimTime}
          message={errorState.eventId24FSwimTime}
          value={formState.eventId24FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId24FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId51FSwimTime"
          error={errorState.eventId51FSwimTime}
          message={errorState.eventId51FSwimTime}
          value={formState.eventId51FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId51FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId77FSwimTime"
          error={errorState.eventId77FSwimTime}
          message={errorState.eventId77FSwimTime}
          value={formState.eventId77FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId77FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId24XSwimTime"
          error={errorState.eventId24XSwimTime}
          message={errorState.eventId24XSwimTime}
          value={formState.eventId24XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId24XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId51XSwimTime"
          error={errorState.eventId51XSwimTime}
          message={errorState.eventId51XSwimTime}
          value={formState.eventId51XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId51XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId77XSwimTime"
          error={errorState.eventId77XSwimTime}
          message={errorState.eventId77XSwimTime}
          value={formState.eventId77XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId77XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId24MSwimTime"
          error={errorState.eventId24MSwimTime}
          message={errorState.eventId24MSwimTime}
          value={formState.eventId24MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId24MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId51MSwimTime"
          error={errorState.eventId51MSwimTime}
          message={errorState.eventId51MSwimTime}
          value={formState.eventId51MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId51MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId77MSwimTime"
          error={errorState.eventId77MSwimTime}
          message={errorState.eventId77MSwimTime}
          value={formState.eventId77MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId77MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"800FR-R"}>
      <div className={global.TableRowHead}>800 FR-R
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId25FSwimTime"
          error={errorState.eventId25FSwimTime}
          message={errorState.eventId25FSwimTime}
          value={formState.eventId25FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId25FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId52FSwimTime"
          error={errorState.eventId52FSwimTime}
          message={errorState.eventId52FSwimTime}
          value={formState.eventId52FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId52FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId78FSwimTime"
          error={errorState.eventId78FSwimTime}
          message={errorState.eventId78FSwimTime}
          value={formState.eventId78FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId78FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId25XSwimTime"
          error={errorState.eventId25XSwimTime}
          message={errorState.eventId25XSwimTime}
          value={formState.eventId25XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId25XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId52XSwimTime"
          error={errorState.eventId52XSwimTime}
          message={errorState.eventId52XSwimTime}
          value={formState.eventId52XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId52XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Mixed LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId78XSwimTime"
          error={errorState.eventId78XSwimTime}
          message={errorState.eventId78XSwimTime}
          value={formState.eventId78XSwimTime}
          onChange={(value) => { onFormValueChanged('eventId78XSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId25MSwimTime"
          error={errorState.eventId25MSwimTime}
          message={errorState.eventId25MSwimTime}
          value={formState.eventId25MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId25MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId52MSwimTime"
          error={errorState.eventId52MSwimTime}
          message={errorState.eventId52MSwimTime}
          value={formState.eventId52MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId52MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId78MSwimTime"
          error={errorState.eventId78MSwimTime}
          message={errorState.eventId78MSwimTime}
          value={formState.eventId78MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId78MSwimTime', value) }} /></div>
      </div>
    </div>
  </Fragment>
);

export default FreestyleRelayEventRowsSmall;