import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';
import Constants from '../../../utils/Constants';

const GetRecordGroupByOrgUnitIdData = (orgUnitId, state, setState) => {
  if (state.isArrayLoading !== true && !state.message) {
    const newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      isOptionsLoaded: false,
      options: [],
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : 'NaN';

    const url = `/RecordGroup/${orgUnitIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          arrayData.unshift({ recordGroupId: Constants.DEFAULT_ID, groupName: '--' });
          setState({
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            message: '',
            arrayData,
            options: arrayData.map((recordGroup) => { return { id: recordGroup.recordGroupId, name: recordGroup.groupName } }),
            isOptionsLoaded: true
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          isOptionsLoaded: false,
          message: message
        });
      });
  }
};

export default GetRecordGroupByOrgUnitIdData;