import React, { Fragment } from 'react';

import TimeRecognitionLargeGrid from './TimeRecognitionLargeGrid';
import TimeRecognitionSmallGrid from './TimeRecognitionSmallGrid';

const TimeRecognitionGrid = ({ gridData, isLoading, onOrgCheckbox, onRecordsCheckbox, onTopTimesCheckbox, onMeetEntryCheckbox }) => {
  return (
    <Fragment>
      <TimeRecognitionLargeGrid
        gridData={gridData}
        isLoading={isLoading}
        onOrgCheckbox={onOrgCheckbox}
        onRecordsCheckbox={onRecordsCheckbox}
        onTopTimesCheckbox={onTopTimesCheckbox}
        onMeetEntryCheckbox={onMeetEntryCheckbox}
      />
      <TimeRecognitionSmallGrid
        gridData={gridData}
        isLoading={isLoading}
        onOrgCheckbox={onOrgCheckbox}
        onRecordsCheckbox={onRecordsCheckbox}
        onTopTimesCheckbox={onTopTimesCheckbox}
        onMeetEntryCheckbox={onMeetEntryCheckbox}
      />  
    </Fragment>
  )
};

export default TimeRecognitionGrid;