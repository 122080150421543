import { Fragment } from "react";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import LscDropdown from "../../../../common/components/dropdowns/lscDropdown/LscDropdown";
import Headings from "../../../../common/components/headings/Headings";
import Constants from "../../../../common/utils/Constants";
import ViewReport from "../../../../common/components/embeddedReports/ViewReport";

import useTimesAnomalySearch from "./UseTimesAnomalySearch";
import TimesAnomalyGrid from "./TimesAnomalyGrid";

const Form = ({ userOrgUnitId, usasOrgUnitId, handleSubmit, formState, errorState, onValueTextPairChanged }) => (
  <form onSubmit={handleSubmit} noValidate>
    {userOrgUnitId === usasOrgUnitId &&
      <>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <LscDropdown
              label="LSC"
              name="lscOrgUnitId"
              value={formState.lscOrgUnitId}
              error={errorState.lscOrgUnitId}
              message={errorState.lscOrgUnitId}
              onChange={(newValue, newValueLabel, e) => {
                onValueTextPairChanged(newValue, 'lscOrgUnitId', newValueLabel, 'lscName', e);
                onValueTextPairChanged('', 'orgUnitId', '', 'orgUnitName', e);
              }} />
          </div>
        </div>

        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="submit">Filter Anomalies</PrimaryButton>&nbsp;
          </div>
        </div>
      </>
    }
  </form>
)

const TimesAnomalySearch = () => {
  const {
    selectOrgUnitState,
    USAS_ORG_UNIT_ID,
    timesAnomalyState,
    formState,
    handleSubmit,
    errorState,
    viewState,
    modalState,
    setViewState,
    onValueTextPairChanged,
    onVerify,
    onDeleteTime,
    onDisplayReport,
    onModalCanceled,
    onOpenModalClicked,
    INITIAL_VIEW_STATE
  } = useTimesAnomalySearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Times Anomalies</Headings.H3>
        </div>
      </div>
      <Form
        userOrgUnitId={selectOrgUnitState.orgUnitId}
        usasOrgUnitId={USAS_ORG_UNIT_ID}
        handleSubmit={handleSubmit}
        formState={formState}
        errorState={errorState}
        onValueTextPairChanged={onValueTextPairChanged}
      />
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <TimesAnomalyGrid
            onVerify={onVerify}
            onDeleteTime={onOpenModalClicked}
            onDisplayReport={onDisplayReport}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Name:</b> {modalState.swimmerName}</p>
            <p><b>Time: </b> {modalState.swimTime}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteTime}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timesAnomalyState.isArrayLoading}
      />
      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })}
            />
          </div>
        </div>
      }
    </Fragment>
  );
}

export default TimesAnomalySearch;