import React, { createContext, useState } from 'react';

import TimesAnomalyData from './TimesAnomalyData';

export const TimesAnomalyStateContext = createContext();

const TimesAnomalyContextProvider = ({ children }) => {
  const stateHook = useState(TimesAnomalyData.INITIAL_STATE);

  return (
    <TimesAnomalyStateContext.Provider value={stateHook}>
      {children}
    </TimesAnomalyStateContext.Provider>
  );
};

export default TimesAnomalyContextProvider;