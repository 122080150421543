import { CommonHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isArrayLoading: false,
  isArrayLoaded: false,
  recordGroupId: 0,
  data: [],
  items: [],
  message: ''
};

const getRecordListData = (recordGroupId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isArrayLoading: true,
      isArrayLoaded: false,
      recordGroupId,
      message: 'Loading...'
    };
    setState(newState);

    const recordGroupIdForUrl = recordGroupId ? encodeURIComponent(recordGroupId) : 0;

    const url = `/RecordList/${recordGroupIdForUrl}`;
    CommonHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isArrayLoading: false,
            isArrayLoaded: true,
            recordGroupId,
            data: data,
            items: data.map((x) => { return { id: x.recordListId, name: x.listName } }),
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoading: false,
          isArrayLoaded: false,
          message: message
        });
      });
  }
}

const RecordListData = { INITIAL_STATE, getRecordListData };

export default RecordListData;