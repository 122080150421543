import React, { Fragment } from 'react';

import TimeSplitsIndividualLargeGrid from './TimesSplitsIndividualLargeGrid';
import TimeSplitsIndividualSmallGrid from './TimesSplitsIndividualSmallGrid';

const TimeSplitsIndividualGrid = ({ timeState, splitsState, onRowChanged }) => {
  return (
    <Fragment>
      <TimeSplitsIndividualLargeGrid
        timeState={timeState}
        splitsState={splitsState}
        onRowChanged={onRowChanged}
      />
      <TimeSplitsIndividualSmallGrid
        timeState={timeState}
        splitsState={splitsState}
        onRowChanged={onRowChanged}
      />
    </Fragment>
  )
};

export default TimeSplitsIndividualGrid;