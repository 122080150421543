import { Fragment } from "react";

import useTopTimesLscSearch from "./UseTopTimesLscSearch";

import SearchTopTimes from "../../../components/searchTopTimes/SearchTopTimes";

const TopTimesLscSearch = () => {
  const { routeName, orgUnitId, orgUnitName } = useTopTimesLscSearch();

  return (
    <Fragment>
      <SearchTopTimes pageTitle={'LSC Top Times Search'} routeName={routeName} lscOrgUnitObj={{ orgUnitId, orgUnitName }} />
    </Fragment>
  );
};

export default TopTimesLscSearch