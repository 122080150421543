import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import style from '../../TimeStandards.module.css';

const IndividualButterflyEventRowsLarge = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <tr key={"50FL"}>
      <td className={style.EventColumn}>50 FL</td>
      <td><TimeInput
        label=""
        name="eventId17FSwimTime"
        error={errorState.eventId17FSwimTime}
        message={errorState.eventId17FSwimTime}
        value={formState.eventId17FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId17FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId44FSwimTime"
        error={errorState.eventId44FSwimTime}
        message={errorState.eventId44FSwimTime}
        value={formState.eventId44FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId44FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId71FSwimTime"
        error={errorState.eventId71FSwimTime}
        message={errorState.eventId71FSwimTime}
        value={formState.eventId71FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId71FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId17MSwimTime"
        error={errorState.eventId17MSwimTime}
        message={errorState.eventId17MSwimTime}
        value={formState.eventId17MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId17MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId44MSwimTime"
        error={errorState.eventId44MSwimTime}
        message={errorState.eventId44MSwimTime}
        value={formState.eventId44MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId44MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId71MSwimTime"
        error={errorState.eventId71MSwimTime}
        message={errorState.eventId71MSwimTime}
        value={formState.eventId71MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId71MSwimTime', value) }} /></td>
    </tr>
    <tr key={"100FL"}>
      <td className={style.EventColumn}>100 FL</td>
      <td><TimeInput
        label=""
        name="eventId18FSwimTime"
        error={errorState.eventId18FSwimTime}
        message={errorState.eventId18FSwimTime}
        value={formState.eventId18FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId18FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId45FSwimTime"
        error={errorState.eventId45FSwimTime}
        message={errorState.eventId45FSwimTime}
        value={formState.eventId45FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId45FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId72FSwimTime"
        error={errorState.eventId72FSwimTime}
        message={errorState.eventId72FSwimTime}
        value={formState.eventId72FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId72FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId18MSwimTime"
        error={errorState.eventId18MSwimTime}
        message={errorState.eventId18MSwimTime}
        value={formState.eventId18MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId18MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId45MSwimTime"
        error={errorState.eventId45MSwimTime}
        message={errorState.eventId45MSwimTime}
        value={formState.eventId45MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId45MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId72MSwimTime"
        error={errorState.eventId72MSwimTime}
        message={errorState.eventId72MSwimTime}
        value={formState.eventId72MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId72MSwimTime', value) }} /></td>
    </tr>
    <tr key={"200FL"}>
      <td className={style.EventColumn}>200 FL</td>
      <td><TimeInput
        label=""
        name="eventId19FSwimTime"
        error={errorState.eventId19FSwimTime}
        message={errorState.eventId19FSwimTime}
        value={formState.eventId19FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId19FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId46FSwimTime"
        error={errorState.eventId46FSwimTime}
        message={errorState.eventId46FSwimTime}
        value={formState.eventId46FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId46FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId73FSwimTime"
        error={errorState.eventId73FSwimTime}
        message={errorState.eventId73FSwimTime}
        value={formState.eventId73FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId73FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId19MSwimTime"
        error={errorState.eventId19MSwimTime}
        message={errorState.eventId19MSwimTime}
        value={formState.eventId19MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId19MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId46MSwimTime"
        error={errorState.eventId46MSwimTime}
        message={errorState.eventId46MSwimTime}
        value={formState.eventId46MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId46MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId73MSwimTime"
        error={errorState.eventId73MSwimTime}
        message={errorState.eventId73MSwimTime}
        value={formState.eventId73MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId73MSwimTime', value) }} /></td>
    </tr>
  </Fragment>
);

export default IndividualButterflyEventRowsLarge;