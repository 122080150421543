import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import NavLinks from '../NavLinks';

const useOrgUnitSelection = () => {
  const { ANOMALY_DETECTION_SELECTOR_ROLE_GROUP_ID } = useEnvironmentVariableData();

  return {
    NavLinks,
    ANOMALY_DETECTION_SELECTOR_ROLE_GROUP_ID
  };
};

export default useOrgUnitSelection;