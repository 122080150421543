

import { Fragment } from "react";

import TimeInput from "../../../../../common/components/inputs/TimeInput";

import global from '../../../../../common/components/GlobalStyle.module.css';

const ShortDistanceIndividualFreestyleEventRowsSmall = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <div className={global.TableRow} key={"50FR"}>
      <div className={global.TableRowHead}>50 FR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId1FSwimTime"
          error={errorState.eventId1FSwimTime}
          message={errorState.eventId1FSwimTime}
          value={formState.eventId1FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId1FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId28FSwimTime"
          error={errorState.eventId28FSwimTime}
          message={errorState.eventId28FSwimTime}
          value={formState.eventId28FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId28FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId55FSwimTime"
          error={errorState.eventId55FSwimTime}
          message={errorState.eventId55FSwimTime}
          value={formState.eventId55FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId55FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId1MSwimTime"
          error={errorState.eventId1MSwimTime}
          message={errorState.eventId1MSwimTime}
          value={formState.eventId1MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId1MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId28MSwimTime"
          error={errorState.eventId28MSwimTime}
          message={errorState.eventId28MSwimTime}
          value={formState.eventId28MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId28MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId55MSwimTime"
          error={errorState.eventId55MSwimTime}
          message={errorState.eventId55MSwimTime}
          value={formState.eventId55MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId55MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"100FR"}>
      <div className={global.TableRowHead}>100 FR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId2FSwimTime"
          error={errorState.eventId2FSwimTime}
          message={errorState.eventId2FSwimTime}
          value={formState.eventId2FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId2FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId29FSwimTime"
          error={errorState.eventId29FSwimTime}
          message={errorState.eventId29FSwimTime}
          value={formState.eventId29FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId29FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId56FSwimTime"
          error={errorState.eventId56FSwimTime}
          message={errorState.eventId56FSwimTime}
          value={formState.eventId56FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId56FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId2MSwimTime"
          error={errorState.eventId2MSwimTime}
          message={errorState.eventId2MSwimTime}
          value={formState.eventId2MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId2MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId29MSwimTime"
          error={errorState.eventId29MSwimTime}
          message={errorState.eventId29MSwimTime}
          value={formState.eventId29MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId29MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId56MSwimTime"
          error={errorState.eventId56MSwimTime}
          message={errorState.eventId56MSwimTime}
          value={formState.eventId56MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId56MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"200FR"}>
      <div className={global.TableRowHead}>200 FR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId3FSwimTime"
          error={errorState.eventId3FSwimTime}
          message={errorState.eventId3FSwimTime}
          value={formState.eventId3FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId3FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId30FSwimTime"
          error={errorState.eventId30FSwimTime}
          message={errorState.eventId30FSwimTime}
          value={formState.eventId30FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId30FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId57FSwimTime"
          error={errorState.eventId57FSwimTime}
          message={errorState.eventId57FSwimTime}
          value={formState.eventId57FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId57FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId3MSwimTime"
          error={errorState.eventId3MSwimTime}
          message={errorState.eventId3MSwimTime}
          value={formState.eventId3MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId3MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId30MSwimTime"
          error={errorState.eventId30MSwimTime}
          message={errorState.eventId30MSwimTime}
          value={formState.eventId30MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId30MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId57MSwimTime"
          error={errorState.eventId57MSwimTime}
          message={errorState.eventId57MSwimTime}
          value={formState.eventId57MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId57MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"400/500FR"}>
      <div className={global.TableRowHead}>400/500 FR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId4FSwimTime"
          error={errorState.eventId4FSwimTime}
          message={errorState.eventId4FSwimTime}
          value={formState.eventId4FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId4FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId31FSwimTime"
          error={errorState.eventId31FSwimTime}
          message={errorState.eventId31FSwimTime}
          value={formState.eventId31FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId31FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId58FSwimTime"
          error={errorState.eventId58FSwimTime}
          message={errorState.eventId58FSwimTime}
          value={formState.eventId58FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId58FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId4MSwimTime"
          error={errorState.eventId4MSwimTime}
          message={errorState.eventId4MSwimTime}
          value={formState.eventId4MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId4MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId31MSwimTime"
          error={errorState.eventId31MSwimTime}
          message={errorState.eventId31MSwimTime}
          value={formState.eventId31MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId31MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId58MSwimTime"
          error={errorState.eventId58MSwimTime}
          message={errorState.eventId58MSwimTime}
          value={formState.eventId58MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId58MSwimTime', value) }} /></div>
      </div>
    </div>
  </Fragment>
);

export default ShortDistanceIndividualFreestyleEventRowsSmall;