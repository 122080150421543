import React, { Fragment } from 'react';

import useTimesDetails from './UseTimesDetails';

import ReadOnly from '../../../../common/components/readOnly/ReadOnly';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

const TimeInfo = () => {
  const {
    formState,
    timeState
    //onBackToSearch
  } = useTimesDetails(true);

  return (
    <Fragment>
      <form noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Event"
              name="eventName"
              value={formState.eventName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Event Gender"
              name="eventGenderName"
              value={formState.eventGenderName} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Session"
              name="sessionName"
              value={formState.sessionName} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Entry Time"
              name="entryTime"
              value={formState.entryTime} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Swim Time"
              name="swimTime"
              value={formState.swimTime} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Swim Date"
              name="swimDate"
              value={formState.swimDate} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Place"
              name="place"
              value={formState.place} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Relay Lead-Off?"
              name="isRelayLeadOff"
              value={formState.isRelayLeadOff === true ? "Yes" : "No"}
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <SecondaryButton type="button" onClick={onBackToSearch}>Back To Time Search</SecondaryButton>
          </div>
        </div> */}
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeState.isObjLoading} />
    </Fragment>
  );
};

export default TimeInfo;