import React, { createContext, useState } from 'react';

import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import { DEFAULT_ID } from '../../../common/utils/Constants';

export const TimeStandardsDropdownStateContext = createContext();

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationId: DEFAULT_ID,
  options: []
};

const TimeStandardsDropdownContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <TimeStandardsDropdownStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardsDropdownStateContext.Provider>
  );
};

export default TimeStandardsDropdownContextProvider;