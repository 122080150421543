import useEventDwMultiSelect from './UseEventDwMultiSelect';

import MultiSelect from '../../../../common/components/multiSelect/MultiSelect';

import global from '../../../../common/components/GlobalStyle.module.css';

const EventDwMultiSelect = ({ label, name, value, error, message, onChange, organizationCode }) => {
  const { eventsDwState } = useEventDwMultiSelect(organizationCode);

  return eventsDwState.message
    ? <span className={global.LoadingMsg}>{eventsDwState.message}</span>
    : (
      <MultiSelect
        label={label}
        name={name}
        value={value}
        options={eventsDwState.options}
        error={error}
        message={message}
        isLoading={eventsDwState.areOptionsLoaded !== true}
        onChange={onChange} />
    );
};

export default EventDwMultiSelect;