import React, { createContext, useState } from 'react';

import SearchTimeStandardData from './SearchTimeStandardData';

export const SearchTimeStandardStateContext = createContext();

const SearchTimeStandardContextProvider = ({ children }) => {
  const stateHook = useState(SearchTimeStandardData.INITIAL_STATE);

  return (
    <SearchTimeStandardStateContext.Provider value={stateHook}>
      {children}
    </SearchTimeStandardStateContext.Provider>
  );
};

export default SearchTimeStandardContextProvider;