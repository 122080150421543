import { useState, useEffect } from 'react';

import useSwimTimeRecognitionData from '../../../../common/state/time/swimTimeRecognition/UseSwimTimeRecognitionData';

import useTimeData from '../../../../common/state/time/UseTimeData';

const useMeetRecognition = () => {
  const { getSwimTimeRecognition, putSwimTimeRecognition } = useSwimTimeRecognitionData();
  const { timeState } = useTimeData();
  const [gridState, setGridState] = useState(getInitialGridState);

  function handleCheckboxAction(objProp, id) {
    const newList = gridState.arrayData;
    const selectedIndex = newList.findIndex(x => x.organizationId === id);
    newList[selectedIndex][objProp] = !newList[selectedIndex][objProp];

    setGridState({
      ...gridState,
      arrayData: newList
    });
  }

  const onOrgCheckbox = (e, id) => {
    handleCheckboxAction("isEligibleForOrganization", id);
  }

  const onRecordsCheckbox = (e, id) => {
    handleCheckboxAction("isEligibleForRecord", id);
  }

  const onTopTimesCheckbox = (e, id) => {
    handleCheckboxAction("isEligibleForTopTimes", id);
  }

  const onMeetEntryCheckbox = (e, id) => {
    handleCheckboxAction("isEligibleForMeetEntry", id);
  }

  const onSaveButtonClick = () => {
    putSwimTimeRecognition(timeState.objData.swimTimeId, gridState.arrayData, gridState, setGridState);
  }

  const onCancelButtonClick = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (timeState.isObjLoaded === true) {
      getSwimTimeRecognition(timeState.objData.swimTimeId, gridState, setGridState);
    }
  }

  useEffect(() => {
    if (timeState.isObjLoaded === true) {
      getSwimTimeRecognition(timeState.objData.swimTimeId, gridState, setGridState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState.isObjLoaded]);

  return {
    gridState,
    onOrgCheckbox,
    onRecordsCheckbox,
    onTopTimesCheckbox,
    onMeetEntryCheckbox,
    onSaveButtonClick,
    onCancelButtonClick
  }
}

export default useMeetRecognition;

function getInitialGridState() {
  return {
    arrayData: [],
    isSaving: false,
    isSaved: false,
    isArrayLoading: false,
    isArrayLoaded: false
  };
};