import { createContext, useState } from 'react';

import EventsDwData from './EventsDwData';

export const EventsDwStateContext = createContext();

const EventsDwContextProvider = ({ children }) => {
  const stateHook = useState(EventsDwData.INITIAL_EVENTS_DW_STATE);

  return (
    <EventsDwStateContext.Provider value={stateHook}>
      {children}
    </EventsDwStateContext.Provider>
  );
};

export default EventsDwContextProvider;