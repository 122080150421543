import React from 'react';

import useTimeStandardTypeDropdown from './UseTimeStandardTypeDropdown';

import Dropdown from '../../../common/components/dropdowns/Dropdown';

import global from '../../../common/components/GlobalStyle.module.css';

const TimeStandardTypeDropdown = ({ label, name, value, error, message, onChange, orgUnitId }) => {
  const { timeStandardTypeState } = useTimeStandardTypeDropdown(orgUnitId);

  return timeStandardTypeState.message
    ? <span className={global.LoadingMsg}>{timeStandardTypeState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={timeStandardTypeState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={timeStandardTypeState.isArrayLoading}
      />
    );
};

export default TimeStandardTypeDropdown;