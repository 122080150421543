import React, { Fragment } from 'react';

import useSearchTimeStandards from './UseSearchTimeStandards';

import TimeStandardsGrid from './TimeStandardsGrid';

import TimeStandardTypeDropdown from '../dropdowns/TimeStandardTypeDropdown';
import TimeStandardNameCombobox from '../comboboxes/TimeStandardNameCombobox';

import Headings from '../../../common/components/headings/Headings';
import YesNoSwitch from '../../../common/components/yesNoSwitch/YesNoSwitch';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import Dropdown from '../../../common/components/dropdowns/Dropdown';
import OrgUnitTreeView from '../../../common/components/tree/orgUnitTree/OrgUnitTreeView';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onOrgUnitChanged, onValueTextPairChanged, onFormValueChanged,
  DEFAULT_ID, sortByOptions, clearForm, onAddTimeStandard }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <OrgUnitTreeView
          label="Organization"
          name="orgUnit"
          value={formState.orgUnit}
          error={errorState.orgUnit}
          message={errorState.orgUnit}
          selectableLeavesOnly={false}
          singleSelect={true}
          onChange={(newValue, e) => {
            onOrgUnitChanged(newValue);
            onValueTextPairChanged(DEFAULT_ID, 'timeStandardId', '', 'timeStandardName', e);
          }}
          organizationId={undefined} />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <TimeStandardTypeDropdown
          label="Time Standard Type"
          name="timeStandardTypeId"
          value={formState.timeStandardTypeId}
          error={errorState.timeStandardTypeId}
          message={errorState.timeStandardTypeId}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'timeStandardTypeId', newValueLabel, 'timeStandardTypeName', e);
            onValueTextPairChanged(DEFAULT_ID, 'timeStandardId', '', 'timeStandardName', e);
          }}
          orgUnitId={formState.orgUnit[0]?.id || ''}
        />
      </div>
      <div className={formState.orgUnit.length > 0 && formState.timeStandardTypeId > 0 ? global.DisplayComponent : global.HideComponent}>
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TimeStandardNameCombobox
            label="Time Standard Name"
            name="timeStandardName"
            valueToMatch={formState.timeStandardName}
            error={errorState.timeStandardId}
            message={errorState.timeStandardId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardId', newValueLabel, 'timeStandardName', e); }}
            orgUnitId={formState.orgUnit.length > 0 ? formState.orgUnit[0].id : ''}
            typeId={formState.timeStandardTypeId > 0 ? formState.timeStandardTypeId : ''} />
        </div>
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <YesNoSwitch
          label="Current Time Standards Only"
          name="isCurrent"
          checked={formState.isCurrent === true}
          error={errorState.isCurrent}
          message={errorState.isCurrent}
          onChange={(value) => { onFormValueChanged('isCurrent', value); }}
        />
      </div>
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Sort Results By"
          options={sortByOptions}
          name="sortBy"
          value={formState.sortBy}
          error={errorState.sortBy}
          message={errorState.sortBy}
          areValuesIntegers={false}
          onChange={(value) => { onFormValueChanged('sortBy', value) }}
        />
      </div>
    </div>
    <div className="row">
      <div className="col-xs-12">
        <p>{errorState.filter ? '' : '*Must filter by an Organization or Time Standard Type to view time standard results'}</p>
        <p className={global.ErrorMessage}>{errorState.filter ? errorState.filter : ''}</p>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <PrimaryButton type="submit">Search For Time Standard</PrimaryButton>&nbsp;
        <SecondaryButton type="button" onClick={clearForm}>Clear Form</SecondaryButton>&nbsp;
        {onAddTimeStandard && <ActionIntraPageButton type="button" onClick={onAddTimeStandard}>Add Time Standard</ActionIntraPageButton>}
      </div>
    </div>
  </form>
)

const SearchTimeStandards = ({ searchTimeStandardState, onAddTimeStandard, onEditTimeStandard, onEditAgeGroup }) => {
  const { timeStandardState, orgUnitState, timeStandardTypeState, formState, errorState, handleSubmit, clearForm,
    onValueTextPairChanged, onFormValueChanged, onOrgUnitChanged, onDeleteTimeStandard, onDeleteAgeGroup,
    sortByOptions, DEFAULT_ID, onToggleFilters } = useSearchTimeStandards();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Time Standards Search</Headings.H3>
        </div>
      </div>
      {searchTimeStandardState !== undefined ?
        <Fragment>
          <div className={searchTimeStandardState.showFilters === true ? global.DisplayComponent : global.HideComponent}>
            <Form
              handleSubmit={handleSubmit}
              formState={formState}
              errorState={errorState}
              onOrgUnitChanged={onOrgUnitChanged}
              onValueTextPairChanged={onValueTextPairChanged}
              onFormValueChanged={onFormValueChanged}
              DEFAULT_ID={DEFAULT_ID}
              sortByOptions={sortByOptions}
              clearForm={clearForm}
              onAddTimeStandard={onAddTimeStandard}
            />
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <TimeStandardsGrid
                onEditTimeStandard={onEditTimeStandard}
                onDeleteTimeStandard={onDeleteTimeStandard}
                onEditAgeGroup={onEditAgeGroup}
                onDeleteAgeGroup={onDeleteAgeGroup}
                searchTimeStandardState={searchTimeStandardState}
                onToggleFilters={onToggleFilters} />
            </div>
          </div>
        </Fragment>
        :
        //shows filters and results at the same time
        <Fragment>
          <Form
            handleSubmit={handleSubmit}
            formState={formState}
            errorState={errorState}
            onOrgUnitChanged={onOrgUnitChanged}
            onValueTextPairChanged={onValueTextPairChanged}
            onFormValueChanged={onFormValueChanged}
            DEFAULT_ID={DEFAULT_ID}
            sortByOptions={sortByOptions}
            clearForm={clearForm}
            onAddTimeStandard={onAddTimeStandard}
          />
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <TimeStandardsGrid
                onEditTimeStandard={onEditTimeStandard}
                onDeleteTimeStandard={onDeleteTimeStandard}
                onEditAgeGroup={onEditAgeGroup}
                onDeleteAgeGroup={onDeleteAgeGroup} />
            </div>
          </div>
        </Fragment>}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isArrayLoading || (timeStandardState.isObjLoading && !timeStandardState.isSaving) ||
          orgUnitState.isArrayLoading || timeStandardTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving} />
    </Fragment >
  )
}

export default SearchTimeStandards;
