import React from 'react';

import useLegsRow from './UseLegsRow';

import global from '../../../../common/components/GlobalStyle.module.css';
import Input from '../../../../common/components/inputs/Input';

const LegRow = ({ rowState, onRowChanged }) => {
  const { onTimeChanged } = useLegsRow(rowState, onRowChanged);
  return (
    <div className={global.SmallTableRow} key={rowState.splitId}>
      <div className={global.SmallTableRowHead}>
        <span className='hidden-xs'>Leg {rowState.legNumber}</span>&nbsp;
      </div>
      <div className={global.SmallTableRowInfo}>
        <div className='row'>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Leg</div>
          <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{rowState.legNumber}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Competitor</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{rowState.competitor}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Event</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{rowState.eventDescription}</div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}><div style={{ paddingTop: "15px" }}>Time</div></div>
          <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
            <Input
              label=""
              name={`legTime${rowState.legNumber}`}
              value={rowState.legTime}
              onChange={onTimeChanged} />
          </div>
        </div>
      </div>
    </div>
  );
};

const RelayLegsSmallGrid = ({ timeState, relayLegsState, onRowChanged }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {
      timeState.isArrayLoading === true || relayLegsState.relayLegs === undefined
      ? (<tr>
        <td colSpan="8">Loading...</td>
      </tr>)
      : Array.isArray(relayLegsState.relayLegs) && relayLegsState.relayLegs.length > 0
        ? relayLegsState.relayLegs.map((rowState, i) =>
          <LegRow onRowChanged={onRowChanged} rowState={rowState} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Relay Legs</div>
            </div>
          </div>
        )}
  </div>
);

export default RelayLegsSmallGrid;