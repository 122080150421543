import React, { Fragment } from 'react';

import ShortDistanceIndividualFreestyleEventRowsLarge from './ShortDistanceIndividualFreestyleEventRowsLarge';
import LongDistanceIndividualFreestyleEventRowsLarge from './LongDistanceIndividualFreestyleEventRowsLarge';
import IndividualBackstrokeEventRowsLarge from './IndividualBackstrokeEventRowsLarge';
import IndividualBreaststrokeEventRowsLarge from './IndividualBreaststrokeEventRowsLarge';
import IndividualButterflyEventRowsLarge from './IndividualButterflyEventRowsLarge';
import IndividualMedleyEventRowsLarge from './IndividualMedleyEventRowsLarge';
import RelayFreestyleEventRowsLarge from './RelayFreestyleEventRowsLarge';
import RelayMedleyEventRowsLarge from './RelayMedleyEventRowsLarge';

import ShortDistanceIndividualFreestyleEventRowsSmall from './ShortDistanceIndividualFreestyleEventRowsSmall';
import LongDistanceIndividualFreestyleEventRowsSmall from './LongDistanceIndividualFreestyleEventRowsSmall';
import IndividualBackstrokeEventRowsSmall from './IndividualBackstrokeEventRowsSmall';
import IndividualBreaststrokeEventRowsSmall from './IndividualBreaststrokeEventRowsSmall';
import IndividualButterflyEventRowsSmall from './IndividualButterflyEventRowsSmall';
import IndividualMedleyEventRowsSmall from './IndividualMedleyEventRowsSmall';
import RelayFreestyleEventRowsSmall from './RelayFreestyleEventRowsSmall';
import RelayMedleyEventRowsSmall from './RelayMedleyEventRowsSmall';

import global from '../../../../../common/components/GlobalStyle.module.css';

const LargeGrid = ({ state, formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <table className={[global.UsasTable, 'visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Event</th>
          <th>Female SCY</th>
          <th>Female SCM</th>
          <th>Female LCM</th>
          <th>Male SCY</th>
          <th>Male SCM</th>
          <th>Male LCM</th>
        </tr>
      </thead>
      <tbody>
        {state.isObjLoaded !== true
          ? (
            <tr>
              {state.isObjLoading === true ?
                <td colSpan="7">Loading...</td>
                : <td colSpan="7">No Time Cuts</td>
              }
            </tr>
          ) : formState.timeStandardAgeGroupId > 0 ?
            (<Fragment>
              <ShortDistanceIndividualFreestyleEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <LongDistanceIndividualFreestyleEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <IndividualBackstrokeEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <IndividualBreaststrokeEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <IndividualButterflyEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <IndividualMedleyEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
            </Fragment>
            ) : (
              <tr>
                <td colSpan="7">No Time Cuts</td>
              </tr>
            )
        }
      </tbody >
    </table >
    <table className={[global.UsasTable, 'visible-lg'].join(' ')}>
      <thead>
        <tr>
          <th>Event</th>
          <th>Female SCY</th>
          <th>Female SCM</th>
          <th>Female LCM</th>
          <th>Mixed SCY</th>
          <th>Mixed SCM</th>
          <th>Mixed LCM</th>
          <th>Male SCY</th>
          <th>Male SCM</th>
          <th>Male LCM</th>
        </tr>
      </thead>
      <tbody>
        {state.isObjLoaded !== true
          ? (
            <tr>
              {state.isObjLoading === true ?
                <td colSpan="10">Loading...</td>
                : <td colSpan="10">No Time Cuts</td>
              }
            </tr>
          ) : formState.timeStandardAgeGroupId > 0 ?
            (<Fragment>
              <RelayFreestyleEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
              <RelayMedleyEventRowsLarge
                formState={formState}
                errorState={errorState}
                onFormValueChanged={onFormValueChanged}
              />
            </Fragment>
            ) : (
              <tr>
                <td colSpan="10">No Time Cuts</td>
              </tr>
            )
        }
      </tbody >
    </table >
  </Fragment>
)

const SmallGrid = ({ state, formState, errorState, onFormValueChanged }) => (
  <div className={[global.UsasTable, 'hidden-lg'].join(' ')}>
    {state.isObjLoaded !== true
      ? (
        <div className={global.TableRow}>
          <div className={global.TableRowHead}>
            &nbsp;
          </div>
          {state.isObjLoading === true ?
            <div className={[global.TableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;Loading... </div>
            : <div className={[global.TableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No Time Cuts</div>
          }
        </div>
      ) : formState.timeStandardAgeGroupId > 0 ?
        (<Fragment>
          <ShortDistanceIndividualFreestyleEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <LongDistanceIndividualFreestyleEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <IndividualBackstrokeEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <IndividualBreaststrokeEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <IndividualButterflyEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <IndividualMedleyEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <RelayFreestyleEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
          <RelayMedleyEventRowsSmall
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
          />
        </Fragment>
        ) : (
          <div className={global.TableRow}>
            <div className={global.TableRowHead}>
              &nbsp;
            </div>
            <div className={[global.TableRowInfo, global.Bold].join(' ')}>&nbsp;&nbsp;No Time Cuts</div>
          </div>
        )
    }
  </div >
);

const MyTimeStandardAgeGroupCutsGrid = ({ state, formState, errorState, onFormValueChanged }) => (
  <Fragment>
    <LargeGrid state={state} formState={formState} errorState={errorState} onFormValueChanged={onFormValueChanged} />
    <SmallGrid state={state} formState={formState} errorState={errorState} onFormValueChanged={onFormValueChanged} />
  </Fragment>
)

export default MyTimeStandardAgeGroupCutsGrid;