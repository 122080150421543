import { isValidSwimTime } from '../../../../common/utils/validation';
import {formatTimeForDisplay, formatTimeForFilterObject} from '../../../../common/utils/TimesUtils';

export const localValidate = (formState) => {
  let errors = {};

  for (var prop in formState) {
    if (Object.prototype.hasOwnProperty.call(formState, prop)) {
      if (prop !== 'timeStandardId' && prop !== 'timeStandardAgeGroupId')
        if (formState[prop] !== '') {
          if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState[prop])))) {
            errors[prop] = 'Invalid Time';
          }
        }
    }
  }

  return errors;
};

const MyTimeStandardAgeGroupCutsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default MyTimeStandardAgeGroupCutsValidation;