import { isValidDate, isValidBirthDate, isValidNumber, isValidSwimTime } from '../../../../common/utils/validation';
import Constants from '../../../../common/utils/Constants';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';

export const localValidate = (formState) => {
  const errors = {};

  if (formState.eventName === ''
    || formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = 'Event is required';
  }

  if (formState.swimDate === Constants.BLANK_DATE_STRING) {
    errors.swimDate = 'Swim Date is required';
  } else if (!isValidDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date must be a valid date'
  } else if (!isValidBirthDate(formState.swimDate)) {
    errors.swimDate = 'Swim Date cannot be in the future'
  }

  const meetStartDate = new Date(formState.meetStartDate);
  const meetEndDate = new Date(formState.meetEndDate);
  const swimDate = new Date(formState.swimDate);

  if (swimDate < meetStartDate || swimDate > meetEndDate) {
    errors.swimDate = 'Swim Date must be between the Meet Start Date and the Meet End Date'
  }

  if (formState.place !== '' && (!isValidNumber(formState.place) || formState.place === '0')) {
    errors.place = 'Place must be a number greater than zero if entered'
  }

  if (formState.entryTime !== '') {
    if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.entryTimeValue)))) {
      errors.entryTime = 'Entry Time must be a valid time';
    }
  }

  if (formState.swimTime === '' || formState.swimTime === 'NT') {
    errors.swimTime = 'Swim Time is required';
  } else if (!isValidSwimTime(formatTimeForFilterObject(formatTimeForDisplay(formState.swimTimeValue)))) {
    errors.swimTime = 'Swim Time must be a valid time';
  }

  return errors;
};

const TimeDetailsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimeDetailsValidation;