import React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import TimesAnomalyContextProvider from '../../state/timesAnomaly/TimesAnomalyContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import LscContextProvider from '../../../common/state/lsc/LscContextProvider';
import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import TimesAnomalyContextView from './TimesAnomalyContextView';
import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import TimesAnomalySearch from './timesAnomalySearch/TimesAnomalySearch';

const ContextRoutes = ({ navRoutes }) => (
  <TimesAnomalyContextProvider>
    <TimesAnomalyContextView>
      <LscContextProvider>
        <Routes>
          <Route path={navRoutes['TIMES_ANOMALY_SEARCH']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['TIMES_ANOMALY_SEARCH']}
                editElement={<TimesAnomalySearch />} />
            }
          />
        </Routes>
      </LscContextProvider>
    </TimesAnomalyContextView>
  </TimesAnomalyContextProvider>
);

const TimesAnomalyRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['TIMES_ANOMALY_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_ANOMALY_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['TIMES_ANOMALY_SELECTION']?.route} replace />} />
    </Routes>
  )
};

const TimesAnomaly = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <TimesAnomalyRoutes />
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default TimesAnomaly;