import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useSearchTimeStandardData from '../../../state/searchTimeStandard/UseSearchTimeStandardData';
import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';

import NavLinks from '../NavLinks';

const useTimeStandardsSearch = () => {
  const navigate = useNavigate();
  const { searchTimeStandardState } = useSearchTimeStandardData();

  const { getTimeStandard } = useTimeStandardData();

  const onAddTimeStandard = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TIME_STANDARDS_DETAIL);
  };

  const onEditTimeStandard = (e, timeStandardId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TIME_STANDARDS_DETAIL, { state: { timeStandardId } });
  }

  const onEditAgeGroup = (e, timeStandardId, timeStandardAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    getTimeStandard(timeStandardId);

    navigate(NavLinks.TIME_STANDARDS_AGE_GROUP_CUTS, { state: { timeStandardId, timeStandardAgeGroupId } });
  }

  return {
    searchTimeStandardState,
    onAddTimeStandard,
    onEditTimeStandard,
    onEditAgeGroup
  }
};

export default useTimeStandardsSearch;