const NavLinks = {
  RECORDS_ROOT: '/records',
  RECORDS_SELECTION: '/records/selection',
  RECORDS_ADMIN: '/records/admin',
  RECORDS_CLEANUP: '/records/cleanup',
  RECORDS_LIST_INSTANCE_DETAIL: '/records/listinstancedetail',
  RECORDS_EVENTS: '/records/events',
  RECORDS_TIMES: '/records/times'
};

export default NavLinks;