import React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import TimeStandardContextProvider from '../../state/timeStandard/TimeStandardContextProvider';
import TimeStandardFiltersContextProvider from '../../state/timeStandard/TimeStandardFiltersContextProvider';
import SearchTimeStandardContextProvider from '../../state/searchTimeStandard/SearchTimeStandardContextProvider';

import TimeStandardTypeContextProvider from '../../../common/state/timeStandardType/TimeStandardTypeContextProvider';

import TimeStandardsContextView from './TimeStandardsContextView';

import TimeStandardsSearch from './timeStandardsSearch/TimeStandardsSearch';
import TimeStandardDetail from './timeStandardDetail/TimeStandardDetail';
import TimeStandardAgeGroup from './timeStandardAgeGroup/TimeStandardAgeGroup';
import TimeStandardAgeGroupAdd from './timeStandardAgeGroupAdd/TimeStandardAgeGroupAdd';
import TimeStandardAgeGroupCuts from './timeStandardAgeGroupCuts/TimeStandardAgeGroupCuts';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <TimeStandardsContextView>
    <Routes>
      <Route path={navRoutes['TIME_STANDARDS_AGEGROUP']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIME_STANDARDS_AGEGROUP']}
            editElement={<TimeStandardAgeGroup />} />
        }
      />
      <Route path={navRoutes['TIME_STANDARDS_AGEGROUP_ADD']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIME_STANDARDS_AGEGROUP_ADD']}
            editElement={<TimeStandardAgeGroupAdd />} />
        }
      />
      <Route path={navRoutes['TIME_STANDARDS_AGEGROUP_CUTS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIME_STANDARDS_AGEGROUP_CUTS']}
            editElement={<TimeStandardAgeGroupCuts />} />
        }
      />
    </Routes>
  </TimeStandardsContextView>
);

const TimeStandardsRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['TIME_STANDARDS_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIME_STANDARDS_SEARCH']}
            editElement={<TimeStandardsSearch />} />
        }
      />
      <Route path={navRoutes['TIME_STANDARDS_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIME_STANDARDS_DETAIL']}
            editElement={<TimeStandardDetail />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['TIME_STANDARDS_SEARCH']?.route} replace />} />
    </Routes>
  )
};

const TimeStandards = () => (
  <TimeStandardTypeContextProvider>
    <TimeStandardContextProvider>
      <TimeStandardFiltersContextProvider>
        <SearchTimeStandardContextProvider>
          <TimeStandardsRoutes />
        </SearchTimeStandardContextProvider>
      </TimeStandardFiltersContextProvider>
    </TimeStandardContextProvider>
  </TimeStandardTypeContextProvider>
);

export default TimeStandards;