import { useContext } from 'react';

import { RecordGroupStateContext } from './RecordGroupContextProvider';
import RecordGroupData from './RecordGroupData';

const useRecordGroupData = () => {
  const [recordGroupState, setRecordGroupState] = useContext(RecordGroupStateContext);

  const getRecordGroups = () => {
    RecordGroupData.getRecordGroupData(recordGroupState, setRecordGroupState);
  };

  const getRecordGroupByOrgUnitId = (orgUnitId) => {
    RecordGroupData.getRecordGroupByOrgUnitIdData(orgUnitId, recordGroupState, setRecordGroupState);
  };

  return {
    recordGroupState, setRecordGroupState, getRecordGroups, getRecordGroupByOrgUnitId
  };
};

export default useRecordGroupData;