import useReportPeriodSeasonDwDropdown from './UseReportPeriodSeasonDwDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const ReportPeriodSeasonDwDropdown = ({ label, name, value, error, message, onChange, organizationCode }) => {
  const { reportPeriodSeasonsDwState } = useReportPeriodSeasonDwDropdown(organizationCode);

  return reportPeriodSeasonsDwState.message
    ? <span className={global.LoadingMsg}>{reportPeriodSeasonsDwState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={reportPeriodSeasonsDwState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={reportPeriodSeasonsDwState.areOptionsLoaded !== true} />
    );
};

export default ReportPeriodSeasonDwDropdown;