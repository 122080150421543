import { Fragment } from "react";

import useTopTimesNationalSearch from "./UseTopTimesNationalSearch";

import SearchTopTimes from "../../../components/searchTopTimes/SearchTopTimes";

const TopTimesNationalSearch = () => {
  const { routeName } = useTopTimesNationalSearch();

  return (
    <Fragment>
      <SearchTopTimes pageTitle={'Top Times Search'} routeName={routeName} />
    </Fragment>
  );
};

export default TopTimesNationalSearch