import React from 'react';

import CheckIcon from '../../../../common/components/icons/CheckIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ReportIcon from '../../../../common/components/icons/ReportIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';
import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

const getMessage = (isAnomaly, isDeleted) => {
  if (isAnomaly === true && isDeleted === false) {
    return 'Verify or Remove';
  } else if (isAnomaly === false && isDeleted === false) {
    return 'Verified';
  } else if (isDeleted === true) {
    return 'All Deleted';
  }
  return '';
};

const GridRow = ({ anomaly, onVerify, onDeleteTime, onDisplayReport }) => (
  <tr>
    <td>{anomaly.firstName + ' ' + anomaly.lastName}</td>
    <td>{anomaly.lscCode}</td>
    <td style={{lineHeight: 1.2, width: 150}}>{anomaly.meetName}</td>
    <td>{formatDate(anomaly.swimDate)}</td>
    <td style={{lineHeight: 1.2}}>{anomaly.eventCode}</td>
    <td>{anomaly.typeName}</td>
    <td>{formatTimeForDisplay(anomaly.swimTime)}</td>
    <td>{anomaly.swimmerAge}</td>
    <td>{anomaly.zscore}</td>
    <td>{anomaly.powerpoints}</td>
    <td style={{lineHeight: 1.2}}>{getMessage(anomaly.isAnomaly, anomaly.isDeleted)}</td>
    <td style={{ float: 'right' }}>
      {anomaly.isDeleted === false && anomaly.isAnomaly === true &&
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onVerify(anomaly.swimTimeAnomalyId)}>
        <CheckIcon toolTipText="Verify Time" />
      </button>
      }
      {anomaly.isDeleted === false && anomaly.isAnomaly === true &&
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteTime(anomaly.swimTimeAnomalyId)}>
        <DeleteIcon toolTipText="Delete all time usage" />
      </button>
      }
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDisplayReport(anomaly)}>
        <ReportIcon toolTipText="Display Time Anomaly Report" />
      </button>
    </td>
  </tr>
);

const LargeTimeAnomalyGrid = ({ data, isLoading, onVerify, onDeleteTime, onDisplayReport }) => {
  const {
        sortableGridState,
        onColumnClicked} = useSortableGrid(data);

  return (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Swimmer Name</th>
        <th>LSC</th>
        <th>Meet</th>
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Date'} columnField={'swimDate'}
          sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        <th>Event</th>
        <th>Session</th>
        <th>Swim Time</th>
        <th>Age</th>
        <th>ZScore</th>
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Powerpoints'} columnField={'powerpoints'}
          sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
        <th>Anomaly?</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map((anomaly, i) => (
            <GridRow
              key={i}
              anomaly={anomaly}
              onVerify={onVerify} 
              onDeleteTime={onDeleteTime}
              onDisplayReport={onDisplayReport} />
          )) : (
            <tr>
              <td colSpan="2">No Anomalies</td>
            </tr>
          )
      }
    </tbody>
  </table>
)
};

export default LargeTimeAnomalyGrid;