import { useNavigate, useLocation } from '../../../common/wrappers/ReactRouterDom';

import NavLinks from './NavLinks';

import useTimeStandardData from '../../state/timeStandard/UseTimeStandardData';

const BACK_TO_LABELS = {
  timeStandardsBackToLabel: 'Back to Time Standards Search',
};

const useManageTimeStandardsContextView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { timeStandardState } = useTimeStandardData();

  const goToTimeStandardsSearch = () => {
    navigate(NavLinks.TIME_STANDARDS_SEARCH);
  };

  const onClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    goToTimeStandardsSearch();
  }

  const getBackToLabel = () => {
    return BACK_TO_LABELS.timeStandardsBackToLabel;
  };

  return {
    location,
    timeStandardState,
    backToLabel: getBackToLabel(),
    onClicked
  };
};

export default useManageTimeStandardsContextView;