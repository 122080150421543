import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getEventsDwData = (organizationCode, state, setState) => {
  if (organizationCode) {
    const newState = {
      ...state,
      organizationCode
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);
    const organizationCodeForUrl = encodeURIComponent(organizationCode);
    const url = `/Event/Organization/${organizationCodeForUrl}/DW`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_EVENTS_DW_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationCode: '',
  areOptionsLoaded: false,
  options: []
};

const EventsDwData = {
  INITIAL_EVENTS_DW_STATE,
  getEventsDwData
};

export default EventsDwData;