import React, { Fragment } from 'react';

import useTimeStandardAgeGroup from './UseTimeStandardAgeGroup';

import TimeStandardAgeGroupGrid from './TimeStandardAgeGroupGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import ActionIntraPageButton from '../../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import { formatDate } from '../../../../common/utils/DateFunctions';

import global from '../../../../common/components/GlobalStyle.module.css';

const TimeStandardAgeGroup = () => {
  const { timeStandardState, state, onAddAgeGroup, onDeleteAgeGroup, onCreateClicked, onCancelClicked,
    Constants } = useTimeStandardAgeGroup();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Time Standard Age Groups</Headings.H3>
        </div>
      </div>
      {timeStandardState.isObjLoaded === true ?
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>Time Standard:</b> {timeStandardState.objData?.standardName} (Qualifying Dates: {formatDate(timeStandardState.objData?.effectiveDate)} - {formatDate(timeStandardState.objData?.expirationDate)})</p>
          </div>
        </div> : <Fragment />}
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <ActionIntraPageButton type="button" onClick={onAddAgeGroup}>Add Age Group</ActionIntraPageButton>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 usas-extra-top-margin">
          <TimeStandardAgeGroupGrid
            state={timeStandardState}
            onDelete={onDeleteAgeGroup}
          />
        </div>
      </div>
      {state.error &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{state.error ? state.error : ''}</p>
          </div>
        </div>}
      <div className="row  usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onCreateClicked}>Create Time Standards</PrimaryButton>&nbsp;
          <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isObjLoading && !timeStandardState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving} />
    </Fragment>
  )
}

export default TimeStandardAgeGroup;
