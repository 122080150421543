import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import TopTimesContextView from './TopTimesContextView';

import TopTimesLscSearch from './topTimesLscSearch/TopTimesLscSearch';
import TopTimesClubSearch from './topTimesClubSearch/TopTimesClubSearch';
import TopTimesLscSelection from './topTimesLscSelection/TopTimesLscSelection';
import TopTimesClubSelection from './topTimesClubSelection/TopTimesClubSelection';
import TopTimesNationalSearch from './topTimesNationalSearch/TopTimesNationalSearch';

import EventsDwContextProvider from '../../state/topTimes/eventsDw/EventsDwContextProvider';
import TimeStandardsDwContextProvider from '../../state/topTimes/timeStandardsDw/TimeStandardsDwContextProvider';
import ReportPeriodSeasonsDwContextProvider from '../../state/topTimes/reportPeriodSeasonsDw/ReportPeriodSeasonsDwContextProvider';
import EventCompetitionGendersDwContextProvider from '../../state/topTimes/eventCompetitionGendersDw/EventCompetitionGendersDwContextProvider';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LscContextProvider from '../../../common/state/lsc/LscContextProvider';
import ZoneContextProvider from '../../../common/state/zone/ZoneContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

const ContextRoutes = ({ navRoutes }) => (
  <TopTimesContextView>
    <Routes>
      <Route path={navRoutes['TOP_TIMES_LSC_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TOP_TIMES_LSC_SEARCH']}
            editElement={<TopTimesLscSearch />} />
        } />
      <Route path={navRoutes['TOP_TIMES_CLUB_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TOP_TIMES_CLUB_SEARCH']}
            editElement={<TopTimesClubSearch />} />
        } />
    </Routes>
  </TopTimesContextView>
);

const TopTimesRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['TOP_TIMES_NATIONAL_SEARCH']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TOP_TIMES_NATIONAL_SEARCH']}
            editElement={<TopTimesNationalSearch />} />
        } />
      <Route path={navRoutes['TOP_TIMES_LSC_SELECT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TOP_TIMES_LSC_SELECT']}
            editElement={<TopTimesLscSelection />} />
        } />
      <Route path={navRoutes['TOP_TIMES_CLUB_SELECT']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TOP_TIMES_CLUB_SELECT']}
            editElement={<TopTimesClubSelection />} />
        } />

      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['TOP_TIMES_NATIONAL_SEARCH']?.route} replace />} />
    </Routes>
  );
};

const TopTimes = () => (
  <EventsDwContextProvider>
    <EventCompetitionGendersDwContextProvider>
      <ReportPeriodSeasonsDwContextProvider>
        <TimeStandardsDwContextProvider>
          <ZoneContextProvider>
            <LscContextProvider>
              <PersonOrgRoleContextProvider>
                <SelectOrgUnitContextProvider>
                  <TopTimesRoutes />
                </SelectOrgUnitContextProvider>
              </PersonOrgRoleContextProvider>
            </LscContextProvider>
          </ZoneContextProvider>
        </TimeStandardsDwContextProvider>
      </ReportPeriodSeasonsDwContextProvider>
    </EventCompetitionGendersDwContextProvider>
  </EventsDwContextProvider>
);

export default TopTimes;