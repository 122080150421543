
const ROUTE_NAME = 'TOP_TIMES_NATIONAL_SEARCH';

const useTopTimesNationalSearch = () => {
  
  return {
    routeName: ROUTE_NAME
  };
};

export default useTopTimesNationalSearch;