import { formatTimeForFilterObject, formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

export const formatTimeStandardCuts = (timeStandardState, formState, FEMALE_COMPETITION_GENDER_ID, MALE_COMPETITION_GENDER_ID, MIXED_EVENT_COMPETITION_GENDER_ID) => {
  let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
  const selectedAgeGroupIndex = timeStandardCopy.timeStandardAgeGroup.findIndex(e => e.timeStandardAgeGroupId === formState.timeStandardAgeGroupId);
  timeStandardCopy.timeStandardAgeGroup[selectedAgeGroupIndex].timeStandardCut = [];

  function PopulateTimeStandardCutArray(timeStandardCutArray, timeStandardAgeGroupId, eventId, competitionGender, swimTime) {
    timeStandardCutArray.push({
      timeStandardAgeGroupId: timeStandardAgeGroupId,
      eventId: eventId,
      eventCompetitionGenderTypeId: competitionGender,
      swimTime: formatTimeForFilterObject(formatTimeForDisplay(swimTime))
    });
  }

  for (const [key, value] of Object.entries(formState)) {
    if (value.length > 0) {
      const lastNine = key.slice(-9);
      let gender = lastNine.substring(0, 1) === "M" ? MALE_COMPETITION_GENDER_ID : lastNine.substring(0, 1) === "F" ? FEMALE_COMPETITION_GENDER_ID : MIXED_EVENT_COMPETITION_GENDER_ID;
      const eventId = key.substring(0, key.length - 9).replace("eventId", "");

      PopulateTimeStandardCutArray(
        timeStandardCopy.timeStandardAgeGroup[selectedAgeGroupIndex].timeStandardCut,
        timeStandardCopy.timeStandardAgeGroup[selectedAgeGroupIndex].timeStandardAgeGroupId,
        eventId,
        gender,
        value)
    };
  }

  return timeStandardCopy;
};