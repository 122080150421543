import Constants from "../../../../common/utils/Constants";
import { isValidDate } from "../../../../common/utils/validation";

const DEFAULT_MAX_RESULTS = 200;

export const localValidate = (formState) => {
  let errors = {};
  let hasRequiredField = false; //TODO required fields for search?
  let hasStartDate = false;
  let maxResults = parseInt(formState.maxResults);

  if (formState.reportPeriodId > 0) {
    hasRequiredField = true;
  }

  if (formState.startDate !== Constants.BLANK_DATE_STRING) {
    hasStartDate = true;

    if (isValidDate(formState.startDate) !== true) {
      errors.startDate = 'Must be a valid date';
    }
  }

  if (formState.endDate !== Constants.BLANK_DATE_STRING) {
    if (hasStartDate === true) {
      hasRequiredField = true;
    }

    if (isValidDate(formState.endDate) !== true) {
      errors.endDate = 'Must be a valid date';
    }
  }

  if (!(formState.eventCompetitionGenderTypeId > 0)) {
    errors.eventCompetitionGenderTypeId = 'Event Gender is required';
  }

  if (maxResults > DEFAULT_MAX_RESULTS) {
    errors.maxResults = `Max Results cannot be greater than ${DEFAULT_MAX_RESULTS}`
  } else if (maxResults < 1) {
    errors.maxResults = 'Max Results must be a positive number';
  }

  if (hasRequiredField === false) {
    errors.hasRequiredField = 'Must select a Season or Start Date and End Date to search';
  }

  return errors;
};

const TopTimesSearchFormValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TopTimesSearchFormValidation;