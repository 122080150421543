import { useEffect } from 'react';

import useTimeStandardNameData from '../../state/timeStandardName/UseTimeStandardNameData';

const useTimeStandardNameCombobox = (orgUnitId, typeId) => {
  const { timeStandardNameState, getTimeStandardNames } = useTimeStandardNameData();

  useEffect(() => {
    if (orgUnitId && typeId) {
      getTimeStandardNames(orgUnitId, typeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, typeId])

  return { timeStandardNameState, getTimeStandardNames };
};

export default useTimeStandardNameCombobox;