import { useEffect } from 'react';
import useRecordTimesData from '../../../state/recordTimesAdmin/UseRecordTimesData';
import useRecordsData from '../../../state/recordsAdmin/UseRecordsData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import useRecordAgeGroupData from '../../../state/recordAgeGroup/UseRecordAgeGroupData';

import validate from './RecordsAdminListInstanceDetailValidation';
import NavLinks from '../NavLinks';

const competitionGenderTypeOptions = [
  { id: Constants.DEFAULT_ID, name: "--" },
  { id: 1, name: "Male" },
  { id: 2, name: "Female" },
  { id: 3, name: "Mixed (Relay Only)" }];

const useRecordsAdminListInstanceDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { recordTimesState } = useRecordTimesData();
  const { recordsState, postRecordListInstanceReturnArray } = useRecordsData();

  const { recordAgeGroupState, getRecordAgeGroups } = useRecordAgeGroupData();
  const { errorState, setErrors, formState, handleSubmit, onValueTextPairChanged, onFormValueChanged } = useForm(getInitialFormState, submitFormCallback, validate);

  function submitFormCallback(formState) {
    const recordListIndex = recordsState.arrayData.findIndex(x => x.recordListId === location.state.recordListId);
    
    let valPos = recordsState.arrayData[recordListIndex].recordEvents.findIndex(
      x => x.eventId === formState.eventId &&
        x.eventCompetitionGenderTypeId === formState.eventCompetitionGenderTypeId &&
        x.recordAgeGroupId === formState.recordAgeGroupId);

    if (valPos !== -1) {
      setErrors({ ...errorState, duplicateRecords: "This Record List Instance already exists" });
    } else {
      postRecordListInstanceReturnArray({
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.recordListId,
        orgUnitId: location.state.orgUnitId,
        eventId: formState.eventId,
        eventCompetitionGenderTypeId: formState.eventCompetitionGenderTypeId,
        recordAgeGroupId: formState.recordAgeGroupId
      });
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.RECORDS_EVENTS, {
      state: {
        recordGroupId: location.state.recordGroupId,
        recordListId: location.state.recordListId,
        orgUnitId: location.state.orgUnitId,
        backFromActionPage: true,
        filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
        filterEventId: location.state.filterEventId,
        filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId
      }
    });
  }

  useEffect(() => {
    if (recordsState.isSaved === true) {
      navigate(NavLinks.RECORDS_EVENTS, {
        state: {
          recordGroupId: location.state.recordGroupId,
          recordListId: location.state.recordListId,
          orgUnitId: location.state.orgUnitId,
          backFromActionPage: true,
          filterRecordAgeGroupId: location.state.filterRecordAgeGroupId,
          filterEventId: location.state.filterEventId,
          filterEventCompetitionGenderTypeId: location.state.filterEventCompetitionGenderTypeId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordsState]);

  useEffect(() => {
    if (recordAgeGroupState.isArrayLoaded === false && recordAgeGroupState.isArrayLoading === false) {
      getRecordAgeGroups();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getInitialFormState() {
    return {
      recordListInstanceId: Constants.DEFAULT_ID,
      eventId: Constants.DEFAULT_ID,
      eventName: '',
      eventCompetitionGenderTypeId: competitionGenderTypeOptions[0].id,
      competitionGenderTypeName: competitionGenderTypeOptions[0].name,
      recordAgeGroupId: Constants.DEFAULT_ID,
      ageGroupDesc: '',
      threshold: ''
    };
  };

  return {
    recordsState,
    recordTimesState,
    formState,
    errorState,
    recordAgeGroupState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onCancelClicked,
    competitionGenderTypeOptions
  }
}

export default useRecordsAdminListInstanceDetail;