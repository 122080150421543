import { useEffect } from 'react';

import useReportPeriodSeasonsDwData from '../../../state/topTimes/reportPeriodSeasonsDw/UseReportPeriodSeasonsDwData';

import { DEFAULT_ID } from '../../../../common/utils/Constants';

const useReportPeriodSeasonDwDropdown = (organizationCode) => {
  const { reportPeriodSeasonsDwState, getReportPeriodSeasonsDw, setReportPeriodSeasonsDwState
  } = useReportPeriodSeasonsDwData();

  useEffect(() => {
    if (organizationCode) {
      if (reportPeriodSeasonsDwState.isArrayLoaded !== true) {
        getReportPeriodSeasonsDw(organizationCode);
      } else if (reportPeriodSeasonsDwState.isArrayLoaded === true) {
        const options = reportPeriodSeasonsDwState.arrayData.map(x => { return { id: x.reportingYearKey, name: x.season } });
        options.unshift({ id: DEFAULT_ID, name: '--' });

        setReportPeriodSeasonsDwState({
          ...reportPeriodSeasonsDwState,
          areOptionsLoaded: true,
          options
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportPeriodSeasonsDwState.isArrayLoaded, organizationCode]);

  return {
    reportPeriodSeasonsDwState
  };
};

export default useReportPeriodSeasonDwDropdown;