import useEventCompetitionGenderDwDropdown from './UseEventCompetitionGenderDwDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const EventCompetitionGenderDwDropdown = ({ label, name, value, error, message, onChange }) => {
  const { eventCompetitionGendersDwState } = useEventCompetitionGenderDwDropdown();

  return eventCompetitionGendersDwState.message
    ? <span className={global.LoadingMsg}>{eventCompetitionGendersDwState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={eventCompetitionGendersDwState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={eventCompetitionGendersDwState.areOptionsLoaded !== true} />
    );
};

export default EventCompetitionGenderDwDropdown;