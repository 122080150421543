import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const IndividualBreaststrokeEventRowsSmall = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <div className={global.TableRow} key={"50BR"}>
      <div className={global.TableRowHead}>50 BR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId14FSwimTime"
          error={errorState.eventId14FSwimTime}
          message={errorState.eventId14FSwimTime}
          value={formState.eventId14FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId14FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId41FSwimTime"
          error={errorState.eventId41FSwimTime}
          message={errorState.eventId41FSwimTime}
          value={formState.eventId41FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId41FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId68FSwimTime"
          error={errorState.eventId68FSwimTime}
          message={errorState.eventId68FSwimTime}
          value={formState.eventId68FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId68FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId14MSwimTime"
          error={errorState.eventId14MSwimTime}
          message={errorState.eventId14MSwimTime}
          value={formState.eventId14MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId14MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId41MSwimTime"
          error={errorState.eventId41MSwimTime}
          message={errorState.eventId41MSwimTime}
          value={formState.eventId41MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId41MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId68MSwimTime"
          error={errorState.eventId68MSwimTime}
          message={errorState.eventId68MSwimTime}
          value={formState.eventId68MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId68MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"100BR"}>
      <div className={global.TableRowHead}>100 BR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId15FSwimTime"
          error={errorState.eventId15FSwimTime}
          message={errorState.eventId15FSwimTime}
          value={formState.eventId15FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId15FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId42FSwimTime"
          error={errorState.eventId42FSwimTime}
          message={errorState.eventId42FSwimTime}
          value={formState.eventId42FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId42FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId69FSwimTime"
          error={errorState.eventId69FSwimTime}
          message={errorState.eventId69FSwimTime}
          value={formState.eventId69FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId69FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId15MSwimTime"
          error={errorState.eventId15MSwimTime}
          message={errorState.eventId15MSwimTime}
          value={formState.eventId15MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId15MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId42MSwimTime"
          error={errorState.eventId42MSwimTime}
          message={errorState.eventId42MSwimTime}
          value={formState.eventId42MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId42MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId69MSwimTime"
          error={errorState.eventId69MSwimTime}
          message={errorState.eventId69MSwimTime}
          value={formState.eventId69MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId69MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"200BR"}>
      <div className={global.TableRowHead}>200 BR
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId16FSwimTime"
          error={errorState.eventId16FSwimTime}
          message={errorState.eventId16FSwimTime}
          value={formState.eventId16FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId16FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId43FSwimTime"
          error={errorState.eventId43FSwimTime}
          message={errorState.eventId43FSwimTime}
          value={formState.eventId43FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId43FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId70FSwimTime"
          error={errorState.eventId70FSwimTime}
          message={errorState.eventId70FSwimTime}
          value={formState.eventId70FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId70FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId16MSwimTime"
          error={errorState.eventId16MSwimTime}
          message={errorState.eventId16MSwimTime}
          value={formState.eventId16MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId16MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId43MSwimTime"
          error={errorState.eventId43MSwimTime}
          message={errorState.eventId43MSwimTime}
          value={formState.eventId43MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId43MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId70MSwimTime"
          error={errorState.eventId70MSwimTime}
          message={errorState.eventId70MSwimTime}
          value={formState.eventId70MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId70MSwimTime', value) }} /></div>
      </div>
    </div>
  </Fragment>
);

export default IndividualBreaststrokeEventRowsSmall;