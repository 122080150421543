import React, { Fragment } from 'react';

import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import BombIcon from '../../../../common/components/icons/BombIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

const LargeGrid = ({ state, onDeleteTimeRecord, onDeleteTime, recordGroupName }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Name'} columnField={'name'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Org Unit'} columnField={'orgUnitCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventCode'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Comp. Category'} columnField={'eventCompetitionGender'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Date'} columnField={'swimDate'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Time'} columnField={'swimTime'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Rank'} columnField={'listRank'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoading === true
          ? (
            <tr>
              {state.isArrayLoading === true ?
                <td colSpan="4">Loading...</td>
                : <td colSpan="4">No Record Times</td>
              }
            </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((timeRecord, i) => (
              <tr key={i}>
                <td>{timeRecord.name}</td>
                <td>{timeRecord.orgUnitCode}</td>
                <td>{timeRecord.eventCode}</td>
                <td>{timeRecord.eventCompetitionGender}</td>
                <td>{timeRecord.meetName}</td>
                <td>{formatDate(timeRecord.swimDate)}</td>
                <td>{formatTimeForDisplay(timeRecord.swimTime)}</td>
                <td>{timeRecord.listRank}</td>
                <td>
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteTimeRecord(e, timeRecord.swimTimeRecordId)}>
                    <DeleteIcon toolTipText="Delete record only" />
                  </button>
                  {recordGroupName !== "All-time Top Relay Splits" &&
                  <button className={global.IconButtonMargin} type="button" onClick={(e) => onDeleteTime(e, timeRecord.swimTimeRecordId)}>
                    <BombIcon toolTipText="Delete time from all tables" />
                  </button>
                  }
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="4">No Time Records</td>
              </tr>
            )
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({ state, onDeleteTimeRecord, onDeleteTime, recordGroupName }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No Records</div>
          }
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((timeRecord, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{timeRecord.name}</span>&nbsp;
              <button className={global.IconButton} type="button" onClick={(e) => onDeleteTimeRecord(e, timeRecord.swimTimeRecordId)}>
                <DeleteIcon />
              </button>
              {recordGroupName !== "All-time Top Relay Splits" &&
              <button  className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteTime(e, timeRecord.swimTimeRecordId)}>
                <BombIcon />
              </button>
              }
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{timeRecord.name}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Org Unit</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.orgUnitCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Event Code</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.eventCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Gender</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.eventCompetitionGender}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.meetName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(timeRecord.swimDate)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Time</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatTimeForDisplay(timeRecord.swimTime)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Rank</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.listRank}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Records</div>
          </div>
        )
    }
  </div >
);

const RecordsAdminCleanupGrid = ({ state, onDeleteTimeRecord, onDeleteTime, recordGroupName }) => {
  return (
  <Fragment>
    <LargeGrid state={state} onDeleteTimeRecord={onDeleteTimeRecord} onDeleteTime={onDeleteTime} recordGroupName={recordGroupName} />
    <SmallGrid state={state} onDeleteTimeRecord={onDeleteTimeRecord} onDeleteTime={onDeleteTime} recordGroupName={recordGroupName} /> 
  </Fragment>
  );
};

export default RecordsAdminCleanupGrid;