import React, { Fragment } from 'react';

import useSearchMyTimeStandards from './UseSearchMyTimeStandards';

import TimeStandardsGrid from './MyTimeStandardsGrid';

import TimeStandardTypeDropdown from '../dropdowns/TimeStandardTypeDropdown';
import TimeStandardNameCombobox from '../comboboxes/TimeStandardNameCombobox';

import Headings from '../../../common/components/headings/Headings';
import ActionIntraPageButton from '../../../common/components/buttons/ActionIntraPageButton';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';

import Constants from '../../../common/utils/Constants';

import global from '../../../common/components/GlobalStyle.module.css';

const Form = ({ handleSubmit, formState, errorState, onValueTextPairChanged,
  DEFAULT_ID, clearForm, onAddTimeStandard, selectOrgUnitState }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <TimeStandardTypeDropdown
          label="Time Standard Type"
          name="timeStandardTypeId"
          value={formState.timeStandardTypeId}
          error={errorState.timeStandardTypeId}
          message={errorState.timeStandardTypeId}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'timeStandardTypeId', newValueLabel, 'timeStandardTypeName', e);
            onValueTextPairChanged(DEFAULT_ID, 'timeStandardId', '', 'timeStandardName', e);
          }}
          orgUnitId={selectOrgUnitState?.orgUnitId ? selectOrgUnitState?.orgUnitId : ''}
        />
      </div>
      {selectOrgUnitState?.orgUnitId && formState.timeStandardTypeId > 0 ?
        <div className="col-xs-12 col-sm-6 col-md-4">
          <TimeStandardNameCombobox
            label="Time Standard Name"
            name="timeStandardName"
            valueToMatch={formState.timeStandardName}
            error={errorState.timeStandardId}
            message={errorState.timeStandardId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'timeStandardId', newValueLabel, 'timeStandardName', e); }}
            orgUnitId={selectOrgUnitState?.orgUnitId ? selectOrgUnitState?.orgUnitId : ''}
            typeId={formState.timeStandardTypeId > 0 ? formState.timeStandardTypeId : 0} />
        </div> : <Fragment />}
      <div className='col-xs-6 col-sm-2 col-md-1'>
        <button className={global.PlainButton} onClick={handleSubmit}>Filter</button>
      </div>
      <div className='col-xs-6 col-sm-4 col-md-2'>
        <button className={global.PlainButton} onClick={clearForm}>Clear Filter</button>
      </div>
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        {onAddTimeStandard && <ActionIntraPageButton type="button" onClick={onAddTimeStandard}>Add Time Standard</ActionIntraPageButton>}
      </div>
    </div>
  </form>
)

const SearchMyTimeStandards = ({ searchTimeStandardState, onAddTimeStandard, onEditTimeStandard, onEditAgeGroup }) => {
  const { timeStandardState, timeStandardTypeState, formState, errorState, handleSubmit, clearForm,
    onValueTextPairChanged, onDeleteTimeStandard, onDeleteAgeGroup, DEFAULT_ID, selectOrgUnitState } = useSearchMyTimeStandards();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Time Standards</Headings.H3>
        </div>
      </div>
      <Form
        handleSubmit={handleSubmit}
        formState={formState}
        errorState={errorState}
        onValueTextPairChanged={onValueTextPairChanged}
        DEFAULT_ID={DEFAULT_ID}
        clearForm={clearForm}
        onAddTimeStandard={onAddTimeStandard}
        selectOrgUnitState={selectOrgUnitState}
      />
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <TimeStandardsGrid
            onEditTimeStandard={onEditTimeStandard}
            onDeleteTimeStandard={onDeleteTimeStandard}
            onEditAgeGroup={onEditAgeGroup}
            onDeleteAgeGroup={onDeleteAgeGroup}
            searchTimeStandardState={searchTimeStandardState}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isArrayLoading || (timeStandardState.isObjLoading && !timeStandardState.isSaving) ||
          timeStandardTypeState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving} />
    </Fragment >
  )
}

export default SearchMyTimeStandards;
