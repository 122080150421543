import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

//TODO shouldn't this be using isSaving & isSaved?
const PostTimeStandardData = (timeStandardObj, state, setState) => {
  if (state.isObjLoading !== true && !state.message) { 
    const newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      objData: {},
      message: 'Loading...'
    };
    setState(newState);

    const url = `/TimeStandard`;
    TimesHttpHelper(url, 'POST', timeStandardObj)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
  }
};

export default PostTimeStandardData;