import React, { Fragment } from 'react';

import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../common/components/icons/EditIcon';
import HideIcon from '../../../common/components/icons/HideIcon';
import ShowIcon from '../../../common/components/icons/ShowIcon';
import SGColumnHeader from '../../../common/components/grids/SGColumnHeader';

import { formatDate } from '../../../common/utils/DateFunctions';
import useSortableGrid from '../../../common/utils/UseSortableGrid';
import SGConstants from '../../../common/utils/SGConstants';

import global from '../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ timeStandard, ageGroup, onEditAgeGroup, onDeleteAgeGroup }) => (
  <tr>
    <td>{ageGroup.ageGroupName || ''}</td>
    <td>{ageGroup.minAge || ''}</td>
    <td>{ageGroup.maxAge || ''}</td>
    <td>
      {onEditAgeGroup &&
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onEditAgeGroup(e, timeStandard.timeStandardId, ageGroup.timeStandardAgeGroupId)}>
          <EditIcon />
        </button>}
      {timeStandard.ageGroups.length > 1 ?
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteAgeGroup(e, timeStandard.timeStandardId, ageGroup.timeStandardAgeGroupId)}>
          <DeleteIcon />
        </button>
        : <Fragment />}
    </td>
  </tr>
);

const DetailTable = ({ timeStandard, onEditAgeGroup, onDeleteAgeGroup }) => (
  <table className={global.DetailTable}>
    <thead>
      <tr>
        <th>Age Group Name</th>
        <th>Min Age</th>
        <th>Max Age</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {Array.isArray(timeStandard.ageGroups) ? timeStandard.ageGroups.map((ageGroup, i) => <DetailTableRow timeStandard={timeStandard} ageGroup={ageGroup} key={i} onEditAgeGroup={onEditAgeGroup} onDeleteAgeGroup={onDeleteAgeGroup} />) : <Fragment />}
    </tbody>
  </table>
);

const TableRow = ({ expandedId, onClick, timeStandard, onEditTimeStandard, onDeleteTimeStandard, onEditAgeGroup, onDeleteAgeGroup }) => (
  <Fragment>
    <tr>
      <td>{timeStandard.standardName || ''}</td>
      <td>{timeStandard.orgUnitName || ''}</td>
      <td>{timeStandard.timeStandardTypeName || ''}</td>
      <td>{formatDate(timeStandard.effectiveDate)} - {formatDate(timeStandard.expirationDate)}</td>
      <td>
        {onEditTimeStandard &&
          <button
            className={global.IconButtonMargin}
            type="button"
            onClick={(e) => onEditTimeStandard(e, timeStandard.timeStandardId)}>
            <EditIcon />
          </button>}
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onDeleteTimeStandard(e, timeStandard.timeStandardId)}>
          <DeleteIcon />
        </button>
        <button
          className={global.IconButtonMargin}
          type="button"
          onClick={(e) => onClick(e, timeStandard.timeStandardId)}>
          {expandedId === timeStandard.timeStandardId ? <HideIcon /> : <ShowIcon />}
        </button>
      </td>
    </tr>
    {Array.isArray(timeStandard.ageGroups) && timeStandard.ageGroups.length > 0 && expandedId === timeStandard.timeStandardId &&
      <tr className={global.Expanded}>
        <td colSpan="6">
          <DetailTable timeStandard={timeStandard} onEditAgeGroup={onEditAgeGroup} onDeleteAgeGroup={onDeleteAgeGroup} />
        </td>
      </tr>}
  </Fragment>
);

const LargeMyTimeStandardsGrid = ({ expandedId, onClick, state, onEditTimeStandard, onDeleteTimeStandard, onEditAgeGroup, onDeleteAgeGroup }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.arrayData);

  return (
    <Fragment>
      <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
        <thead>
          <tr>
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Time Standard Name'} columnField={'standardName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'orgUnitName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Type'} columnField={'timeStandardTypeName'}
              sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
            <th>Dates</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {state.isArrayLoaded !== true
            ? (<tr>
              {state.isArrayLoading === true ?
                <td colSpan="6">Loading...</td> :
                <td colSpan="6">No Results</td>}
            </tr>)
            : Array.isArray(sortableGridState.sortedGridData)
              && sortableGridState.sortedGridData.length > 0
              ? sortableGridState.sortedGridData.map((timeStandard, i) =>
                <TableRow
                  timeStandard={timeStandard}
                  key={i}
                  onEditTimeStandard={onEditTimeStandard}
                  onDeleteTimeStandard={onDeleteTimeStandard}
                  onEditAgeGroup={onEditAgeGroup}
                  onDeleteAgeGroup={onDeleteAgeGroup}
                  expandedId={expandedId}
                  onClick={onClick} />)
              : (<tr>
                <td colSpan="6">No Results</td>
              </tr>)}
        </tbody>
      </table>
    </Fragment>
  );
};

export default LargeMyTimeStandardsGrid;