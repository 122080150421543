import { API_NAMES, initApi } from "../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../common/utils/HttpHelper";

const searchTopTimesData = (filterObject, state, setState) => {
  if (filterObject) {
    const api = initApi(API_NAMES.TIMES, state, setState);
    const url = `/Time/TopTimes/search?filters=${encodeURIComponent(filterObject)}`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_TOP_TIMES_STATE = {
  ...BASIC_INITIAL_STATE
};

const TopTimesData = {
  INITIAL_TOP_TIMES_STATE,
  searchTopTimesData
};

export default TopTimesData;