import { useNavigate } from "../../../common/wrappers/ReactRouterDom";

import useSelectOrgUnitData from "../../../common/state/selectOrgUnit/UseSelectOrgUnitData";
import useNavRoutes from "../../../common/state/security/UseNavRoutes";

const useTopTimesContextView = () => {
  const navigate = useNavigate();
  const { navRoutes } = useNavRoutes();
  const { selectOrgUnitState } = useSelectOrgUnitData();

  const onBackToClicked = () => {
    if (window.location.pathname === navRoutes['TOP_TIMES_LSC_SEARCH']?.route) {
      navigate(navRoutes['TOP_TIMES_LSC_SELECT']?.route);
    } else if (window.location.pathname === navRoutes['TOP_TIMES_CLUB_SEARCH']?.route) {
      navigate(navRoutes['TOP_TIMES_CLUB_SELECT']?.route);
    } else {
      navigate('/');
    }
  };

  return {
    selectOrgUnitState,
    onBackToClicked
  };
};

export default useTopTimesContextView;