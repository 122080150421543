import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

import NavLinks from '../NavLinks';

const useOrgUnitSelection = () => {
  const { MY_TIME_STANDARDS_ROLE_GROUP_ID } = useEnvironmentVariableData();
  return {
    NavLinks,
    MY_TIME_STANDARDS_ROLE_GROUP_ID,
  };
};

export default useOrgUnitSelection;