export const localValidate = (formState) => {
  let hasRequiredField = false;
  let errors = {};

  if (formState.lscOrgUnitId > 0) {
    hasRequiredField = true;
  }

  return errors;
};

const TimesAnomalySearchValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimesAnomalySearchValidation;