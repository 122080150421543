import React from 'react';

import RightArrowIcon from '../../../../common/components/icons/RightArrowIcon';
import global from '../../../../common/components/GlobalStyle.module.css';

const SmallRecordsAdminGrid = ({ state, onView }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No Results</div>
          }
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((record, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{record?.listName}</span>&nbsp;              
              <button className={global.IconButton}
                type="button"
                onClick={() => onView(record.recordListId)}>
                <RightArrowIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Record List Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{record.listName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Organization</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{record.orgUnitCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Record Group Name</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{record.groupName}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Records</div>
          </div>
        )
    }
  </div >
);

export default SmallRecordsAdminGrid;