import { useState } from 'react';

import useTopTimesData from '../../state/topTimes/UseTopTimesData';

const INITIAL_STATE = {
  showFilters: true,
  canDownloadReport: false,
  apiErrorMessage: '' 
};

const useTimeSearch = () => {
  const { topTimesState, searchTopTimes, resetTopTimesState } = useTopTimesData();
  const [state, setState] = useState(INITIAL_STATE);

  const onSubmitFormCallback = (submitObj) => {
    const searchTopTimesPromise = searchTopTimes(submitObj);

    if (searchTopTimesPromise ?? false) {
      searchTopTimesPromise.then((newState) => {
        if (newState ?? false) {
          if (newState.arrayData.length > 0) {
            setState({
              ...state,
              canDownloadReport: true
            });
          } else {
            setState({
              ...state,
              canDownloadReport: false
            });
          }
        }
      }).catch((e) => {
        setState({
          ...state,
          apiErrorMessage: e?.message || 'An error occurred.'
        });
      });
    }
  };

  const onToggleShowFilters = () => {
    setState({
      ...state,
      showFilters: !state.showFilters
    });
  };

  const onCloseErrorPopup = () => {
    setState(INITIAL_STATE);
    resetTopTimesState();
  };

  return {
    ...state,
    topTimesState,
    onSubmitFormCallback,
    onToggleShowFilters,
    onCloseErrorPopup
  };
};

export default useTimeSearch;