import { Fragment } from "react";
import useRecordsAdminTimes from "./UseRecordsAdminTimes";
import Headings from "../../../../common/components/headings/Headings";
import RecordsAdminTimesGrid from "./RecordAdminTimesGrid";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";

const RecordsAdminTimes = () => {
  const { 
    recordEligibleTimesState, 
    addEligibleRecordTimesState, 
    onAddRecordTimesCheckbox,
    onAddSelectedRecords,
    onBack } = useRecordsAdminTimes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Records Admin Eligible Times</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <SecondaryButton type="button" onClick={onBack}>Back</SecondaryButton>&nbsp;&nbsp;
        { addEligibleRecordTimesState.eligibleRecordTimes?.length > 0 &&
          <PrimaryButton type="button" onClick={onAddSelectedRecords}>Add Selected Eligible Times</PrimaryButton>
        }
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <RecordsAdminTimesGrid
            state={recordEligibleTimesState}
            onAddRecordTimesCheckbox={onAddRecordTimesCheckbox}
          />
        </div>
      </div>
    </Fragment>)
}

export default RecordsAdminTimes;