import { Fragment } from 'react';

import useSearchTopTimes from './UseSearchTopTimes';

import TopTimesSearchForm from './forms/TopTimesSearchForm';
import TopTimesSearchGrid from './grids/TopTimesSearchGrid';

import Headings from '../../../common/components/headings/Headings';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import global from '../../../common/components/GlobalStyle.module.css';

const SearchTopTimes = ({ pageTitle, routeName, lscOrgUnitObj = undefined, clubOrgUnitObj = undefined }) => {
  const {
    apiErrorMessage,
    showFilters,
    canDownloadReport,
    topTimesState,
    onSubmitFormCallback,
    onToggleShowFilters,
    onCloseErrorPopup
  } = useSearchTopTimes();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>{pageTitle}</Headings.H3>
        </div>
      </div>
      <div className={showFilters === true ? global.DisplayComponent : global.HideComponent}>
        <TopTimesSearchForm
          onSubmitFormCallback={onSubmitFormCallback}
          canDownloadReport={canDownloadReport === true}
          routeName={routeName}
          lscOrgUnitObj={lscOrgUnitObj}
          clubOrgUnitObj={clubOrgUnitObj} />
      </div>
      <TopTimesSearchGrid
        isLoading={topTimesState.isArrayLoading}
        gridData={topTimesState.arrayData}
        showFilters={showFilters === true}
        onToggleShowFilters={onToggleShowFilters} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={'Top Times Search Error'}
        displayPopUp={apiErrorMessage !== ''}
        onModalCanceled={onCloseErrorPopup}>
        <div className="row">
          <div className="col-xs-12">
            <p>{apiErrorMessage}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onCloseErrorPopup}>Close</PrimaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default SearchTopTimes;