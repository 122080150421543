import { createContext, useState } from 'react';

import ReportPeriodSeasonsDwData from './ReportPeriodSeasonsDwData';

export const ReportPeriodSeasonsDwStateContext = createContext();

const ReportPeriodSeasonsDwContextProvider = ({ children }) => {
  const stateHook = useState(ReportPeriodSeasonsDwData.INITIAL_REPORT_PERIOD_SEASONS_DW_STATE);

  return (
    <ReportPeriodSeasonsDwStateContext.Provider value={stateHook}>
      {children}
    </ReportPeriodSeasonsDwStateContext.Provider>
  );
};

export default ReportPeriodSeasonsDwContextProvider;