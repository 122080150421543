import { Fragment } from 'react';

import useTopTimesContextView from './UseTopTimesContextView';

import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';

import global from '../../../common/components/GlobalStyle.module.css';

const TopTimesContextView = ({ children }) => {
  const {
    selectOrgUnitState,
    onBackToClicked
  } = useTopTimesContextView();

  if (selectOrgUnitState.orgUnitId) {
    return (
      <Fragment>
        {selectOrgUnitState.showBackToSelection === true &&
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onBackToClicked}>
                <LeftArrowIcon />  Back To Selection
              </button>
            </div>
          </div>
        }
        <div className="row usas-extra-bottom-margin">
          <div className={[global.HeaderText, 'col-xs-12'].join(' ')}>
            <div className="usas-extra-bottom-margin"><strong>Viewing:</strong> {selectOrgUnitState.orgUnitName || ''}</div>
          </div>
        </div>
        {children}
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onBackToClicked}>
            <LeftArrowIcon />  Back To Selection
          </button>
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <PageNotFound />
        </div>
      </div>
    </Fragment>
  );
};

export default TopTimesContextView;