import SwimTimeRecognitionData from './SwimTimeRecognitionData';

const useSwimTimeRecognitionData = () => {
  const getSwimTimeRecognition = (swimTimeId, viewState, setViewState) => {
    SwimTimeRecognitionData.getSwimTimeRecognition(swimTimeId, viewState, setViewState);
  };

  const putSwimTimeRecognition = (swimTimeId, swimTimeRecognitionArray, viewState, setViewState) => {
    SwimTimeRecognitionData.putSwimTimeRecognition(swimTimeId, swimTimeRecognitionArray, viewState, setViewState);
  };

  return {
    getSwimTimeRecognition,
    putSwimTimeRecognition
  }
}

export default useSwimTimeRecognitionData;