import { Fragment } from "react";

import useTimeStandardsSearch from "./UseTimeStandardsSearch";

import SearchTimeStandards from "../../../components/searchTimeStandard/SearchTimeStandards";

const TimeStandardsSearch = () => {
  const { searchTimeStandardState, onAddTimeStandard, onEditTimeStandard, onEditAgeGroup } = useTimeStandardsSearch();

  return (
    <Fragment>
      <SearchTimeStandards
        onAddTimeStandard={onAddTimeStandard}
        onEditTimeStandard={onEditTimeStandard}
        onEditAgeGroup={onEditAgeGroup}
        searchTimeStandardState={searchTimeStandardState}
      />
    </Fragment>);

}
export default TimeStandardsSearch;