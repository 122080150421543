import { Fragment } from "react";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Dropdown from "../../../../common/components/dropdowns/Dropdown";
import Headings from "../../../../common/components/headings/Headings";
import useRecordsAdminSearch from "./UseRecordsAdminSearch";
import Constants from "../../../../common/utils/Constants";

import RecordsAdminGrid from "./RecordsAdminGrid";
import RecordsListCombobox from "../../../components/comboboxes/recordsList/RecordsListCombobox";

const Form = ({ handleSubmit, formState, errorState, recordGroupState, onValueTextPairChanged }) => (
  <form onSubmit={handleSubmit} noValidate>
    <div className="row">
      <div className="col-xs-12 col-sm-6 col-md-4">
        <Dropdown
          label="Record Groups"
          options={recordGroupState.options}
          name="recordGroupId"
          value={formState.recordGroupId}
          error={errorState.recordGroupId}
          message={errorState.recordGroupId}
          areValuesIntegers={true}
          onChange={(newValue, newValueLabel, e) => {
            onValueTextPairChanged(newValue, 'recordGroupId', newValueLabel, 'groupName', e);
            onValueTextPairChanged(-1, 'recordListId', '', 'recordListName', e);
          }}
        />
      </div>
      {formState?.recordGroupId > 0 ?
        <div className="col-xs-12 col-sm-6 col-md-4">
          <RecordsListCombobox
            label="Record List"
            name="recordListName"
            valueToMatch={formState.recordListName}
            error={errorState.recordListId}
            message={errorState.recordListId}
            onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'recordListId', newValueLabel, 'recordListName', e); }}
            recordGroupId={formState.recordGroupId ? formState.recordGroupId : 0}
          />
        </div>
        : <Fragment />}
    </div>
    <div className="row usas-extra-top-margin">
      <div className="col-xs-12">
        <PrimaryButton type="submit">Filter Records</PrimaryButton>&nbsp;
      </div>
    </div>
  </form>
)

const RecordsAdminSearch = () => {
  const {
    formState,
    handleSubmit,
    errorState,
    recordGroupState,
    onFormValueChanged,
    onValueTextPairChanged,
    onView
  } = useRecordsAdminSearch();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Records Admin</Headings.H3>
        </div>
      </div>
      {recordGroupState.arrayData.length > 2 &&
      <Form
        handleSubmit={handleSubmit}
        formState={formState}
        errorState={errorState}
        recordGroupState={recordGroupState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
      />
      }
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <RecordsAdminGrid
            onView={onView}
          />
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={recordGroupState.isArrayLoading} />
    </Fragment>
  );
}

export default RecordsAdminSearch;