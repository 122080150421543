import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

export const INITIAL_STATE = {
  isLoading: false,
  isLoaded: false,
  orgUnitId: '',
  typeId: 0,
  data: [],
  items: [],
  message: ''
};

const getTimeStandardNames = (orgUnitId, typeId, state, setState) => {
  if (state.isLoading !== true && !state.message) {
    let newState = {
      ...state,
      isLoading: true,
      isLoaded: false,
      orgUnitId,
      typeId,
      message: 'Loading...'
    };
    setState(newState);

    const orgUnitIdForUrl = orgUnitId ? encodeURIComponent(orgUnitId) : '';
    const typeIdForUrl = typeId ? encodeURIComponent(typeId) : 0;

    const url = `/TimeStandard/GetForOrgUnitAndType/${orgUnitIdForUrl}/${typeIdForUrl}`;
    TimesHttpHelper(url, 'GET')
      .then((data) => {
        if (!data) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isLoading: false,
            isLoaded: true,
            orgUnitId,
            typeId,
            data: data,
            items: data.map((x) => { return { id: x.timeStandardId, name: x.standardName } }),
            message: ''
          };
          setState(newState);
        }

      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isLoading: false,
          isLoaded: false,
          message: message
        });
      });
  }
}

const TimeStandardNameData = { INITIAL_STATE, getTimeStandardNames };

export default TimeStandardNameData;