import { Fragment } from 'react';

import TopTimesSearchGridLarge from './TopTimesSearchGridLarge';
import TopTimesSearchGridSmall from './TopTimesSearchGridSmall';

import global from '../../../../common/components/GlobalStyle.module.css';

const TopTimesSearchGrid = ({ isLoading, gridData, onToggleShowFilters, showFilters }) => (
  <Fragment>
    {isLoading === false
      ? (
        <div className="row">
          <div className="col-xs-12 col-sm-10">
            <p>{gridData.length === 1 ? `${gridData.length} Search Result` : `${gridData.length} Search Results`}</p>
          </div>
          <div className="col-xs-12 col-sm-2">
            <button className={global.ToggleButton} type="button" onClick={onToggleShowFilters}>
              {showFilters === true ? 'Hide Filters' : 'Show Filters'}
            </button>
          </div>
        </div>
      )
      : <div className="row usas-extra-top-margin usas-extra-bottom-margin"></div>
    }
    <TopTimesSearchGridLarge
      isLoading={isLoading}
      gridData={gridData} />
    <TopTimesSearchGridSmall
      isLoading={isLoading}
      gridData={gridData} />
  </Fragment>
);

export default TopTimesSearchGrid;