import React, { Fragment } from 'react';

import useMyTimeStandardAgeGroupCuts from './UseMyTimeStandardAgeGroupCuts';

import MyTimeStandardAgeGroupCutsGrid from './components/MyTimeStandardAgeGroupCutsGrid';

import Headings from '../../../../common/components/headings/Headings';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import { formatDate } from '../../../../common/utils/DateFunctions';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const MyTimeStandardAgeGroupCuts = () => {
  const { timeStandardState, state, formState, errorState, handleSubmit, onFormValueChanged,
    onCancelClicked } = useMyTimeStandardAgeGroupCuts();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Time Standard Age Group Cuts</Headings.H3>
        </div>
      </div>
      {timeStandardState.isObjLoaded === true ?
        <div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>Time Standard:</b> {timeStandardState.objData?.standardName} (Qualifying Dates: {formatDate(timeStandardState.objData?.effectiveDate)} - {formatDate(timeStandardState.objData?.expirationDate)})</p>
            <p><b>Age Group:</b> {state.ageGroup}</p>
          </div>
        </div> : <Fragment />}
      <form onSubmit={handleSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 ">
            <MyTimeStandardAgeGroupCutsGrid
              state={timeStandardState}
              formState={formState}
              errorState={errorState}
              onFormValueChanged={onFormValueChanged} />
          </div>
        </div>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeStandardState.isObjLoading && !timeStandardState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeStandardState.isSaving} />
    </Fragment>
  )
}

export default MyTimeStandardAgeGroupCuts;
