import Constants from '../../../common/utils/Constants';

export const localValidate = (formState) => {
  let hasRequiredField = false;
  let errors = {};

  if (formState.orgUnit.length > 0) {
    hasRequiredField = true;
  }

  if (formState.timeStandardTypeId !== Constants.DEFAULT_ID) {
    hasRequiredField = true;
  }

  if (formState.timeStandardName.trim() !== '' && formState.timeStandardId < 0) {
    errors.timeStandardId = 'Must be a valid Time Standard Name';
  }

  if (hasRequiredField === false) {
    errors.filter = 'Must filter by an Organization or Time Standard Type to view time standard results';
  }

  return errors;
};

const SearchTimeStandardsValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default SearchTimeStandardsValidation;