import useTimeStandardsDwDropdown from './UseTimeStandardsDwDropdown';

import Dropdown from '../../../../common/components/dropdowns/Dropdown';

import global from '../../../../common/components/GlobalStyle.module.css';

const TimeStandardsDwDropdown = ({ label, name, value, error, message, onChange, organizationCode }) => {
  const { timeStandardsDwState } = useTimeStandardsDwDropdown(organizationCode);

  return timeStandardsDwState.message
    ? <span className={global.LoadingMsg}>{timeStandardsDwState.message}</span>
    : (
      <Dropdown
        error={error}
        label={label}
        message={message}
        options={timeStandardsDwState.options}
        name={name}
        value={value}
        onChange={onChange}
        isLoading={timeStandardsDwState.areOptionsLoaded !== true} />
    );
};

export default TimeStandardsDwDropdown;