import { useEffect, useState } from 'react';
import useTimesAnomalyData from '../../../state/timesAnomaly/UseTimesAnomalyData';
import validate from './TimesAnomalySearchValidation';

import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import useSelectOrgUnitData from '../../../../common/state/selectOrgUnit/UseSelectOrgUnitData';
import useEmbeddedReportData from '../../../../common/state/embeddedReport/UseEmbeddedReportData';
import useDecryptIdData from '../../../../common/state/decryptId/UseDecryptIdData';

const INITIAL_VIEW_STATE = {
  reportParameters: { PersonId: '', EventId: '' },
  personId: '',
  eventId: 0,
  routeName: '',
  isLoading: false,
  reportViewerType: '',
  reportName: ''
};

const useTimesAnomalySearch = () => {
  const { USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { timesAnomalyState, searchTimesAnomaly, putTimesAnomalyStatusUpdate, deleteTimesAnomaly } = useTimesAnomalyData();
  const { selectOrgUnitState } = useSelectOrgUnitData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged } = useForm(getInitialFormState, submitFormCallback, validate);
  const { reportListState, getEmbeddedReportListForRoute } = useEmbeddedReportData();
  const { getDecryptedId } = useDecryptIdData();
  const [ viewState, setViewState ] = useState(INITIAL_VIEW_STATE);
  const [ modalState, setModalState ] = useState(getInitialModalState());

  const onVerify = (swimTimeAnomalyId) => {
    const filterOrgUnitId = formState.lscOrgUnitId === Constants.DEFAULT_ID ? selectOrgUnitState.orgUnitId : formState.lscOrgUnitId;
    putTimesAnomalyStatusUpdate(swimTimeAnomalyId, filterOrgUnitId);
  };

  const onDeleteTime = () => {
    const filterOrgUnitId = formState.lscOrgUnitId === Constants.DEFAULT_ID ? selectOrgUnitState.orgUnitId : formState.lscOrgUnitId;
    deleteTimesAnomaly(modalState.swimTimeAnomalyId, filterOrgUnitId);
    setModalState(getInitialModalState());
  }

  const onDisplayReport = (anomaly) => {
    const idObj = {
      "personId": anomaly.personId
    }

    const getDecryptedIdPromise = getDecryptedId(idObj);

    if (getDecryptedIdPromise ?? false) {
      getDecryptedIdPromise.then((newState) => {

        setViewState({
          ...viewState,
          reportParameters: { PersonId: newState.objData.personNumber, EventId: anomaly.eventId },
          routeName: 'TIMES_ANOMALY_SELECTION',
          isLoading: true,
          reportViewerType: 'ActiveReports',
          reportName: 'Anomaly Detection Report'
        });
      }).catch ((e) => {
      console.log(e);
    })
  }
}

const onModalCanceled = (e) => {
  if (e && e.preventDefault) {
    e.preventDefault();
  }
  setModalState(getInitialModalState());
};

const onOpenModalClicked = (swimTimeAnomalyId) => {
  const anomalyObj = timesAnomalyState.arrayData.find(x => x.swimTimeAnomalyId === swimTimeAnomalyId);

  setModalState({
    ...modalState,
    swimTimeAnomalyId: swimTimeAnomalyId,
    swimmerName: anomalyObj.firstName + ' ' + anomalyObj.lastName,
    swimTime: formatTimeForDisplay(anomalyObj.swimTime),
    displayPopUp: true
  });
};

useEffect(() => {
  if (timesAnomalyState.isArrayLoaded === false && timesAnomalyState.isArrayLoading === false) {
    searchTimesAnomaly(selectOrgUnitState.orgUnitId);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);


useEffect(() => {
  if (viewState.routeName) {
    const reportListPromise = getEmbeddedReportListForRoute(viewState.routeName);

    if (reportListPromise) {
      reportListPromise.then((stateObj) => {
        if (Array.isArray(stateObj.arrayData)) {
          const reportIdx = stateObj.arrayData.findIndex(x => x.reportName === viewState.reportName);

          setViewState({
            ...viewState,
            isLoading: false,
            showReport: stateObj.arrayData.length > 0,
            reportInfoId: stateObj.arrayData[reportIdx].reportInfoId,
          });
        } else {
          setViewState({
            ...viewState,
            isLoading: false,
            showReport: false,
            reportInfoId: -1
          });
        }
      }).catch((err) => {
        setViewState({
          ...viewState,
          isLoading: false,
          showReport: false,
          reportInfoId: -1
        });
      });
    }
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [viewState.routeName, viewState.reportViewerType]);

function submitFormCallback() {
  searchTimesAnomaly(formState.lscOrgUnitId === Constants.DEFAULT_ID ? USAS_ORG_UNIT_ID : formState.lscOrgUnitId);
}

function getInitialFormState() {
  return {
    lscOrgUnitId: Constants.DEFAULT_ID,
    orgUnitId: ''
  };
};

function getInitialModalState() {
  return {
    displayPopUp: false,
    currentTimeInfo: '',
    modalTitle: 'Delete all time usage?'
  };
};

return {
  selectOrgUnitState,
  USAS_ORG_UNIT_ID,
  timesAnomalyState,
  formState,
  handleSubmit,
  errorState,
  viewState,
  modalState,
  setViewState,
  onValueTextPairChanged,
  onVerify,
  onDeleteTime,
  onDisplayReport,
  onModalCanceled,
  onOpenModalClicked,
  INITIAL_VIEW_STATE
}
};

export default useTimesAnomalySearch;