import React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import TimeStandardContextProvider from '../../state/timeStandard/TimeStandardContextProvider';
import TimeStandardFiltersContextProvider from '../../state/timeStandard/TimeStandardFiltersContextProvider';
import SearchTimeStandardContextProvider from '../../state/searchTimeStandard/SearchTimeStandardContextProvider';

import TimeStandardTypeContextProvider from '../../../common/state/timeStandardType/TimeStandardTypeContextProvider';
import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';

import MyTimeStandardsContextView from './MyTimeStandardsContextView';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import MyTimeStandardsSearch from './myTimeStandardsSearch/MyTimeStandardsSearch';
import MyTimeStandardDetail from './myTimeStandardDetail/MyTimeStandardDetail';
import MyTimeStandardAgeGroup from './myTimeStandardAgeGroup/MyTimeStandardAgeGroup';
import MyTimeStandardAgeGroupAdd from './myTimeStandardAgeGroupAdd/MyTimeStandardAgeGroupAdd';
import MyTimeStandardAgeGroupCuts from './myTimeStandardAgeGroupCuts/MyTimeStandardAgeGroupCuts';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <TimeStandardTypeContextProvider>
    <TimeStandardContextProvider>
      <TimeStandardFiltersContextProvider>
        <SearchTimeStandardContextProvider>
          <MyTimeStandardsContextView>
            <Routes>
              <Route path={navRoutes['MY_TIME_STANDARDS_SEARCH']?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes['MY_TIME_STANDARDS_SEARCH']}
                    editElement={<MyTimeStandardsSearch />} />
                }
              />
              <Route path={navRoutes['MY_TIME_STANDARDS_DETAIL']?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes['MY_TIME_STANDARDS_DETAIL']}
                    editElement={<MyTimeStandardDetail />} />
                }
              />
              <Route path={navRoutes['MY_TIME_STANDARDS_AGEGROUP']?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes['MY_TIME_STANDARDS_AGEGROUP']}
                    editElement={<MyTimeStandardAgeGroup />} />
                }
              />
              <Route path={navRoutes['MY_TIME_STANDARDS_AGEGROUP_ADD']?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes['MY_TIME_STANDARDS_AGEGROUP_ADD']}
                    editElement={<MyTimeStandardAgeGroupAdd />} />
                }
              />
              <Route path={navRoutes['MY_TIME_STANDARDS_AGEGROUP_CUTS']?.path}
                element={
                  <SecuredRouteElement
                    navRoute={navRoutes['MY_TIME_STANDARDS_AGEGROUP_CUTS']}
                    editElement={<MyTimeStandardAgeGroupCuts />} />
                }
              />
            </Routes>
          </MyTimeStandardsContextView>
        </SearchTimeStandardContextProvider>
      </TimeStandardFiltersContextProvider>
    </TimeStandardContextProvider>
  </TimeStandardTypeContextProvider>
);

const MyTimeStandardsRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['MY_TIME_STANDARDS_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['MY_TIME_STANDARDS_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['MY_TIME_STANDARDS_SELECTION']?.route} replace />} />
    </Routes>
  )
};

const MyTimeStandards = () => (

  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <MyTimeStandardsRoutes />
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>

);

export default MyTimeStandards;