import React, { Fragment } from 'react';

import useRecordsAdminSearch from './UseRecordsAdminSearch';

import LargeRecordsAdminGrid from './LargeRecordsAdminGrid';
import SmallRecordsAdminGrid from './SmallRecordsAdminGrid';

import global from '../../../../common/components/GlobalStyle.module.css'

const RecordsAdminGrid = ({ onView }) => {
  const { recordsState } = useRecordsAdminSearch();

  return (
    <Fragment>
      {recordsState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {recordsState.arrayData.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{recordsState.arrayData.length === 1 ? `${recordsState.arrayData.length} Search Result` : `${recordsState.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      : <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
      <LargeRecordsAdminGrid 
        data={recordsState.arrayData}
        isLoading={recordsState.isArrayLoading}
        onView={onView}
      />
      <SmallRecordsAdminGrid
        state={recordsState}
        onView={onView}
      />
    </Fragment>
  )
};

export default RecordsAdminGrid;