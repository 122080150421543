import { useEffect, useState } from 'react';

import validate from './SearchTimeStandardsValidation';

import useSearchTimeStandardData from '../../state/searchTimeStandard/UseSearchTimeStandardData';
import useTimeStandardData from '../../state/timeStandard/UseTimeStandardData';

import useTimeStandardTypeData from '../../../common/state/timeStandardType/UseTimeStandardTypeData';
import useOrgUnitData from '../../../common/state/orgUnit/UseOrgUnitData';

import useForm from '../../../common/utils/UseForm';
import Constants from '../../../common/utils/Constants';
import useTimeStandardNameData from '../../state/timeStandardName/UseTimeStandardNameData';

const useSearchTimeStandards = () => {
  const [state, setState] = useState({
    setInitialState: true,
    tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID,
    tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: false
  });
  const { searchTimeStandardState, setSearchTimeStandardState } = useSearchTimeStandardData();
  const { timeStandardState, timeStandardFiltersState, searchTimeStandards, clearObjData, getTimeStandard, putTimeStandard,
    deleteTimeStandard } = useTimeStandardData();
  const { timeStandardTypeState } = useTimeStandardTypeData();
  const { timeStandardNameState } = useTimeStandardNameData();
  const { orgUnitState } = useOrgUnitData();
  const { errorState, formState, handleSubmit, onValueTextPairChanged, onFormValueChanged, setFormState, setFormData, setIsDirty, setErrors,
    isDirty } = useForm(getInitialFormState, submitFormCallback, validate);

  const [sortByState] = useState([
    { id: '', name: "--", value: '' },
    { id: 'StandardName', name: "Time Standard Name", value: 'StandardName' },
    { id: 'OrgUnitName', name: "Organization", value: 'OrgUnitName' },
    { id: 'TimeStandardTypeName', name: "Time Standard Type", value: 'TimeStandardTypeName' },
    { id: 'EffectiveDate', name: "Effective Date", value: 'EffectiveDate' },
    { id: 'ExpirationDate', name: "Expiration Date", value: 'ExpirationDate' }
  ]);

  const createSearchFilterObject = () => {
    const filterObject = {
      orgUnit: (formState.orgUnit.length <= 0) ? undefined : formState.orgUnit,
      timeStandardTypeId: (formState.timeStandardTypeId === Constants.DEFAULT_ID) ? undefined : formState.timeStandardTypeId,
      timeStandardName: (formState.timeStandardName === '') ? undefined : formState.timeStandardName,
      isCurrent: (formState.isCurrent === false) ? undefined : formState.isCurrent
    }

    return filterObject;
  }

  function submitFormCallback(formState) {
    setSearchTimeStandardState({ ...searchTimeStandardState, tryRedirect: false });
    const sortBy = formState.sortBy === undefined ? '' : formState.sortBy;
    searchTimeStandards(createSearchFilterObject(), sortBy);
  }

  const onOrgUnitChanged = (newValue) => {
    setFormState({
      ...formState,
      orgUnit: newValue
    });
    setIsDirty(true);
  };

  const clearForm = () => {
    setFormState(getInitialFormState());
    setErrors({});
  };

  const onToggleFilters = () => {
    setSearchTimeStandardState({ ...searchTimeStandardState, showFilters: !searchTimeStandardState.showFilters });
    setIsDirty(true);
  }

  const onDeleteTimeStandard = (e, timeStandardId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setState({ ...state, tryTimeStandardDelete: true, timeStandardId: timeStandardId });
  }

  const editTimeStandardAgeGroupArray = (timeStandardAgeGroupId) => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    const index = timeStandardCopy.timeStandardAgeGroup.findIndex(e => e.timeStandardAgeGroupId === timeStandardAgeGroupId);

    timeStandardCopy.timeStandardAgeGroup.splice(index, 1);

    return timeStandardCopy;
  }

  const onDeleteAgeGroup = (e, timeStandardId, timeStandardAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    };

    getTimeStandard(timeStandardId);
    setState({ ...state, tryAgeGroupDelete: true, timeStandardAgeGroupId: timeStandardAgeGroupId });
  }

  useEffect(() => {
    if (searchTimeStandardState.showFilters === true && isDirty === false) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTimeStandardState.showFilters, isDirty]);

  useEffect(() => {
    // Check for previous search values to repopulate the form
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0 && orgUnitState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      searchTimeStandards(oldSearchValues, timeStandardFiltersState.sortBy);
      setFormData({
        ...formState,
        orgUnit: oldSearchValues.orgUnit || [],
        isCurrent: oldSearchValues.isCurrent ?? false,
        sortBy: timeStandardFiltersState.sortBy
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitState.isArrayLoaded]);

  useEffect(() => {
    // Handle repopulating time standard type on the form
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0 && state.setInitialState === true
      && timeStandardTypeState.isArrayLoaded === true && timeStandardTypeState.isOptionsLoaded === true && orgUnitState.isArrayLoaded === true) {
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      if ((oldSearchValues.orgUnit && formState.orgUnit[0]?.id === oldSearchValues.orgUnit[0]?.id && timeStandardTypeState.orgUnitId === formState.orgUnit[0]?.id) ||
        (oldSearchValues.orgUnit === undefined && formState.orgUnit.length === 0 && timeStandardTypeState.orgUnitId === '')) {
        if (oldSearchValues.timeStandardName === undefined) {
          setState({ ...state, setInitialState: false });
        }
        setFormData({
          ...formState,
          timeStandardTypeId: oldSearchValues.timeStandardTypeId || Constants.DEFAULT_ID,
          timeStandardTypeName: oldSearchValues.timeStandardTypeId ? timeStandardTypeState.options.find(x => x.id === oldSearchValues.timeStandardTypeId)?.name || '' : ''
        });

      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.orgUnit, timeStandardTypeState, orgUnitState.isArrayLoaded]);

  useEffect(() => {
    // Handle repopulating time standard name on the form
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0 && state.setInitialState === true
      && timeStandardTypeState.isArrayLoaded === true && timeStandardTypeState.isOptionsLoaded === true &&
      orgUnitState.isArrayLoaded === true && timeStandardNameState.isLoading !== true) {
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      if (oldSearchValues.orgUnit && formState.orgUnit[0]?.id === oldSearchValues.orgUnit[0]?.id && timeStandardTypeState.orgUnitId === formState.orgUnit[0]?.id) {
        if (oldSearchValues.timeStandardTypeId && formState.timeStandardTypeId === oldSearchValues.timeStandardTypeId) {
          setState({ ...state, setInitialState: false });
          setFormData({
            ...formState,
            timeStandardId: oldSearchValues.timeStandardName ? timeStandardNameState.items.find(x => x.name === oldSearchValues.timeStandardName)?.id || '' : '',
            timeStandardName: oldSearchValues.timeStandardName || ''
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.timeStandardTypeId, timeStandardTypeState, orgUnitState.isArrayLoaded, timeStandardNameState]);

  useEffect(() => {
    //handle deletes
    if (Object.keys(timeStandardState.objData).length > 0) {
      //delete age group
      if (state.tryAgeGroupDelete === true && state.timeStandardAgeGroupId !== Constants.DEFAULT_ID) {
        putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardAgeGroupArray(state.timeStandardAgeGroupId));
        setState({ ...state, tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: true });
      }
      //delete time standard
      if ((state.tryTimeStandardDelete === true && state.timeStandardId !== Constants.DEFAULT_ID)) {
        clearObjData();
        deleteTimeStandard(state.timeStandardId);
        setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, redoSearch: true });
      }
    }
    else {
      //delete time standard
      if ((state.tryTimeStandardDelete === true && state.timeStandardId !== Constants.DEFAULT_ID)) {
        deleteTimeStandard(state.timeStandardId);
        setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, redoSearch: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData, state]);

  useEffect(() => {
    // Check for previous search values to repopulate the form after a successful delete
    if (Object.keys(timeStandardFiltersState.filterObject).length > 0
      && timeStandardState.isObjLoading === false && state.redoSearch === true) {
      clearObjData();
      setState({ ...state, tryTimeStandardDelete: false, timeStandardId: Constants.DEFAULT_ID, tryAgeGroupDelete: false, timeStandardAgeGroupId: Constants.DEFAULT_ID, redoSearch: false });
      const oldSearchValues = JSON.parse(timeStandardFiltersState.filterObject);
      searchTimeStandards(oldSearchValues, timeStandardFiltersState.sortBy);
      setFormState({
        ...formState,
        ...oldSearchValues,
        sortBy: timeStandardFiltersState.sortBy
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.isObjLoading, state.redoSearch]);

  function getInitialFormState() {
    return {
      orgUnit: [],
      timeStandardTypeId: Constants.DEFAULT_ID,
      timeStandardTypeName: '',
      timeStandardId: Constants.DEFAULT_ID,
      timeStandardName: '',
      isCurrent: false,
      sortBy: ''
    };
  };

  return {
    timeStandardState,
    orgUnitState,
    timeStandardTypeState,
    formState,
    errorState,
    handleSubmit,
    onValueTextPairChanged,
    onFormValueChanged,
    onOrgUnitChanged,
    onDeleteTimeStandard,
    onDeleteAgeGroup,
    clearForm,
    onToggleFilters,
    sortByOptions: sortByState,
    DEFAULT_ID: Constants.DEFAULT_ID
  }
};

export default useSearchTimeStandards;