import React from 'react';

import BugIcon from '../../../../common/components/icons/BugIcon';
import global from '../../../../common/components/GlobalStyle.module.css';

const SmallRecordsAdminEventsGrid = ({ data, isLoading, onCleanupRecords, getGenderDescription }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {isLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          <div className={global.SmallTableRowLabels}>Loading... </div>
        </div>
      ) : Array.isArray(data) && data.length > 0
        ? data.map((recordEvent, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{recordEvent?.eventCode}</span>&nbsp;
              <button className={global.IconButton}
                type="button"
                onClick={() => onCleanupRecords(recordEvent.recordlistInstanceId)}>
                <BugIcon />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Competition Category</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{getGenderDescription(recordEvent.eventCompetitionGenderTypeId)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Age Group</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{recordEvent.ageGroupDesc}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Records</div>
          </div>
        )
    }
  </div >
);

export default SmallRecordsAdminEventsGrid;