import React from 'react';

import useTimeStandardNameCombobox from './UseTimeStandardNameCombobox';

import Combobox from '../../../common/components/comboboxes/Combobox';

import global from '../../../common/components/GlobalStyle.module.css';

const TimeStandardNameCombobox = ({ label, name, valueToMatch, error, message, onChange, orgUnitId, typeId }) => {
  const { timeStandardNameState } = useTimeStandardNameCombobox(orgUnitId, typeId);

  return timeStandardNameState.message
    ? <span className={global.LoadingMsg}>{timeStandardNameState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={timeStandardNameState.isLoading}
        items={timeStandardNameState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default TimeStandardNameCombobox;