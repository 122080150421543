import { useContext } from 'react';

import RecordsData from './RecordsData';
import { RecordsStateContext } from './RecordsContextProvider';

const useRecordsData = () => {
  const [recordsState, setRecordsState] = useContext(RecordsStateContext);

  const searchRecords = (filterObject) => {
    RecordsData.searchRecords(JSON.stringify(filterObject), recordsState, setRecordsState);
  };

  const postRecordListInstance = (recordListInstanceObject) => {
    RecordsData.postRecordListInstance(recordListInstanceObject, recordsState, setRecordsState);
  }

  const postRecordListInstanceReturnArray = (recordListInstanceObject) => {
    RecordsData.postRecordListInstanceReturnArray(recordListInstanceObject, recordsState, setRecordsState);
  }

  const deleteRecordListInstance = (recordListInstanceId) => {
    RecordsData.deleteRecordListInstance(recordListInstanceId, recordsState, setRecordsState)
  }

  const deleteRecordListInstanceReturnArray = (recordListInstanceId) => {
    RecordsData.deleteRecordListInstanceReturnArray(recordListInstanceId, recordsState, setRecordsState)
  }

  const clearObjData = () => {
    setRecordsState({
      ...recordsState,
      isObjLoading: false,
      isObjLoaded: false,
      isSaving: false,
      isSaved: false,
      objData: {},
      message: ''
    });
  };

  const clearSearchResults = () => {
    setRecordsState({
      ...recordsState,
      isArrayloaded: false,
      isArrayloading: false,
      arrayData: [],
      message: ''
    })
  };

  return {
    recordsState,
    searchRecords,
    postRecordListInstance,
    postRecordListInstanceReturnArray,
    deleteRecordListInstance,
    deleteRecordListInstanceReturnArray,
    clearObjData,
    clearSearchResults
  };
};

export default useRecordsData;