import { useContext } from "react";

import EventCompetitionGendersDwData from "./EventCompetitionGendersDwData";
import { EventCompetitionGendersDwStateContext } from "./EventCompetitionGendersDwContextProvider";

const useEventCompetitionGendersDwData = () => {
  const [eventCompetitionGendersDwState, setEventCompetitionGendersDwState] = useContext(EventCompetitionGendersDwStateContext);

  const getEventCompetitionGendersDw = () => {
    return EventCompetitionGendersDwData.getEventCompetitionGendersDwData(eventCompetitionGendersDwState, setEventCompetitionGendersDwState);
  };

  return {
    eventCompetitionGendersDwState,
    getEventCompetitionGendersDw,
    setEventCompetitionGendersDwState
  };
};

export default useEventCompetitionGendersDwData;