import React, { Fragment } from 'react';

import useManageTimesContextView from './UseManageTimesContextView';
import { navLinks } from './UseManageTimes';

import Headings from '../../../common/components/headings/Headings';
import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';
import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import Constants from '../../../common/utils/Constants';
import MobileContextBasedNav from '../../../common/components/contextBasedNav/MobileContextBasedNav';
import LargeContextBasedNav from '../../../common/components/contextBasedNav/LargeContextBasedNav';

import global from '../../../common/components/GlobalStyle.module.css';

const ManageTimesContextView = ({ children }) => {
  const {
    state,
    timeState,
    backToLabel,
    onClicked,
    onHomeClicked
  }
    = useManageTimesContextView();

  if (!timeState || timeState.isObjLoaded === false) {
    return timeState.isObjLoading === false
      ? (
        <Fragment>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <button className={global.BackToSearchButton}
                type="button"
                onClick={onClicked}>
                <LeftArrowIcon />  {backToLabel}
              </button>
            </div>
          </div>
          <div className="row usas-extra-bottom-margin">
            <div className="col-xs-12">
              <PageNotFound />
            </div>
          </div>
        </Fragment>
      ) : (
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PopUpModal
              widthPct={50}
              maxWidth={250}
              title={Constants.LOADING_MSG}
              displayPopUp={true} />
          </div>
        </div>
      );
  }

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.HeaderButton} onClick={onHomeClicked}>
            <Headings.H3
              className={[global.NoBottomMargin, 'col-xs-12'].join(' ')}
              excludeBar={true}>
              View/Edit Time
            </Headings.H3>
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <hr className="usas-bar-turmeric" />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          <button className={global.BackToSearchButton}
            type="button"
            onClick={onClicked}>
            <LeftArrowIcon />  {backToLabel}
          </button>
        </div>
      </div>
      {(state.dataIsLoading === true)
        ? <Fragment />
        : (<div className="row">
          <div className={["col-xs-12", global.HeaderText].join(' ')}>
            <p><b>Meet Name:</b> {state.meetName || ''}</p>
            <p><b>Meet Start - End Dates:</b> {state.meetStartDate || ''} - {state.meetEndDate || ''}</p>
            <p><b>Athlete Name(s):</b> {state.competitorNames || ''}</p>
          </div>
        </div>)
      }
      <div className="row">
        <div className="visible-xs col-xs-12">
          <MobileContextBasedNav taxonomyName="TimesDetail" renderPillsOnly={true} />
        </div>
        <div className="hidden-xs col-xs-12">
          <LargeContextBasedNav taxonomyName="TimesDetail" homeRoute={navLinks.DETAIL} renderPillsOnly={true} />
        </div>
      </div>
      {children}
    </Fragment >
  );
};

export default ManageTimesContextView;