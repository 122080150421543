import { createContext, useState } from 'react';

import EventCompetitionGendersDwData from './EventCompetitionGendersDwData';

export const EventCompetitionGendersDwStateContext = createContext();

const EventCompetitionGendersDwContextProvider = ({ children }) => {
  const stateHook = useState(EventCompetitionGendersDwData.INITIAL_EVENTS_COMPETITION_GENDERS_DW_STATE);

  return (
    <EventCompetitionGendersDwStateContext.Provider value={stateHook}>
      {children}
    </EventCompetitionGendersDwStateContext.Provider>
  );
};

export default EventCompetitionGendersDwContextProvider;