import { Fragment } from 'react';

import UseTopTimesLscSelection from './UseTopTimesLscSelection';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const TopTimesLscSelection = () => {
  const {
    isLoading,
    LSC_TOP_TIMES_SEARCH_ROLE_GROUP_ID,
    TOP_TIMES_LSC_SEARCH_ROUTE,
    isReadyState
  } = UseTopTimesLscSelection();

  return (
    <Fragment>
      {LSC_TOP_TIMES_SEARCH_ROLE_GROUP_ID && TOP_TIMES_LSC_SEARCH_ROUTE && isReadyState === true &&
        <SelectOrgUnit
          pageTitle={'LSC Top Times Selection'}
          navLink={TOP_TIMES_LSC_SEARCH_ROUTE}
          roleGroupId={LSC_TOP_TIMES_SEARCH_ROLE_GROUP_ID} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default TopTimesLscSelection;