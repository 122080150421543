const useSplitsRow = (rowState, onRowChanged) => {
  const onTimeChanged = (value) => {
    onRowChanged({
      ...rowState,
      splitTime: value
    });
  };

  return {onTimeChanged};
};

export default useSplitsRow;
