import React, { Fragment } from 'react';

import useTimesDetails from './UseTimesDetails';

import TimeDeletionConfirmation from './TimeDeletionConfirmation';

import AddEditForm from '../components/AddEditForm';

const TimesEdit = () => {
  const {
    state,
    formState,
    errorState,
    timeState,
    eventState,
    onFormValueChanged,
    onValueTextPairChanged,
    onSubmit,
    resetForm,
    onDelete,
    onClosePopup,
    onShowPopup
  } = useTimesDetails(false);

  return (
    <Fragment>
      <AddEditForm
        state={state}
        formState={formState}
        errorState={errorState}
        timeState={timeState}
        eventState={eventState}
        onFormValueChanged={onFormValueChanged}
        onValueTextPairChanged={onValueTextPairChanged}
        onSubmit={onSubmit}
        resetForm={resetForm}
        onShowPopup={onShowPopup} />
      <TimeDeletionConfirmation
        state={state}
        formState={formState}
        onDelete={onDelete}
        onClosePopup={onClosePopup} />
    </Fragment>
  );
};

export default TimesEdit;