import React from 'react';

import useTimeById from './UseTimeById';
import Constants from '../../../../common/utils/Constants';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

const TimeById = () => {
  useTimeById();

  return (
    <PopUpModal
      widthPct={50}
      maxWidth={250}
      title={Constants.LOADING_MSG}
      displayPopUp={true} />
  );
};

export default TimeById;