import React from 'react';

import CheckIcon from '../../../../common/components/icons/CheckIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import ReportIcon from '../../../../common/components/icons/ReportIcon';
import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';

const getMessage = (isAnomaly, isDeleted) => {
  if (isAnomaly === true && isDeleted === false) {
    return 'Verify or Remove';
  } else if (isAnomaly === false && isDeleted === false) {
    return 'Verified';
  } else if (isDeleted === true) {
    return 'All Deleted';
  }
  return '';
};

const SmallTimesAnomalyGrid = ({ state, onVerify, onDeleteTime, onDisplayReport }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading123... </div>
            : <div className={global.SmallTableRowLabels}>No Results</div>
          }
        </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((anomaly, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{anomaly?.lastName}</span>&nbsp;
              {anomaly.isDeleted === false && anomaly.isAnomaly === true &&
                <button className={global.IconButtonMargin}
                  type="button"
                  onClick={() => onVerify(anomaly.swimTimeAnomalyId)}>
                  <CheckIcon toolTipText="Verify Time" />
                </button>
              }
              {anomaly.isDeleted === false && anomaly.isAnomaly === true &&
                <button className={global.IconButtonMargin}
                  type="button"
                  onClick={() => onDeleteTime(anomaly.swimTimeAnomalyId)}>
                  <DeleteIcon toolTipText="Delete all time usage" />
                </button>
              }
              <button className={global.IconButtonMargin}
                type="button"
                onClick={() => onDisplayReport(anomaly.swimTimeAnomalyId)}>
                <ReportIcon toolTipText="Display Time Anomaly Report" />
              </button>
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Last Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{anomaly.lastName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>First Name</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.firstName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.meetName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(anomaly.swimDate)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Event</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.eventCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Session</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.typeName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Time</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatTimeForDisplay(anomaly.swimTime)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swimmer Age</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.swimmerAge}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Z-Score</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.zscore}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Powerpoints</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{anomaly.powerpoints}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Anomaly?</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{getMessage(anomaly.isAnomaly, anomaly.isDeleted)}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Anomalies</div>
          </div>
        )
    }
  </div >
);

export default SmallTimesAnomalyGrid;