import React from 'react';

import OrgUnitContextProvider from '../common/state/orgUnit/OrgUnitContextProvider';

const AppStateContextProviders = ({ children }) => {
  return (
    <OrgUnitContextProvider>
      {children}
    </OrgUnitContextProvider>
  );
};
export default AppStateContextProviders;