import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseManageTimes';
import useTimeData from '../../../../common/state/time/UseTimeData';
import {
  formatMillisecondsToTime, formatTimeForDisplay, formatTimeForFilterObject, convertTimeToMilliseconds
} from '../../../../common/utils/TimesUtils';
import { isValidSwimTime } from '../../../../common/utils/validation';

const useTimeSplits = () => {
  const navigate = useNavigate();
  const { timeState, putTime } = useTimeData();
  const [splitsState, setSplitsState] = useState({ timeSplits: [] });
  const [errorState, setErrorState] = useState({ isError: false, errorMessage: '' })

  const createTimeObjForSubmit = () => {
    return {
      swimTimeId: timeState.objData.swimTimeId,
      personId: timeState.objData.personId,
      eventId: timeState.objData.eventId,
      meetId: timeState.objData.meetId,
      sessionId: timeState.objData.sessionId,
      heatNumber: timeState.objData.heatNumber,
      time: timeState.objData.time,
      swimDate: timeState.objData.swimDate,
      finishPosition: parseInt(timeState.objData.finishPosition),
      isLeadOffTime: timeState.objData.isLeadOffTime,
      seedTime: timeState.objData.seedTime,
      eventCompetitionGenderTypeId: timeState.objData.eventCompetitionGenderTypeId,
      teamName: timeState.objData.teamName,
      swimTimeRelay: timeState.objData.swimTimeRelay,
      swimTimeSplit: formatSplitsArrayForPut(splitsState.timeSplits),
      laneNumber: timeState.objData.laneNumber,
      reactionTime: timeState.objData.reactionTime
    };
  };

  const onSaveButtonClicked = (e) => {
    const { isInvalid, errorMessage } = areSplitsInvalid(splitsState.timeSplits);
    if (isInvalid) {
      setErrorState({
        ...errorState,
        isError: true,
        errorMessage: errorMessage
      });
    } else {
      setErrorState({
        ...errorState,
        isError: false,
        errorMessage: ''
      });

      const timeObj = createTimeObjForSubmit();
      putTime(timeObj.swimTimeId, timeObj);
    }
  }

  const onCancelButtonClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.DETAIL);
  }

  function getEmptySplitsArray(swimTimeId, distance) {
    //TODO: get course from timeState to determine number of splits for SCY etc.
    const numSplits = distance / 50;
    const distanceFormFactor = distance / numSplits;
    let splitsArray = [];

    if (numSplits > 1) {
      for (let i = 0; i < numSplits; i++) {
        splitsArray.push({
          swimTimeId: swimTimeId,
          splitNumber: i + 1,
          cumulativeDistance: distanceFormFactor * (i + 1),
          splitTime: '',
          calculatedSwimTime: ''
        });
      };
    }

    return splitsArray;
  }

  const onRowChanged = (value) => {
    let newArray = [...splitsState.timeSplits];

    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].splitNumber === value.splitNumber) {
        newArray[i] = { ...value };

        setSplitsState({
          ...splitsState,
          timeSplits: newArray
        });

        return;
      }
    }
  };

  const findDifferenceInTime = (splitTime, priorSplitTime) => {
    const formattedForDatabaseSplitTime = formatTimeForFilterObject(splitTime);
    const splitTimeInMilliseconds = convertTimeToMilliseconds(formattedForDatabaseSplitTime);
    const formattedForDatabasePriorSplitTime = formatTimeForFilterObject(priorSplitTime);
    const priorSplitTimeInMilliseconds = convertTimeToMilliseconds(formattedForDatabasePriorSplitTime);
    const timeDifference = splitTimeInMilliseconds - priorSplitTimeInMilliseconds;
    const formattedTimeDifference = formatMillisecondsToTime(timeDifference);
    return formattedTimeDifference;
  };

  const areSplitsInvalid = (splitsArray) => {
    let isInvalid = false;
    let errorMessage = '';
    const finalSplitTime = splitsArray.slice(-1);
    const swimTime = timeState.objData.time;
    if (formatTimeForFilterObject(finalSplitTime[0].splitTime) !== formatTimeForFilterObject(formatTimeForDisplay(swimTime))) {
      isInvalid = true;
      errorMessage = `The Final Split must equal the Swim Time (${formatTimeForDisplay(swimTime)})`;
      return { isInvalid, errorMessage }
    }

    for (var i = 0, len = splitsArray.length; i < len; i++) {
      let min;
      let sec;
      if (splitsArray[i].splitTime.split(":").length === 1) {
        min = undefined;
        sec = splitsArray[i].splitTime;
      } else {
        const [mm, ss] = splitsArray[i].splitTime.split(":");
        min = mm;
        sec = ss;
      }

      //no decimal
      if (sec.split(".").length === 1) {
        isInvalid = true;
        errorMessage = `Invalid Split ${splitsArray[i].splitNumber || ''} Time`;

        return { isInvalid, errorMessage }
      }

      if (!isValidSwimTime(formatTimeForFilterObject(splitsArray[i].splitTime))) {
        isInvalid = true;
        errorMessage = `Invalid Leg ${splitsArray[i].splitNumber || ''} Time`;

        return { isInvalid, errorMessage };
      }

      if (splitsArray[i + 1] &&
        convertTimeToMilliseconds(formatTimeForFilterObject(splitsArray[i].splitTime)) >
        convertTimeToMilliseconds(formatTimeForFilterObject(splitsArray[i + 1].splitTime))) {
        isInvalid = true;
        errorMessage = `Leg ${splitsArray[i].splitNumber || ''} Time cannot be slower than the Leg ${splitsArray[i + 1].splitNumber || ''} Time`;

        return { isInvalid, errorMessage };
      }

      const [s, ms] = sec.split(".");
      if (min === undefined) {
        if (isNaN(s) === true || isNaN(ms) === true || s.length !== 2 || ms.length !== 2) {
          isInvalid = true;
          errorMessage = `Invalid Split ${splitsArray[i].splitNumber || ''} Time`;

          return { isInvalid, errorMessage }
        }
      } else {
        if (
          isNaN(min) === true ||
          isNaN(s) === true ||
          isNaN(ms) === true ||
          (min.length !== 1 && ms.length !== 2) ||
          s.length !== 2 ||
          ms.length !== 2) {
          isInvalid = true;
          errorMessage = 'Invalid Split Time';

          return { isInvalid, errorMessage }
        }
      }
    }

    return { isInvalid, errorMessage };
  }

  const formatSplitsArrayForPut = (splitsArray) => {
    const splitsToPut = splitsArray.map((split) => {
      return {
        swimTimeSplitId: split.swimTimeSplitId,
        swimTimeId: split.swimTimeId,
        splitNumber: split.splitNumber,
        splitTime: formatTimeForFilterObject(split.splitTime)
      };
    });

    return splitsToPut;
  }

  const formatTimeSplits = (splitArray, totalDistance) => {
    const distanceFormFactor = totalDistance / splitArray.length;
    const formattedSplitsArray = splitArray.map((split, index) => {
      let calculatedSwimTime = '';
      if (index > 0) {
        calculatedSwimTime = findDifferenceInTime(formatTimeForDisplay(split.splitTime), formatTimeForDisplay(splitArray[index - 1].splitTime));
      } else {
        calculatedSwimTime = split.splitTime;
      }
      return {
        swimTimeSplitId: split.swimTimeSplitId,
        swimTimeId: split.swimTimeId,
        splitNumber: split.splitNumber,
        cumulativeDistance: distanceFormFactor * (index + 1),
        splitTime: formatTimeForDisplay(split.splitTime),
        calculatedSwimTime: formatTimeForDisplay(calculatedSwimTime)
      };
    });
    return formattedSplitsArray;
  };

  useEffect(() => {
    if (timeState.isObjLoaded && timeState.isSaving === false) {
      let splitsArray = [];
      if (timeState.objData.swimTimeSplit.length > 0) {
        let swimTimeSplitsArraySorted = JSON.parse(JSON.stringify(timeState.objData.swimTimeSplit));
        swimTimeSplitsArraySorted.sort(function (a, b) {
          return (a.splitNumber < b.splitNumber) ? -1 : (a.splitNumber > b.splitNumber) ? 1 : 0;
        });
        splitsArray = formatTimeSplits(swimTimeSplitsArraySorted, timeState.objData.event.distance);
      } else {
        if (splitsArray.length === 0) {
          splitsArray = getEmptySplitsArray(timeState.objData.swimTimeId, timeState.objData.event.distance);
        }
      }

      setSplitsState({
        ...splitsState,
        timeSplits: splitsArray
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState]);

  return {
    timeState,
    splitsState,
    errorState,
    onRowChanged,
    onSaveButtonClicked,
    onCancelButtonClicked
  };
};

export default useTimeSplits;
