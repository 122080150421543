import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import { navLinks } from '../UseManageTimes';

import useTimeData from '../../../../common/state/time/UseTimeData';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';
import { isValidSwimTime } from '../../../../common/utils/validation';

const useRelayLegs = () => {
  const navigate = useNavigate();
  const { timeState, putTime } = useTimeData();
  const [relayLegsState, setRelayLegsState] = useState({ relayLegs: [] });
  const [errorState, setErrorState] = useState({ isError: false, errorMessage: '' })

  const createTimeObjForSubmit = () => {
    return {
      swimTimeId: timeState.objData.swimTimeId,
      personId: timeState.objData.personId,
      eventId: timeState.objData.eventId,
      meetId: timeState.objData.meetId,
      sessionId: timeState.objData.sessionId,
      heatNumber: timeState.objData.heatNumber,
      time: timeState.objData.time,
      swimDate: timeState.objData.swimDate,
      finishPosition: parseInt(timeState.objData.finishPosition),
      isLeadOffTime: timeState.objData.isLeadOffTime,
      seedTime: timeState.objData.seedTime,
      eventCompetitionGenderTypeId: timeState.objData.eventCompetitionGenderTypeId,
      teamName: timeState.objData.teamName,
      swimTimeRelay: formatLegsArrayForPut(relayLegsState.relayLegs),
      swimTimeSplit: [],
      laneNumber: timeState.objData.laneNumber,
      reactionTime: timeState.objData.reactionTime
    };
  };


  function timeAsSeconds(legTime) {
    if (legTime.indexOf(":") === -1) {
      return Number.parseFloat(legTime);
    }

    const [min, sec] = legTime.split(":");
    const val = Number.parseInt(min) * 60 + Number.parseFloat(sec);

    return val;
  }

  const areLegTimesInvalid = (relayLegs) => {
    let isInvalid = false;
    let errorMessage = '';

    let sumOfRelayLegsInSeconds = 0;

    for (var i = 0, len = relayLegs.length; i < len; i++) {
      let min;
      let sec;
      if (relayLegs[i].legTime.split(":").length === 1) {
        min = undefined;
        sec = relayLegs[i].legTime;
      } else {
        const [mm, ss] = relayLegs[i].legTime.split(":");
        min = mm;
        sec = ss;
      }
 
      //no decimal
      if (sec.split(".").length === 1) {
        isInvalid = true;
        errorMessage = `Invalid Leg ${relayLegs[i].legNumber} Time`;


        return { isInvalid, errorMessage }
      }

      if (!isValidSwimTime(formatTimeForFilterObject(relayLegs[i].legTime))) {
        isInvalid = true;
        errorMessage = `Invalid Leg ${relayLegs[i].legNumber} Time`;

        return { isInvalid, errorMessage };
      }

      const [s, ms] = sec.split(".");
      if (min === undefined) {
        if (isNaN(s) === true || isNaN(ms) === true || s.length !== 2 || ms.length !== 2) {
          isInvalid = true;
          errorMessage = `Invalid Leg ${relayLegs[i].legNumber} Time`;

          return { isInvalid, errorMessage }
        }
      } else {
        if (
          isNaN(min) === true ||
          isNaN(s) === true ||
          isNaN(ms) === true ||
          (min.length !== 1 && ms.length !== 2) ||
          s.length !== 2 ||
          ms.length !== 2) {
          isInvalid = true;
          errorMessage = 'Invalid Leg Time';

          return { isInvalid, errorMessage }
        }
      }

      sumOfRelayLegsInSeconds += timeAsSeconds(relayLegs[i].legTime);
    }

    if (sumOfRelayLegsInSeconds !== timeAsSeconds(timeState.objData.time.substring(4, 11))) {
      isInvalid = true;
      errorMessage = `The sum of the relay leg times must equal the Swim Time (${formatTimeForDisplay(timeState.objData.time)})`;

      return { isInvalid, errorMessage };
    }

    return { isInvalid, errorMessage };
  }

  const onSaveButtonClicked = (e) => {
    const { isInvalid, errorMessage } = areLegTimesInvalid(relayLegsState.relayLegs);

    if (isInvalid) {
      setErrorState({
        ...errorState,
        isError: true,
        errorMessage: errorMessage
      });
    } else {
      setErrorState({
        ...errorState,
        isError: false,
        errorMessage: ''
      });

      const timeObj = createTimeObjForSubmit();
      console.log(timeObj);
      putTime(timeObj.swimTimeId, timeObj);
    }
  }

  const onCancelButtonClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(navLinks.DETAIL);
  }

  const onRowChanged = (value) => {
    let newArray = [...relayLegsState.relayLegs];

    for (let i = 0; i < newArray.length; i++) {
      if (newArray[i].legNumber === value.legNumber) {
        newArray[i] = { ...value };

        setRelayLegsState({
          ...relayLegsState,
          relayLegs: newArray
        });

        return;
      }
    }
  };

  const formatLegTimes = (swimTimeRelay, totalDistance) => {
    const formattedRelayLegTimes = swimTimeRelay.map((leg, index) => {
      return {
        swimTimeRelayId: leg.swimTimeRelayId,
        swimTimeId: leg.swimTimeId,
        personId: leg.personId,
        legEventId: leg.legEventId,
        legNumber: leg.legNumber,
        competitor: `${leg.person.firstName} ${leg.person.lastName}`,
        eventDescription: leg.legEvent.eventCode,
        legTime: formatTimeForDisplay(leg.legTime),
        reactionTime: leg.reactionTime
      };
    });
    return formattedRelayLegTimes;
  };

  const formatLegsArrayForPut = (legsArray) => {
    const legsToPut = legsArray.map((leg) => {
      return {
        swimTimeRelayId: leg.swimTimeRelayId,
        swimTimeId: leg.swimTimeId,
        personId: leg.personId,
        legEventId: leg.legEventId,
        legNumber: leg.legNumber,
        legTime: formatTimeForFilterObject(leg.legTime),
        reactionTime: leg.reactionTime
      };
    });

    return legsToPut;
  }

  function getEmptyRelayLegsArray(swimTimeId, distance) {
    //TODO: get course from timeState to determine number of splits for SCY etc.
    const numLegs = 4;
    let legsArray = [];

    for (let i = 0; i < numLegs; i++) {
      legsArray.push({
        swimTimeId: swimTimeId,
        legNumber: i + 1,
        competitor: '',
        eventDescription: '',
        legTime: ''
      });
    };

    return legsArray;
  }

  useEffect(() => {
    if (timeState.isObjLoaded) {
      let legsArray = [];
      if (timeState.objData.swimTimeRelay.length > 0) {
        legsArray = formatLegTimes(timeState.objData.swimTimeRelay, timeState.objData.event.distance);
      } else {
        if (legsArray.length === 0) {
          legsArray = getEmptyRelayLegsArray(timeState.objData.swimTimeId, timeState.objData.event.distance);
        }
      }

      setRelayLegsState({
        ...relayLegsState,
        relayLegs: legsArray
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState.isObjLoaded]);

  return {
    timeState,
    relayLegsState,
    errorState,
    onRowChanged,
    onSaveButtonClicked,
    onCancelButtonClicked
  };
}

export default useRelayLegs;