import React, { Fragment } from 'react';

import useRecordsAdminEvents from './UseRecordsAdminEvents';

import LargeRecordsAdminEventsGrid from './LargeRecordsAdminEventsGrid';
import SmallRecordsAdminEventsGrid from './SmallRecordsAdminEventsGrid';

import global from '../../../../common/components/GlobalStyle.module.css'

const RecordsAdminEventsGrid = ({ data, onCleanupRecords, getGenderDescription, onDeleteRecordListInstance }) => {
  const { recordsState } = useRecordsAdminEvents();

  return (
    <Fragment>
      {recordsState.isArrayLoading === false
      ? <div className="row">
        <div className="col-xs-12 col-sm-10">
          {data.length > 499
            ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
            : <p>{recordsState.arrayData.length === 1 ? `${recordsState.arrayData.length} Search Result` : `${recordsState.arrayData.length} Search Results`}</p>}
        </div>
      </div>
      : <Fragment>
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        </div>
      </Fragment >}
      <LargeRecordsAdminEventsGrid 
        data={data}
        isLoading={recordsState.isArrayLoading}
        onCleanupRecords={onCleanupRecords}
        getGenderDescription={getGenderDescription}
        onDeleteRecordListInstance={onDeleteRecordListInstance}
      />
      <SmallRecordsAdminEventsGrid 
        data={data}
        isLoading={recordsState.isArrayLoading}
        onCleanupRecords={onCleanupRecords}
        getGenderDescription={getGenderDescription}
        onDeleteRecordListInstance={onDeleteRecordListInstance}
      />
    </Fragment>
  )
};

export default RecordsAdminEventsGrid;