import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import Constants from '../../../../common/utils/Constants';

import NavLinks from '../NavLinks';

const ERROR = 'At least one age group is required';

const useMyTimeStandardAgeGroup = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ tryRedirect: false, error: '' });
  const { getContextSecurity } = useSecurityData();
  const { timeStandardState, clearObjData, putTimeStandard } = useTimeStandardData();

  const onAddAgeGroup = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MY_TIME_STANDARDS_AGE_GROUP_ADD);
  }

  const editTimeStandardObj = (timeStandardAgeGroupId) => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    const index = timeStandardCopy.timeStandardAgeGroup.findIndex(e => e.timeStandardAgeGroupId === timeStandardAgeGroupId);

    timeStandardCopy.timeStandardAgeGroup.splice(index, 1);

    return timeStandardCopy;
  }

  const onDeleteAgeGroup = (e, timeStandardAgeGroupId) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (timeStandardState.objData?.timeStandardAgeGroup?.length === 1) {
      setState({ ...state, error: ERROR });
    }
    putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj(timeStandardAgeGroupId));
  }

  const onCreateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (timeStandardState.objData?.timeStandardAgeGroup?.length === 0) {
      setState({ ...state, error: ERROR });
    }
    else {
      setState({ ...state, tryRedirect: true });
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    if (timeStandardState.objData?.timeStandardAgeGroup?.length === 0) {
      setState({ ...state, error: ERROR });
    }
    else {
      setState({ ...state, tryRedirect: true });
    }
  }

  useEffect(() => {
    getContextSecurity(timeStandardState.objData.timeStandardId, 'Times');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (Object.keys(timeStandardState.objData).length > 0 && timeStandardState.isObjLoaded === true
      && state.tryRedirect === true && state.error === '') {
      clearObjData();
      navigate(NavLinks.MY_TIME_STANDARDS_SEARCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeStandardState.objData, timeStandardState.isObjLoaded, state])

  return {
    timeStandardState,
    state,
    onAddAgeGroup,
    onDeleteAgeGroup,
    onCreateClicked,
    onCancelClicked,
    Constants
  }
};

export default useMyTimeStandardAgeGroup;
