import getSwimTimeRecognition from './GetSwimTimeRecognitionData';
import putSwimTimeRecognition from './PutSwimTimeRecognitionData';

import Constants from '../../../utils/Constants';

const INITIAL_STATE = {
  meetId: Constants.DEFAULT_ID,
  isArrayLoaded: false,
  arrayData: [],
  message: ''
};

const SwimTimeRecognitionData = {
  INITIAL_STATE,
  getSwimTimeRecognition,
  putSwimTimeRecognition
};

export default SwimTimeRecognitionData;