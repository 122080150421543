import React from 'react';

import RightArrowIcon from '../../../../common/components/icons/RightArrowIcon';
import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';

import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ record, onView }) => (
  <tr>
    <td>{record.listName}</td>
    <td>{record.orgUnitCode}</td>
    <td>{record.groupName}</td>
    <td style={{ float: 'right' }}>
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onView(record.recordListId)}>
        <RightArrowIcon />
      </button>
    </td>
  </tr>
);

const LargeRecordsAdminGrid = ({ data, isLoading, onView }) => {
  const {
        sortableGridState,
        onColumnClicked} = useSortableGrid(data);

  return (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Record List Name'} columnField={'listName'}
          sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Organization'} columnField={'orgUnitCode'}
          sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Record Group Name'} columnField={'groupName'}
          sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
          ? sortableGridState.sortedGridData.map((record, i) => (
            <GridRow
              key={i}
              record={record}
              onView={onView} />
          )) : (
            <tr>
              <td colSpan="2">No Records</td>
            </tr>
          )
      }
    </tbody>
  </table>
)
};

export default LargeRecordsAdminGrid;