import React, { Fragment } from 'react';

import useTimesAnomalySearch from './UseTimesAnomalySearch';
import LargeTimesAnomalyGrid from './LargeTimesAnomalyGrid';
import SmallTimesAnomalyGrid from './SmallTimesAnomalyGrid';

import global from '../../../../common/components/GlobalStyle.module.css'

const TimesAnomalyGrid = ({ onVerify, onDeleteTime, onDisplayReport }) => {
  const { timesAnomalyState } = useTimesAnomalySearch();

  return (
    <Fragment>
      {timesAnomalyState.isArrayLoading === false
        ? <div className="row">
          <div className="col-xs-12 col-sm-10">
            {timesAnomalyState.arrayData.length > 499
              ? <p className={global.ErrorMessage}>More than 500 Search Results returned. Please add additional filters to narrow your search results.</p>
              : <p>{timesAnomalyState.arrayData.length === 1 ? `${timesAnomalyState.arrayData.length} Search Result` : `${timesAnomalyState.arrayData.length} Search Results`}</p>}
          </div>
        </div>
        : <Fragment>
          <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          </div>
        </Fragment >}
      <LargeTimesAnomalyGrid
        data={timesAnomalyState.arrayData}
        isLoading={timesAnomalyState.isArrayLoading}
        onVerify={onVerify}
        onDeleteTime={onDeleteTime}
        onDisplayReport={onDisplayReport}
      />
      {timesAnomalyState.isArrayLoading === false && timesAnomalyState.isArrayLoaded === true &&
      <SmallTimesAnomalyGrid
        state={timesAnomalyState}
        onVerify={onVerify}
        onDeleteTime={onDeleteTime}
        onDisplayReport={onDisplayReport}
      />
      }
    </Fragment>
  )
};

export default TimesAnomalyGrid;