import React, { Fragment } from 'react';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';

import useOrgUnitSelection from './UseOrgUnitSelection';

const OrgUnitSelection = () => {
  const { 
    NavLinks, 
    MY_TIME_STANDARDS_ROLE_GROUP_ID } = useOrgUnitSelection();

  return (
    <Fragment>
      <SelectOrgUnit
        pageTitle={'My Time Standards'}
        navLink={NavLinks.MY_TIME_STANDARDS_SEARCH}
        roleGroupId={MY_TIME_STANDARDS_ROLE_GROUP_ID} />
    </Fragment>
  );
};

export default OrgUnitSelection;