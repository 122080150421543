import { useContext } from 'react';

import RecordTimesData from './RecordTimesData';
import { RecordTimesStateContext } from './RecordTimesContextProvider';

const useRecordTimesData = () => {
  const [recordTimesState, setRecordTimesState] = useContext(RecordTimesStateContext);

  const getRecordTimes = (recordListInstanceId) => {
    RecordTimesData.getRecordTimes(recordListInstanceId, recordTimesState, setRecordTimesState);
  };

  const deleteRecordTimeForAllTables = (swimTimeRecordId) => {
    RecordTimesData.deleteRecordTimeForAllTables(swimTimeRecordId, recordTimesState, setRecordTimesState);
  }

  const deleteRecordTime = (swimTimeRecordId) => {
    RecordTimesData.deleteRecordTime(swimTimeRecordId, recordTimesState, setRecordTimesState);
  }

  const postRecordTimes = (recordTimesObj) => {
    RecordTimesData.postRecordTimes(recordTimesObj, recordTimesState, setRecordTimesState);
  }

  return {
    recordTimesState,
    setRecordTimesState,
    getRecordTimes,
    deleteRecordTimeForAllTables,
    deleteRecordTime,
    postRecordTimes
  };
};

export default useRecordTimesData;