import React, { Fragment } from 'react';

import DeleteIcon from '../../../common/components/icons/DeleteIcon';
import EditIcon from '../../../common/components/icons/EditIcon';
import HideIcon from '../../../common/components/icons/HideIcon';
import ShowIcon from '../../../common/components/icons/ShowIcon';

import { formatDate } from '../../../common/utils/DateFunctions';

import global from '../../../common/components/GlobalStyle.module.css';

const DetailTableRow = ({ timeStandard, ageGroup, onEditAgeGroup, onDeleteAgeGroup }) => (
  <div className={global.SmallTableRow}>
    <div className={global.SmallTableDetailRowHead}>
      <span className='hidden-xs'>{ageGroup.ageGroupName}</span>&nbsp;
      {timeStandard.ageGroups.length > 1 ?
        <button className={global.IconButton} type="button" onClick={(e) =>
          onDeleteAgeGroup(e, timeStandard.timeStandardId, ageGroup.timeStandardAgeGroupId)}>
          <DeleteIcon />
        </button>
        : <Fragment />}
      {onEditAgeGroup &&
        <button className={timeStandard.ageGroups.length > 1 ? global.IconButtonMobileMargin : global.IconButton} type="button"
          onClick={(e) => onEditAgeGroup(e, timeStandard.timeStandardId, ageGroup.timeStandardAgeGroupId)}>
          <EditIcon />
        </button>}
    </div>
    <div className={global.SmallTableDetailRowInfo}>
      <div className='row'>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
          Age Group Name
        </div>
        <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
          {ageGroup.ageGroupName}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Min Age
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {ageGroup.minAge ? ageGroup.minAge : ' '}
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
          Max Age
        </div>
        <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
          {ageGroup.maxAge ? ageGroup.maxAge : ' '}
        </div>
      </div>
    </div>
  </div >
);

const DetailTable = ({ timeStandard, onEditAgeGroup, onDeleteAgeGroup }) => (
  <div className={global.SmallTableDetailTable}>
    {timeStandard.ageGroups.map((ageGroup, i) =>
      <DetailTableRow key={i} timeStandard={timeStandard} ageGroup={ageGroup}
        onEditAgeGroup={onEditAgeGroup} onDeleteAgeGroup={onDeleteAgeGroup} />)}
  </div>
);

const GridRowContent = ({ timeStandard }) => (
  <div className={global.SmallTableRowInfo}>
    <div className='row'>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>
        Standard Name
      </div>
      <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>
        {timeStandard?.standardName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Organization
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {timeStandard?.orgUnitName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Type
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {timeStandard?.timeStandardTypeName}
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>
        Dates
      </div>
      <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
        {formatDate(timeStandard.effectiveDate)} - {formatDate(timeStandard.expirationDate)}
      </div>
    </div>
  </div>
);

const GridRowIcons = ({ id, expandedId, onExpandCollapseClicked, onDeleteTimeStandard, onEditTimeStandard, timeStandard }) => (
  <Fragment>
    <button className={global.IconButton}
      type="button"
      onClick={onExpandCollapseClicked}>
      {expandedId === id ? <HideIcon /> : <ShowIcon />}
    </button>
    <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onDeleteTimeStandard(e, timeStandard.timeStandardId)}>
      <DeleteIcon />
    </button>
    {onEditTimeStandard &&
      <button className={global.IconButtonMobileMargin} type="button" onClick={(e) => onEditTimeStandard(e, timeStandard.timeStandardId)}>
        <EditIcon />
      </button>}
  </Fragment>
);

const GridRow = ({ expandedId, onClick, timeStandard, onEditTimeStandard, onDeleteTimeStandard, onEditAgeGroup, onDeleteAgeGroup }) => {
  return (
    <Fragment>
      <div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>{timeStandard?.standardName}</span>&nbsp;
          <GridRowIcons
            id={timeStandard.timeStandardId}
            expandedId={expandedId}
            onExpandCollapseClicked={(e) => onClick(e, timeStandard.timeStandardId)}
            onEditTimeStandard={onEditTimeStandard}
            onDeleteTimeStandard={onDeleteTimeStandard}
            timeStandard={timeStandard} />
        </div>
        <GridRowContent timeStandard={timeStandard} />
      </div>
      {Array.isArray(timeStandard.ageGroups) && timeStandard.ageGroups.length > 0 && expandedId === timeStandard.timeStandardId
        && <DetailTable timeStandard={timeStandard} onEditAgeGroup={onEditAgeGroup} onDeleteAgeGroup={onDeleteAgeGroup} />}
    </Fragment>
  );
}

const SmallMyTimeStandardsGrid = ({ expandedId, onClick, state, onEditTimeStandard, onDeleteTimeStandard, onEditAgeGroup, onDeleteAgeGroup }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoaded !== true
      ? (<div className={global.SmallTableRow}>
        <div className={global.SmallTableRowHead}>
          &nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading... </div> :
            <div className={global.SmallTableRowLabels}>No Results</div>}
        </div>
      </div>
      ) : Array.isArray(state.arrayData) && state.arrayData.length > 0
        ? state.arrayData.map((timeStandard, i) =>
          <GridRow
            key={i}
            timeStandard={timeStandard}
            onEditTimeStandard={onEditTimeStandard}
            onDeleteTimeStandard={onDeleteTimeStandard}
            onEditAgeGroup={onEditAgeGroup}
            onDeleteAgeGroup={onDeleteAgeGroup}
            expandedId={expandedId}
            onClick={onClick} />)
        : (<div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>
            &nbsp;
          </div>
          <div className={global.SmallTableRowInfo}>
            <div className={global.SmallTableRowLabels}>No Results</div>
          </div>
        </div>)
    }
  </div>
);

export default SmallMyTimeStandardsGrid;