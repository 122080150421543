import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import style from '../../TimeStandards.module.css';

const MedleyRelayEventRowsLarge = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <tr key={"200MED-R"}>
      <td className={style.EventColumn}>200 MED-R</td>
      <td><TimeInput
        label=""
        name="eventId26FSwimTime"
        error={errorState.eventId26FSwimTime}
        message={errorState.eventId26FSwimTime}
        value={formState.eventId26FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId26FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId53FSwimTime"
        error={errorState.eventId53FSwimTime}
        message={errorState.eventId53FSwimTime}
        value={formState.eventId53FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId53FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId79FSwimTime"
        error={errorState.eventId79FSwimTime}
        message={errorState.eventId79FSwimTime}
        value={formState.eventId79FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId79FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId26XSwimTime"
        error={errorState.eventId26XSwimTime}
        message={errorState.eventId26XSwimTime}
        value={formState.eventId26XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId26XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId53XSwimTime"
        error={errorState.eventId53XSwimTime}
        message={errorState.eventId53XSwimTime}
        value={formState.eventId53XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId53XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId79XSwimTime"
        error={errorState.eventId79XSwimTime}
        message={errorState.eventId79XSwimTime}
        value={formState.eventId79XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId79XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId26MSwimTime"
        error={errorState.eventId26MSwimTime}
        message={errorState.eventId26MSwimTime}
        value={formState.eventId26MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId26MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId53MSwimTime"
        error={errorState.eventId53MSwimTime}
        message={errorState.eventId53MSwimTime}
        value={formState.eventId53MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId53MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId79MSwimTime"
        error={errorState.eventId79MSwimTime}
        message={errorState.eventId79MSwimTime}
        value={formState.eventId79MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId79MSwimTime', value) }} /></td>
    </tr>
    <tr key={"400MED-R"}>
      <td className={style.EventColumn}>400 MED-R</td>
      <td><TimeInput
        label=""
        name="eventId27FSwimTime"
        error={errorState.eventId27FSwimTime}
        message={errorState.eventId27FSwimTime}
        value={formState.eventId27FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId27FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId54FSwimTime"
        error={errorState.eventId54FSwimTime}
        message={errorState.eventId54FSwimTime}
        value={formState.eventId54FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId54FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId80FSwimTime"
        error={errorState.eventId80FSwimTime}
        message={errorState.eventId80FSwimTime}
        value={formState.eventId80FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId80FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId27XSwimTime"
        error={errorState.eventId27XSwimTime}
        message={errorState.eventId27XSwimTime}
        value={formState.eventId27XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId27XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId54XSwimTime"
        error={errorState.eventId54XSwimTime}
        message={errorState.eventId54XSwimTime}
        value={formState.eventId54XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId54XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId80XSwimTime"
        error={errorState.eventId80XSwimTime}
        message={errorState.eventId80XSwimTime}
        value={formState.eventId80XSwimTime}
        onChange={(value) => { onFormValueChanged('eventId80XSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId27MSwimTime"
        error={errorState.eventId27MSwimTime}
        message={errorState.eventId27MSwimTime}
        value={formState.eventId27MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId27MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId54MSwimTime"
        error={errorState.eventId54MSwimTime}
        message={errorState.eventId54MSwimTime}
        value={formState.eventId54MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId54MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId80MSwimTime"
        error={errorState.eventId80MSwimTime}
        message={errorState.eventId80MSwimTime}
        value={formState.eventId80MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId80MSwimTime', value) }} /></td>
    </tr>
  </Fragment>
);

export default MedleyRelayEventRowsLarge;