import { Fragment } from "react";

import useMyTimeStandardsSearch from "./UseMyTimeStandardsSearch";

import SearchMyTimeStandards from "../../../components/searchMyTimeStandards/SearchMyTimeStandards";

const MyTimeStandardsSearch = () => {
  const { searchTimeStandardState, onAddTimeStandard, onEditTimeStandard, onEditAgeGroup } = useMyTimeStandardsSearch();

  return (
    <Fragment>
      <SearchMyTimeStandards
        onAddTimeStandard={onAddTimeStandard}
        onEditTimeStandard={onEditTimeStandard}
        onEditAgeGroup={onEditAgeGroup}
        searchTimeStandardState={searchTimeStandardState}
      />
    </Fragment>);

}
export default MyTimeStandardsSearch;