import { useContext } from "react";

import ReportPeriodSeasonsDwData from "./ReportPeriodSeasonsDwData";
import { ReportPeriodSeasonsDwStateContext } from "./ReportPeriodSeasonsDwContextProvider";

const useReportPeriodSeasonsDwData = () => {
  const [reportPeriodSeasonsDwState, setReportPeriodSeasonsDwState] = useContext(ReportPeriodSeasonsDwStateContext);

  const getReportPeriodSeasonsDw = (organizationCode) => {
    return ReportPeriodSeasonsDwData.getReportPeriodSeasonsDwData(organizationCode, reportPeriodSeasonsDwState, setReportPeriodSeasonsDwState);
  };

  return {
    reportPeriodSeasonsDwState,
    getReportPeriodSeasonsDw,
    setReportPeriodSeasonsDwState
  };
};

export default useReportPeriodSeasonsDwData;