import { TimesHttpHelper, NO_DATA_MESSAGE } from '../../../utils/HttpHelper';

const PutSwimTimeRecognitionData = (swimTimeId, swimTimeRecognitionArray, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const swimTimeIdForUrl = swimTimeId ? encodeURIComponent(swimTimeId) : 'NaN';
    const url = `/Time/Recognition?swimTimeId=${swimTimeIdForUrl}`;
    TimesHttpHelper(url, 'PUT', swimTimeRecognitionArray)
      .then((arrayData) => {
        if (!arrayData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          setState({
            ...newState,
            isArrayLoaded: true,
            isSaving: false,
            isSaved: true,
            arrayData: arrayData,
            message: ''
          });
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isArrayLoaded: false,
          isSaving: false,
          isSaved: false,
          message: message
        });
      });
  }
};

export default PutSwimTimeRecognitionData;


