import { Fragment } from 'react';

import useRelayLegs from './UseRelayLegs';

import RelayLegsGrid from './RelayLegsGrid';

import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Headings from '../../../../common/components/headings/Headings';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const RelayLegs = () => {
  const { timeState, relayLegsState, errorState, onRowChanged, onSaveButtonClicked, onCancelButtonClicked } = useRelayLegs();

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Relay Legs</Headings.H3>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12">
          {timeState.objData.personId !== null ?
            <p className={global.HeaderText}>See 'Splits' for Individual Time split information</p> :
            <RelayLegsGrid timeState={timeState} relayLegsState={relayLegsState} onRowChanged={onRowChanged} />
          }
        </div>
      </div>
      {errorState.isError === true &&
        <div className="row">
          <div className="col-xs-12">
            <p className={global.ErrorMessage}>{errorState.errorMessage}</p>
          </div>
        </div>}
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          {timeState.objData.personId === null ?
            <Fragment><PrimaryButton type="button" onClick={onSaveButtonClicked} >Save</PrimaryButton>&nbsp;</Fragment>
            : <Fragment />
          }
          <SecondaryButton type="button" onClick={onCancelButtonClicked}>Back</SecondaryButton>
        </div>
      </div>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeState.isSaving} />
    </Fragment>);
};

export default RelayLegs;