import { API_NAMES, initApi } from "../../../../common/utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from "../../../../common/utils/HttpHelper";

const getReportPeriodSeasonsDwData = (organizationCode, state, setState) => {
  if (organizationCode) {
    const newState = {
      ...state,
      organizationCode
    };

    const api = initApi(API_NAMES.COMMON, newState, setState);
    const organizationCodeForUrl = encodeURIComponent(organizationCode);
    const url = `/ReportPeriod/Organization/${organizationCodeForUrl}/Season/LowestChildren/DW`;

    return api?.executeArray ? api.executeArray(url, 'GET') : null;
  }
};

const INITIAL_REPORT_PERIOD_SEASONS_DW_STATE = {
  ...BASIC_INITIAL_STATE,
  organizationCode: '',
  areOptionsLoaded: false,
  options: []
};

const ReportPeriodSeasonsDwData = {
  INITIAL_REPORT_PERIOD_SEASONS_DW_STATE,
  getReportPeriodSeasonsDwData
};

export default ReportPeriodSeasonsDwData;