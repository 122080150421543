import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import searchTimesAnomaly from "./SearchTimesAnomalyData";
import putTimesAnomalyStatusUpdate from "./PutTimesAnomalyStatusUpdateData";
import deleteTimesAnomaly from './DeleteTimesAnomalyData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const TimeAnomalyData = {
  INITIAL_STATE,
  searchTimesAnomaly,
  putTimesAnomalyStatusUpdate,
  deleteTimesAnomaly
};

export default TimeAnomalyData;