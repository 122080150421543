import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';
import getRecordAgeGroups from './GetRecordAgeGroupData';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RecordAgeGroupData = {
  INITIAL_STATE,
  getRecordAgeGroups,
};

export default RecordAgeGroupData;