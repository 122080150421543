import Constants from "../../../../common/utils/Constants";

export const localValidate = (formState) => {
  let errors = {};

  if (formState.eventId === Constants.DEFAULT_ID) {
    errors.eventId = "Event is required";
  }

  if (formState.eventCompetitionGenderTypeId === Constants.DEFAULT_ID) {
    errors.eventCompetitionGenderTypeId = "Event Competition Gender is required"
  }

  if (formState.recordAgeGroupId === Constants.DEFAULT_ID) {
    errors.recordAgeGroupId = "Age Group is required"
  }

  return errors;
}

const RecordsAdminListInstanceDetailValidation = async (formState) => {
  let errors = localValidate(formState);


  return errors ? errors : {};
};

export default RecordsAdminListInstanceDetailValidation;