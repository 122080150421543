import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import global from '../../../../../common/components/GlobalStyle.module.css';

const IndividualMedleyEventRowsSmall = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <div className={global.TableRow} key={"100IM"}>
      <div className={global.TableRowHead}>100 IM
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId20FSwimTime"
          error={errorState.eventId20FSwimTime}
          message={errorState.eventId20FSwimTime}
          value={formState.eventId20FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId20FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId47FSwimTime"
          error={errorState.eventId47FSwimTime}
          message={errorState.eventId47FSwimTime}
          value={formState.eventId47FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId47FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId20MSwimTime"
          error={errorState.eventId20MSwimTime}
          message={errorState.eventId20MSwimTime}
          value={formState.eventId20MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId20MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId47MSwimTime"
          error={errorState.eventId47MSwimTime}
          message={errorState.eventId47MSwimTime}
          value={formState.eventId47MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId47MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"200IM"}>
      <div className={global.TableRowHead}>200 IM
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId21FSwimTime"
          error={errorState.eventId21FSwimTime}
          message={errorState.eventId21FSwimTime}
          value={formState.eventId21FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId21FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId48FSwimTime"
          error={errorState.eventId48FSwimTime}
          message={errorState.eventId48FSwimTime}
          value={formState.eventId48FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId48FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId74FSwimTime"
          error={errorState.eventId74FSwimTime}
          message={errorState.eventId74FSwimTime}
          value={formState.eventId74FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId74FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId21MSwimTime"
          error={errorState.eventId21MSwimTime}
          message={errorState.eventId21MSwimTime}
          value={formState.eventId21MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId21MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId48MSwimTime"
          error={errorState.eventId48MSwimTime}
          message={errorState.eventId48MSwimTime}
          value={formState.eventId48MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId48MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId74MSwimTime"
          error={errorState.eventId74MSwimTime}
          message={errorState.eventId74MSwimTime}
          value={formState.eventId74MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId74MSwimTime', value) }} /></div>
      </div>
    </div>
    <div className={global.TableRow} key={"400IM"}>
      <div className={global.TableRowHead}>400 IM
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId22FSwimTime"
          error={errorState.eventId22FSwimTime}
          message={errorState.eventId22FSwimTime}
          value={formState.eventId22FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId22FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId49FSwimTime"
          error={errorState.eventId49FSwimTime}
          message={errorState.eventId49FSwimTime}
          value={formState.eventId49FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId49FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Female LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId75FSwimTime"
          error={errorState.eventId75FSwimTime}
          message={errorState.eventId75FSwimTime}
          value={formState.eventId75FSwimTime}
          onChange={(value) => { onFormValueChanged('eventId75FSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCY</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId22MSwimTime"
          error={errorState.eventId22MSwimTime}
          message={errorState.eventId22MSwimTime}
          value={formState.eventId22MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId22MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male SCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId49MSwimTime"
          error={errorState.eventId49MSwimTime}
          message={errorState.eventId49MSwimTime}
          value={formState.eventId49MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId49MSwimTime', value) }} /></div>
      </div>
      <div className={global.TableRowInfo}>
        <div className={global.TableRowLabels}>Male LCM</div>
        <div className={global.TableRowData}><TimeInput
          label=""
          name="eventId75MSwimTime"
          error={errorState.eventId75MSwimTime}
          message={errorState.eventId75MSwimTime}
          value={formState.eventId75MSwimTime}
          onChange={(value) => { onFormValueChanged('eventId75MSwimTime', value) }} /></div>
      </div>
    </div>
  </Fragment>
);

export default IndividualMedleyEventRowsSmall;