import React, { Fragment } from 'react';

import useTimesAnomalyContextView from './UseTimesAnomalyContextView';

import LeftArrowIcon from '../../../common/components/icons/LeftArrowIcon';
import PageNotFound from '../../../common/components/PageNotFound';
import global from '../../../common/components/GlobalStyle.module.css';

const TimesAnomalyContextView = ({ children }) => {
  const {
    selectOrgUnitState,
    backToLabel,
    onClicked
  } = useTimesAnomalyContextView();

  if (!selectOrgUnitState.orgUnitId) {
    return (
      <Fragment>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <button className={global.BackToSearchButton}
              type="button"
              onClick={onClicked}>
              <LeftArrowIcon />  {backToLabel}
            </button>
          </div>
        </div>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12">
            <PageNotFound />
          </div>
        </div>
      </Fragment>
    )
  }

  return (
    <Fragment>
      {children}
    </Fragment >
  );
};

export default TimesAnomalyContextView;