import { useEffect } from 'react';

import useEventsDwData from '../../../state/topTimes/eventsDw/UseEventsDwData';

const useEventDwMultiSelect = (organizationCode) => {
  const { eventsDwState, getEventsDw, setEventsDwState } = useEventsDwData();

  useEffect(() => {
    if (organizationCode) {
      if (eventsDwState.isArrayLoaded !== true) {
        getEventsDw(organizationCode);
      } else if (eventsDwState.isArrayLoaded === true) {
        const options = eventsDwState.arrayData.map(x => { return { id: x.swimEventKey, name: x.eventCode } });

        setEventsDwState({
          ...eventsDwState,
          areOptionsLoaded: true,
          options
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventsDwState.isArrayLoaded, organizationCode]);

  return {
    eventsDwState
  };
};

export default useEventDwMultiSelect;