import React, { createContext, useState } from 'react';

import RecordTimesData from './RecordTimesData';

export const RecordTimesStateContext = createContext();

const RecordTimesContextProvider = ({ children }) => {
  const stateHook = useState(RecordTimesData.INITIAL_STATE);

  return (
    <RecordTimesStateContext.Provider value={stateHook}>
      {children}
    </RecordTimesStateContext.Provider>
  );
};

export default RecordTimesContextProvider;