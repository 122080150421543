import { useEffect } from 'react';

import useRecordListData from '../../../state/recordList/UseRecordListData';

const useRecordListCombobox = (recordGroupId) => {
  const { recordListState, getRecordList } = useRecordListData();
  
  useEffect(() => {
    if (recordGroupId > 0) {
      getRecordList(recordGroupId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordGroupId])

  return { recordListState };
};

export default useRecordListCombobox;