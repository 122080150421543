import { isValidNumber } from '../../../../common/utils/validation';

export const localValidate = (formState) => {
  const minAge = parseInt(formState.minAge);
  const maxAge = parseInt(formState.maxAge);
  let errors = {};

  if (formState.ageGroupName.trim() === '') {
    errors.ageGroupName = 'Age Group Name is required';
  } else if (formState.ageGroupName.trim().length > 50) {
    errors.ageGroupName = 'Age Group Name cannot exceed 50 characters';
  }

  if (formState.minAge === '') {
    errors.minAge = 'Min Age is required';
  } else if (!isValidNumber(formState.minAge) || formState.minAge === '0') {
    errors.minAge = 'Min Age must be greater than 0';
  } else if (formState.minAge > 99) {
    errors.minAge = 'Min Age cannot be greater than 99';
  } else if (minAge > maxAge) {
    errors.minAge = 'Min Age cannot be greater than Max Age';
  }

  if (formState.maxAge === '') {
    errors.maxAge = 'Max Age is required';
  } else if (!isValidNumber(formState.maxAge) || formState.maxAge === '0') {
    errors.maxAge = 'Max Age must be greater than 0';
  } else if (formState.maxAge > 99) {
    errors.maxAge = 'Max Age cannot be greater than 99';
  } else if (maxAge < minAge) {
    errors.maxAge = 'Max Age cannot be less than Min Age';
  }

  return errors;
};

const TimeStandardAgeGroupAddValidation = async (formState) => {
  let errors = localValidate(formState);

  return errors ? errors : {};
};

export default TimeStandardAgeGroupAddValidation;