import { useState } from "react";

import TopTimesData from "./TopTimesData";

const useTopTimesData = () => {
  const [topTimesState, setTopTimesState] = useState(TopTimesData.INITIAL_TOP_TIMES_STATE);

  const searchTopTimes = (filterObject) => {
    return TopTimesData.searchTopTimesData(JSON.stringify(filterObject), topTimesState, setTopTimesState);
  };

  const resetTopTimesState = () => {
    setTopTimesState(TopTimesData.INITIAL_TOP_TIMES_STATE);
  };

  return {
    topTimesState,
    searchTopTimes,
    resetTopTimesState
  };
};

export default useTopTimesData;