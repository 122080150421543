
import { useEffect, useState } from 'react';

import useNavRoutes from '../../../../common/state/security/UseNavRoutes';
import usePersonOrgRoleData from '../../../../common/state/personOrgRole/UsePersonOrgRoleData';
import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';

const useTopTimesLscSelection = () => {
  const { navRoutes } = useNavRoutes();
  const { environmentVariableState, LSC_TOP_TIMES_SEARCH_ROLE_GROUP_ID } = useEnvironmentVariableData();
  const { clearArrayData } = usePersonOrgRoleData();
  const [isReadyState, setIsReadyState] = useState(false);

  useEffect(() => {
    clearArrayData();
    setIsReadyState(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: environmentVariableState.isLoading,
    LSC_TOP_TIMES_SEARCH_ROLE_GROUP_ID,
    TOP_TIMES_LSC_SEARCH_ROUTE: navRoutes?.TOP_TIMES_LSC_SEARCH?.route,
    isReadyState
  };
};

export default useTopTimesLscSelection;