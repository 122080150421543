import { useEffect } from 'react';

import useEventCompetitionGendersDwData from '../../../state/topTimes/eventCompetitionGendersDw/UseEventCompetitionGendersDwData';

const useEventCompetitionGenderDwDropdown = () => {
  const { eventCompetitionGendersDwState, getEventCompetitionGendersDw, setEventCompetitionGendersDwState
  } = useEventCompetitionGendersDwData();

  useEffect(() => {
    if (eventCompetitionGendersDwState.isArrayLoaded !== true) {
      getEventCompetitionGendersDw();
    } else if (eventCompetitionGendersDwState.isArrayLoaded === true) {
      const options = eventCompetitionGendersDwState.arrayData.map(x => { return { id: x.eventCompetitionCategoryKey, name: x.typeName } });
      const filteredOptions = options.filter((x) => x.id > 0);

      setEventCompetitionGendersDwState({
        ...eventCompetitionGendersDwState,
        areOptionsLoaded: true,
        options: filteredOptions
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCompetitionGendersDwState.isArrayLoaded]);

  return {
    eventCompetitionGendersDwState
  };
};

export default useEventCompetitionGenderDwDropdown;