import { Routes, Route } from 'react-router-dom';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import SessionTypeContextProvider from '../../state/sessionType/SessionTypeContextProvider';
import TimeStandardsDropdownContextProvider from '../../state/timeStandardsDropdown/TimeStandardsDropdownContextProvider';

import TimeContextProvider from '../../../common/state/time/TimeContextProvider';
import EventContextProvider from '../../../common/state/event/EventContextProvider';
import SessionContextProvider from '../../../common/state/time/SessionContextProvider';
import TimeFiltersContextProvider from '../../../common/state/time/TimeFiltersContextProvider';
import SearchTimeContextProvider from '../../../common/state/searchTime/SearchTimeContextProvider';
import OrganizationContextProvider from '../../../common/state/organization/OrganizationContextProvider';
import TimesOrganizationContextProvider from '../../../common/state/time/TimesOrganizationContextProvider';
import ReportPeriodSeasonContextProvider from '../../../common/state/reportPeriodSeason/ReportPeriodSeasonContextProvider';
import EventCompetitonGenderTypeContextProvider from '../../../common/state/eventCompetitionGender/EventCompetitionGenderTypeContextProvider';

// import TimeSearch from './timeSearch/TimeSearch';
import TimeInfo from './timeDetails/TimeInfo';
import TimeEdit from './timeDetails/TimeEdit';
import TimeSplits from './timeSplits/TimeSplits';
import TimeRecognition from './timeRecognition/TimeRecognition';
import RelayLegs from './relayLegs/RelayLegs';
import TimeById from './timeById/TimeById';
import ManageTimesContextView from './ManageTimesContextView';

import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';

const ContextRoutes = ({ navRoutes }) => (
  <ManageTimesContextView>
    <Routes>
      <Route path={navRoutes['TIMES_DETAIL']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_DETAIL']}
            editElement={<TimeEdit />}
            readOnlyElement={<TimeInfo />} />
        }
      />
      <Route path={navRoutes['TIMES_SPLITS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_SPLITS']}
            editElement={<TimeSplits />} />
        }
      />
      <Route path={navRoutes['TIMES_RECOGNITION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_RECOGNITION']}
            editElement={<TimeRecognition />} />
        }
      />
      <Route path={navRoutes['TIMES_RELAY_LEGS']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_RELAY_LEGS']}
            editElement={<RelayLegs />} />
        }
      />
    </Routes>
  </ManageTimesContextView>
);

const ManageTimeRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['TIMES_SEARCH']?.path} element={<Navigate to={navRoutes['TOP_TIMES_NATIONAL_SEARCH']?.route} replace />} />
      <Route path={navRoutes['TIMES_BY_ID']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['TIMES_BY_ID']}
            editElement={<TimeById />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['TOP_TIMES_NATIONAL_SEARCH']?.route} replace />} />
    </Routes>
  )
};

const ManageTimes = () => (
  <EventContextProvider>
    <EventCompetitonGenderTypeContextProvider>
      <SessionContextProvider>
        <SessionTypeContextProvider>
          <TimesOrganizationContextProvider>
            <TimeContextProvider>
              <TimeFiltersContextProvider>
                <SearchTimeContextProvider>
                  <OrganizationContextProvider>
                    <ReportPeriodSeasonContextProvider>
                      <TimeStandardsDropdownContextProvider>
                        <ManageTimeRoutes />
                      </TimeStandardsDropdownContextProvider>
                    </ReportPeriodSeasonContextProvider>
                  </OrganizationContextProvider>
                </SearchTimeContextProvider>
              </TimeFiltersContextProvider>
            </TimeContextProvider>
          </TimesOrganizationContextProvider>
        </SessionTypeContextProvider>
      </SessionContextProvider>
    </EventCompetitonGenderTypeContextProvider>
  </EventContextProvider>
);

export default ManageTimes;