import { createContext, useState } from 'react';

import TimeStandardsDwData from './TimeStandardsDwData';

export const TimeStandardsDwStateContext = createContext();

const TimeStandardsDwContextProvider = ({ children }) => {
  const stateHook = useState(TimeStandardsDwData.INITIAL_TIME_STANDARDS_DW_STATE);

  return (
    <TimeStandardsDwStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardsDwStateContext.Provider>
  );
};

export default TimeStandardsDwContextProvider;