import { useContext } from "react";

import EventsDwData from "./EventsDwData";
import { EventsDwStateContext } from "./EventsDwContextProvider";

const useEventsDwData = () => {
  const [eventsDwState, setEventsDwState] = useContext(EventsDwStateContext);

  const getEventsDw = (organizationCode) => {
    return EventsDwData.getEventsDwData(organizationCode, eventsDwState, setEventsDwState);
  };

  return {
    eventsDwState,
    getEventsDw,
    setEventsDwState
  };
};

export default useEventsDwData;