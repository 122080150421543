import React from 'react';
import { Routes, Route } from 'react-router';
import { Navigate } from '../../../common/wrappers/ReactRouterDom';

import RecordsContextProvider from '../../state/recordsAdmin/RecordsContextProvider';
import RecordTimesContextProvider from '../../state/recordTimesAdmin/RecordTimesContextProvider';

import SelectOrgUnitContextProvider from '../../../common/state/selectOrgUnit/SelectOrgUnitContextProvider';
import PersonOrgRoleContextProvider from '../../../common/state/personOrgRole/PersonOrgRoleContextProvider';
import RecordGroupContextProvider from '../../../common/state/time/recordGroup/RecordGroupContextProvider';
import EventContextProvider from '../../../common/state/event/EventContextProvider';
import RecordAgeGroupContextProvider from '../../state/recordAgeGroup/RecordAgeGroupContextProvider';
import CourseContextProvider from '../../../common/state/course/CourseContextProvider';

import RecordsAdminContextView from './RecordsAdminContextView';

import OrgUnitSelection from './orgUnitSelection/OrgUnitSelection';
import RecordsAdminSearch from './recordsAdminSearch/RecordsAdminSearch';
import RecordsAdminCleanup from './recordsAdminCleanup/RecordsAdminCleanup';
import RecordsAdminListInstanceDetail from './recordsAdminListInstanceDetail/RecordsAdminListInstanceDetail';
import RecordsAdminEvents from './recordsAdminEvents/RecordsAdminEvents';
import RecordsAdminTimes from './recordsAdminTimes/RecordsAdminTimes';

import useNavRoutes from '../../../common/state/security/UseNavRoutes';
import LoadingModal from '../../../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../../../common/components/security/SecuredRouteElement';

const ContextRoutes = ({ navRoutes }) => (
  <RecordsContextProvider>
    <RecordTimesContextProvider>
      <RecordGroupContextProvider>
        <RecordsAdminContextView>
          <CourseContextProvider>
          <EventContextProvider>
            <RecordAgeGroupContextProvider>
              <Routes>
                <Route path={navRoutes['RECORDS_ADMIN']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['RECORDS_ADMIN']}
                      editElement={<RecordsAdminSearch />} />
                  }
                />
                <Route path={navRoutes['RECORDS_CLEANUP']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['RECORDS_CLEANUP']}
                      editElement={<RecordsAdminCleanup />} />
                  }
                />
                <Route path={navRoutes['RECORDS_LIST_INSTANCE_DETAIL']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['RECORDS_LIST_INSTANCE_DETAIL']}
                      editElement={<RecordsAdminListInstanceDetail />} />
                  }
                />
                <Route path={navRoutes['RECORDS_EVENTS']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['RECORDS_EVENTS']}
                      editElement={<RecordsAdminEvents />} />
                  }
                />
                <Route path={navRoutes['RECORDS_TIMES']?.path}
                  element={
                    <SecuredRouteElement
                      navRoute={navRoutes['RECORDS_TIMES']}
                      editElement={<RecordsAdminTimes />} />
                  }
                />
              </Routes>
            </RecordAgeGroupContextProvider>
          </EventContextProvider>
          </CourseContextProvider>
        </RecordsAdminContextView>
      </RecordGroupContextProvider>
    </RecordTimesContextProvider>
  </RecordsContextProvider>
);

const RecordsAdminRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <Routes>
      <Route path={navRoutes['RECORDS_SELECTION']?.path}
        element={
          <SecuredRouteElement
            navRoute={navRoutes['RECORDS_SELECTION']}
            editElement={<OrgUnitSelection />} />
        }
      />
      <Route path={'/*'} element={<ContextRoutes navRoutes={navRoutes} />} />
      <Route path={'/'} element={<Navigate to={navRoutes['RECORDS_SELECTION']?.route} replace />} />
    </Routes>
  )
};

const RecordsAdmin = () => (
  <SelectOrgUnitContextProvider>
    <PersonOrgRoleContextProvider>
      <RecordsAdminRoutes />
    </PersonOrgRoleContextProvider>
  </SelectOrgUnitContextProvider>
);

export default RecordsAdmin;