import { useContext } from 'react';

import { SearchTimeStandardStateContext } from './SearchTimeStandardContextProvider';

const useSearchTimeStandardData = () => {
  const [searchTimeStandardState, setSearchTimeStandardState] = useContext(SearchTimeStandardStateContext);

  return {
    searchTimeStandardState,
    setSearchTimeStandardState
  };
};

export default useSearchTimeStandardData;