import useSelectOrgUnitData from "../../../../common/state/selectOrgUnit/UseSelectOrgUnitData";

const ROUTE_NAME = 'TOP_TIMES_CLUB_SEARCH';

const useTopTimesClubSearch = () => {
  const { selectOrgUnitState } = useSelectOrgUnitData();

  return {
    orgUnitId: selectOrgUnitState.orgUnitId,
    orgUnitName: selectOrgUnitState.orgUnitName,
    routeName: ROUTE_NAME
  };
};

export default useTopTimesClubSearch;