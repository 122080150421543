import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from '../../../../common/wrappers/ReactRouterDom';

import NavLinks from '../NavLinks';

import { formatTimeStandardCuts } from './FormatTimeStandardCuts';
import validate from './TimeStandardAgeGroupCutsValidation';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';

import useEnvironmentVariableData from '../../../../common/state/environmentVariable/UseEnvironmentVariableData';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import useForm from '../../../../common/utils/UseForm';
import Constants from '../../../../common/utils/Constants';
import { formatTimeForDisplay, formatTimeForFilterObject } from '../../../../common/utils/TimesUtils';

const useTimeStandardAgeGroupCuts = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { getContextSecurity } = useSecurityData();
  const { MALE_COMPETITION_GENDER_ID, FEMALE_COMPETITION_GENDER_ID, MIXED_EVENT_COMPETITION_GENDER_ID, environmentVariableState } = useEnvironmentVariableData();
  const [state, setState] = useState({ tryRedirect: false, ageGroup: '' });
  const { timeStandardState, getTimeStandard, putTimeStandard } = useTimeStandardData();
  const { errorState, formState, handleSubmit, onFormValueChanged, setFormData, setIsDirty,
    isSubmitting } = useForm(getInitialFormState, submitFormCallback, validate);

  function submitFormCallback(formState) {
    putTimeStandard(timeStandardState.objData.timeStandardId, formatTimeStandardCuts(timeStandardState, formState, FEMALE_COMPETITION_GENDER_ID, MALE_COMPETITION_GENDER_ID, MIXED_EVENT_COMPETITION_GENDER_ID));
    setState({ ...state, tryRedirect: true });
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.TIME_STANDARDS_SEARCH);
  }

  useEffect(() => {
    if (location.state && location.state.timeStandardId > 0
      && location.state.timeStandardAgeGroupId > 0) {
      setFormData({
        ...formState,
        timeStandardId: location.state.timeStandardId,
        timeStandardAgeGroupId: location.state.timeStandardAgeGroupId
      });
      getTimeStandard(location.state.timeStandardId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state])

  function makeFormStateUpdateObj(timeStandardCutArray, timeStandardId, timeStandardAgeGroupId, mode) {
    const MIN_EVENT_ID = 1;
    const MAX_EVENT_ID = 88;
    const MODE_INITIALIZE = "INITIALIZE";

    if (timeStandardCutArray === undefined) return {};

    let swimTime = "";

    let formStateObj = {
      timeStandardId: timeStandardId,
      timeStandardAgeGroupId: timeStandardAgeGroupId
    }

    for (let i = MIN_EVENT_ID; i <= MAX_EVENT_ID; i++) {
      let objPropMale = `eventId${i}MSwimTime`;
      swimTime = mode === MODE_INITIALIZE ? '' : (timeStandardCutArray.find(e => e.eventId === i && e.eventCompetitionGenderTypeId === MALE_COMPETITION_GENDER_ID)?.swimTime || '');
      formStateObj[objPropMale] = swimTime ? formatTimeForFilterObject(formatTimeForDisplay(swimTime)) : '';

      let objPropFemale = `eventId${i}FSwimTime`;
      swimTime = mode === MODE_INITIALIZE ? '' : (timeStandardCutArray.find(e => e.eventId === i && e.eventCompetitionGenderTypeId === FEMALE_COMPETITION_GENDER_ID)?.swimTime || '');
      formStateObj[objPropFemale] = swimTime ? formatTimeForFilterObject(formatTimeForDisplay(swimTime)) : '';

      //Relays
      if ((i >= 23 && i <= 27) || (i >= 50 && i <= 54) || (i >= 76 && i <= 80)) {
        let objPropMixed = `eventId${i}XSwimTime`;
        swimTime = mode === MODE_INITIALIZE ? '' : (timeStandardCutArray.find(e => e.eventId === i && e.eventCompetitionGenderTypeId === MIXED_EVENT_COMPETITION_GENDER_ID)?.swimTime || '');
        formStateObj[objPropMixed] = swimTime ? formatTimeForFilterObject(formatTimeForDisplay(swimTime)) : '';
      }
    }

    return formStateObj;
  }

  useEffect(() => {
    getContextSecurity(location.state.timeStandardId, 'Times');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (location.state && location.state.timeStandardId > 0 && location.state.timeStandardAgeGroupId > 0 &&
      Object.keys(timeStandardState.objData).length > 0 && environmentVariableState.isLoaded === true) {
      const selectedAgeGroup = timeStandardState.objData?.timeStandardAgeGroup.find(e => e.timeStandardAgeGroupId === location.state.timeStandardAgeGroupId);
      const ageGroupDisplay = `${selectedAgeGroup?.ageGroupName} (Ages: ${selectedAgeGroup?.minAge.toString()} - ${selectedAgeGroup?.maxAge.toString()})`;
      setState({ ...state, ageGroup: ageGroupDisplay });

      const formStateUpdateObj = makeFormStateUpdateObj(
        selectedAgeGroup?.timeStandardCut,
        location.state.timeStandardId,
        location.state.timeStandardAgeGroupId,
        "POPULATE"
      );

      setFormData({
        ...formState,
        ...formStateUpdateObj
      });
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, formState.timeStandardAgeGroupId, timeStandardState.objData, environmentVariableState])

  useEffect(() => {
    if (state.tryRedirect === true && timeStandardState.isObjLoaded === true && isSubmitting === false) {
      navigate(NavLinks.TIME_STANDARDS_SEARCH);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, timeStandardState.isObjLoaded, isSubmitting])

  function getInitialFormState() {
    return makeFormStateUpdateObj([], Constants.DEFAULT_ID, Constants.DEFAULT_ID, "INITIALIZE");
  };

  return {
    timeStandardState,
    state,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked,
    Constants
  }
};

export default useTimeStandardAgeGroupCuts;