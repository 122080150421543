import React, { Fragment } from 'react';

import BugIcon from '../../../../common/components/icons/BugIcon';
import DeleteIcon from '../../../../common/components/icons/DeleteIcon';
import global from '../../../../common/components/GlobalStyle.module.css';

const GridRow = ({ recordEvent, onCleanupRecords, getGenderDescription, onDeleteRecordListInstance }) => (
  <tr>
    <td>{recordEvent.eventCode || ''}</td>
    <td>{getGenderDescription(recordEvent.eventCompetitionGenderTypeId)}</td>
    <td>{recordEvent.ageGroupDesc || ''}</td>
    <td style={{ float: 'right' }}>
    {!recordEvent.hasDependentData &&
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onDeleteRecordListInstance(recordEvent.recordlistInstanceId)}>
        <DeleteIcon />
      </button>
      }
      <button className={global.IconButtonMargin}
        type="button"
        onClick={() => onCleanupRecords(recordEvent.recordlistInstanceId)}>
        <BugIcon />
      </button>
    </td>
  </tr>
);

const LargeRecordsAdminEventsGrid = ({ data, isLoading, onCleanupRecords, getGenderDescription, onDeleteRecordListInstance }) => (
  <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
    <thead>
      <tr>
        <th>Event Code</th>
        <th>Competition Category</th>
        <th>Age Group</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      {isLoading === true
        ? (
          <tr>
            <td colSpan="2">Loading...</td>
          </tr>
        ) : Array.isArray(data) && data.length > 0
          ? data.map((recordEvent, i) => (
            <GridRow
              key={i}
              recordEvent={recordEvent}
              onCleanupRecords={onCleanupRecords}
              getGenderDescription={getGenderDescription}
              onDeleteRecordListInstance={onDeleteRecordListInstance} />
          )) : (
            <tr>
              <td colSpan="2">No Records</td>
            </tr>
          )
      }
    </tbody>
  </table>
);

export default LargeRecordsAdminEventsGrid;