import React, { Fragment } from 'react';

import SGColumnHeader from '../../../../common/components/grids/SGColumnHeader';
import useSortableGrid from '../../../../common/utils/UseSortableGrid';
import SGConstants from '../../../../common/utils/SGConstants';

import global from '../../../../common/components/GlobalStyle.module.css';
import { formatDate } from '../../../../common/utils/DateFunctions';
import { formatTimeForDisplay } from '../../../../common/utils/TimesUtils';
import Checkbox from '../../../../common/components/checkboxes/Checkbox';

const LargeGrid = ({ state, onAddRecordTimesCheckbox }) => {
  const {
    sortableGridState,
    onColumnClicked
  } = useSortableGrid(state.data);

  return (
    <table className={[global.UsasTable, 'visible-md visible-lg'].join(' ')}>
      <thead>
        <tr>
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swimmer Name'} columnField={'swimmerName'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Parent Code'} columnField={'parentCode'}
            sortType={SGConstants.SORT_TYPE_ALPHABETICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Org Unit Code'} columnField={'orgUnitCode'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Team Name'} columnField={'orgUnitName'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Meet Name'} columnField={'meetName'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Event'} columnField={'eventCode'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Date'} columnField={'swimDate'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />
          <SGColumnHeader sortableGridState={sortableGridState} columnLabel={'Swim Time'} columnField={'swimTime'}
            sortType={SGConstants.SORT_TYPE_NUMERICAL} onColumnClicked={onColumnClicked} />

          <th></th>
        </tr>
      </thead>
      <tbody>
        {state.isArrayLoading === true
          ? (
            <tr>
              {state.isArrayLoading === true ?
                <td colSpan="4">Loading...</td>
                : <td colSpan="4">No Record Times</td>
              }
            </tr>
          ) : Array.isArray(sortableGridState.sortedGridData) && sortableGridState.sortedGridData.length > 0
            ? sortableGridState.sortedGridData.map((timeRecord, i) => (
              <tr key={i}>
                <td>{timeRecord.swimmerName}</td>
                <td>{timeRecord.parentCode}</td>
                <td>{timeRecord.orgUnitCode}</td>
                <td>{timeRecord.orgUnitName}</td>
                <td>{timeRecord.meetName}</td>
                <td>{timeRecord.eventCode}</td>
                <td>{formatDate(timeRecord.swimDate)}</td>
                <td>{formatTimeForDisplay(timeRecord.swimTime)}</td>
                <td>
                  <Checkbox
                    label=""
                    name="addRecordTimes"
                    onChange={(e) => { onAddRecordTimesCheckbox(e, timeRecord.swimTimeRecognitionId, timeRecord.swimTimeRelayId) }}
                    checked={timeRecord.addTime}
                  />
                </td>
              </tr>
            )) : (
              <tr>
                <td colSpan="4">No Time Records</td>
              </tr>
            )
        }
      </tbody>
    </table>
  );
};

const SmallGrid = ({ state, onAddRecordTimesCheckbox }) => (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {state.isArrayLoading === true
      ? (
        <div className={global.SmallTableRow}>
          <div className={global.SmallTableRowHead}>&nbsp;</div>
          {state.isArrayLoading === true ?
            <div className={global.SmallTableRowLabels}>Loading... </div>
            : <div className={global.SmallTableRowLabels}>No Records</div>
          }
        </div>
      ) : Array.isArray(state.data) && state.data.length > 0
        ? state.data.map((timeRecord, i) => (
          <div className={global.SmallTableRow} key={i}>
            <div className={global.SmallTableRowHead}>
              <span className='hidden-xs'>{timeRecord.swimmerName}</span>&nbsp;
              <Checkbox
                label=""
                name="addRecordTimes"
                onChange={(e) => { onAddRecordTimesCheckbox(e, timeRecord.swimTimeRecognitionId, timeRecord.swimTimeRelayId) }}
                checked={timeRecord.addTime}
              />
            </div>
            <div className={global.SmallTableRowInfo}>
              <div className='row'>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Name</div>
                <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{timeRecord.swimmerName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Parent Code</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.parentCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Org Unit Code</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.orgUnitCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Team Name</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.orgUnitName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Meet Name</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.meetName}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Event</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{timeRecord.eventCode}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Date</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatDate(timeRecord.swimDate)}</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Swim Time</div>
                <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{formatTimeForDisplay(timeRecord.swimTime)}</div>
              </div>
            </div>
          </div>
        )) : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowLabels}>No Records.</div>
          </div>
        )
    }
  </div >
);

const RecordsAdminTimesGrid = ({ state, onAddRecordTimesCheckbox }) => {
  return (
    <Fragment>
      <LargeGrid state={state} onAddRecordTimesCheckbox={onAddRecordTimesCheckbox} />
      <SmallGrid state={state} onAddRecordTimesCheckbox={onAddRecordTimesCheckbox} />
    </Fragment>
  );
};

export default RecordsAdminTimesGrid;