import { useState } from 'react';

import TimeStandardNameData from './TimeStandardNameData';

const useTimeStandardNameData = () => {
  const [timeStandardNameState, setTimeStandardNameState] = useState(TimeStandardNameData.INITIAL_STATE);

  const getTimeStandardNames = (orgUnitId, typeId) => TimeStandardNameData.getTimeStandardNames(orgUnitId, typeId, timeStandardNameState, setTimeStandardNameState);

  return {
    timeStandardNameState,
    getTimeStandardNames
  };
};

export default useTimeStandardNameData;