import React, { Fragment } from "react";

import useTimesEvents from './UseTimesEvents';

import EventCombobox from '../../../../components/comboboxes/eventCombobox/EventCombobox';

const TimesEvents = ({ label, eventName, eventValue, eventId, onChange }) => {
  // eslint-disable-next-line no-unused-vars
  const { eventState } = useTimesEvents();

  return (
    <Fragment>
      <EventCombobox
        label={label}
        name={eventName}
        valueToMatch={eventValue}
        error={eventId}
        message={eventId}
        onChange={onChange}
      />
    </ Fragment >
  );
};

export default TimesEvents;