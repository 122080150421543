import { useEffect } from 'react';
import { useNavigate, useParams } from '../../../../common/wrappers/ReactRouterDom';

import useSecurityData from '../../../../common/state/security/UseSecurityData';
import useTimeData from '../../../../common/state/time/UseTimeData';
import ToIntIfInt from '../../../../common/utils/ToIntIfInt';
import { navLinks } from '../UseManageTimes';

const TAXONOMIES = ['TimesDetail'];
const SCOPE = 'Times';

const useTimeById = () => {
  const navigate = useNavigate();
  const { timeId, returnSystem } = useParams();
  const { timeState, getTime, timeFiltersState, setTimeFiltersState } = useTimeData();
  const { getContextSecurity, contextSecurityState } = useSecurityData();
  const swimTimeId = ToIntIfInt(timeId);

  useEffect(() => {
    if (returnSystem && timeFiltersState.backToSystem !== returnSystem) {
      switch (returnSystem.toLowerCase()) {
        case 'meet':
          setTimeFiltersState({ ...timeFiltersState, backToSystem: 'Meet' });
          break;

        case 'member':
          setTimeFiltersState({ ...timeFiltersState, backToSystem: 'Member' });
          break;

        default:
          setTimeFiltersState({ ...timeFiltersState, backToSystem: 'Times' });
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //load time
  useEffect(() => {
    if (swimTimeId > 0 && timeState.isObjLoading !== true && timeState.isObjLoaded !== true) {
      getTime(swimTimeId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swimTimeId, timeState]);

  //load security context
  useEffect(() => {
    if (timeState.isObjLoaded === true) {
      if (timeState.objData?.swimTimeId > 0) {
        if (contextSecurityState.isObjLoading !== true && contextSecurityState.isObjLoaded !== true) {
          getContextSecurity(swimTimeId, TAXONOMIES, SCOPE);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextSecurityState, timeState]);

  //navigate
  useEffect(() => {
    if (timeState.isObjLoaded === true) {
      if (contextSecurityState.isObjLoaded === true) {
        if (contextSecurityState.contextId === swimTimeId && contextSecurityState.scope === 'Times') {
          navigate(navLinks.DETAIL);
        } else {
          navigate(navLinks.SEARCH);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeState, contextSecurityState]);

  return {};
};

export default useTimeById;
