import React, { createContext, useState } from 'react';

import TimeStandardData from './TimeStandardData';

export const TimeStandardStateContext = createContext();

const TimeStandardContextProvider = ({ children }) => {
  const stateHook = useState(TimeStandardData.INITIAL_STATE);

  return (
    <TimeStandardStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardStateContext.Provider>
  );
};

export default TimeStandardContextProvider;