import { useEffect, useState } from 'react';
import { useNavigate } from '../../../../common/wrappers/ReactRouterDom';

import validate from './MyTimeStandardAgeGroupAddValidation';

import useTimeStandardData from '../../../state/timeStandard/UseTimeStandardData';

import useForm from '../../../../common/utils/UseForm';
import useSecurityData from '../../../../common/state/security/UseSecurityData';

import NavLinks from '../NavLinks';

const DUPLICATE_AGE_GROUP_NAME_ERROR = 'The provided age group name matches the name of an existing age group. Duplicate names are not allowed.';

const useMyTimeStandardAgeGroupAdd = () => {
  const navigate = useNavigate();
  const [state, setState] = useState({ tryRedirect: false });
  const { getContextSecurity } = useSecurityData();
  const { timeStandardState, putTimeStandard } = useTimeStandardData();
  const { errorState, setErrors, formState, handleSubmit, onFormValueChanged, setFormData, setIsDirty,
    isSubmitting } = useForm(getInitialFormState, submitFormCallback, validate);

  const validateAgeGroup = (timeStandardCopy) => {
    //Check for dupes
    let duplicateAgeGroupNames = false;
    for (let i = 0; i < timeStandardCopy.timeStandardAgeGroup.length; i++) {
      if (timeStandardCopy.timeStandardAgeGroup[i].ageGroupName === formState.ageGroupName) {
        duplicateAgeGroupNames = true;
      }
    }
    if (duplicateAgeGroupNames === true) {
      setErrors({ ...errorState, duplicateNames: DUPLICATE_AGE_GROUP_NAME_ERROR });
      return false;
    }
    return true;
  }

  const editTimeStandardObj = () => {
    let timeStandardCopy = JSON.parse(JSON.stringify(timeStandardState.objData));
    if (timeStandardCopy.timeStandardAgeGroup.length > 0) {
      if (validateAgeGroup(timeStandardCopy) === true) {
        timeStandardCopy.timeStandardAgeGroup.push({
          timeStandardId: timeStandardCopy.timeStandardId,
          minAge: parseInt(formState.minAge),
          maxAge: parseInt(formState.maxAge),
          ageGroupName: formState.ageGroupName.trim(),
          timeStandardCut: []
        });

        return timeStandardCopy;
      }
      else {
        return null;
      }
    }
    else if (timeStandardCopy.timeStandardAgeGroup.length === 0) {
      timeStandardCopy.timeStandardAgeGroup.push({
        timeStandardId: timeStandardCopy.timeStandardId,
        minAge: parseInt(formState.minAge),
        maxAge: parseInt(formState.maxAge),
        ageGroupName: formState.ageGroupName.trim(),
        timeStandardCut: []
      });

      return timeStandardCopy;
    }
  }

  function submitFormCallback(formState) {
    const editedTimeStandardObj = editTimeStandardObj();
    if (editedTimeStandardObj !== null) {
      putTimeStandard(timeStandardState.objData.timeStandardId, editTimeStandardObj());
      setState({ ...state, tryRedirect: true });
    }
  }

  const onCancelClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    navigate(NavLinks.MY_TIME_STANDARDS_AGE_GROUP);
  }

  useEffect(() => {
    getContextSecurity(timeStandardState.objData.timeStandardId, 'Times');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setFormData({
      ...formState,
      ageGroupName: 'Open',
      minAge: '1',
      maxAge: '99'
    });
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (state.tryRedirect === true && timeStandardState.isObjLoaded === true && isSubmitting === false) {
      navigate(NavLinks.MY_TIME_STANDARDS_AGE_GROUP);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.tryRedirect, timeStandardState.isObjLoaded, isSubmitting])

  return {
    timeStandardState,
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onCancelClicked
  }
};

export default useMyTimeStandardAgeGroupAdd;

function getInitialFormState() {
  return {
    ageGroupName: '',
    minAge: '',
    maxAge: ''
  };
};