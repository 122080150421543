import { Fragment } from "react";

import TimeInput from '../../../../../common/components/inputs/TimeInput';

import style from '../../TimeStandards.module.css';

const LongDistanceIndividualFreestyleEventRowsLarge = ({ errorState, formState, onFormValueChanged }) => (
  <Fragment>
    <tr key={"800/1000FR"}>
      <td className={style.EventColumn}>800/1000 FR</td>
      <td><TimeInput
        label=""
        name="eventId5FSwimTime"
        error={errorState.eventId5FSwimTime}
        message={errorState.eventId5FSwimTime}
        value={formState.eventId5FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId5FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId32FSwimTime"
        error={errorState.eventId32FSwimTime}
        message={errorState.eventId32FSwimTime}
        value={formState.eventId32FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId32FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId59FSwimTime"
        error={errorState.eventId59FSwimTime}
        message={errorState.eventId59FSwimTime}
        value={formState.eventId59FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId59FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId5MSwimTime"
        error={errorState.eventId5MSwimTime}
        message={errorState.eventId5MSwimTime}
        value={formState.eventId5MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId5MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId32MSwimTime"
        error={errorState.eventId32MSwimTime}
        message={errorState.eventId32MSwimTime}
        value={formState.eventId32MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId32MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId59MSwimTime"
        error={errorState.eventId59MSwimTime}
        message={errorState.eventId59MSwimTime}
        value={formState.eventId59MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId59MSwimTime', value) }} /></td>
    </tr>
    <tr key={"1500/1650FR"}>
      <td className={style.EventColumn}>1500/1650 FR</td>
      <td><TimeInput
        label=""
        name="eventId6FSwimTime"
        error={errorState.eventId6FSwimTime}
        message={errorState.eventId6FSwimTime}
        value={formState.eventId6FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId6FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId33FSwimTime"
        error={errorState.eventId33FSwimTime}
        message={errorState.eventId33FSwimTime}
        value={formState.eventId33FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId33FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId60FSwimTime"
        error={errorState.eventId60FSwimTime}
        message={errorState.eventId60FSwimTime}
        value={formState.eventId60FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId60FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId6MSwimTime"
        error={errorState.eventId6MSwimTime}
        message={errorState.eventId6MSwimTime}
        value={formState.eventId6MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId6MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId33MSwimTime"
        error={errorState.eventId33MSwimTime}
        message={errorState.eventId33MSwimTime}
        value={formState.eventId33MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId33MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId60MSwimTime"
        error={errorState.eventId60MSwimTime}
        message={errorState.eventId60MSwimTime}
        value={formState.eventId60MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId60MSwimTime', value) }} /></td>
    </tr>
    <tr key={"2000FR"}>
      <td className={style.EventColumn}>2000 FR</td>
      <td><TimeInput
        label=""
        name="eventId7FSwimTime"
        error={errorState.eventId7FSwimTime}
        message={errorState.eventId7FSwimTime}
        value={formState.eventId7FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId7FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId34FSwimTime"
        error={errorState.eventId34FSwimTime}
        message={errorState.eventId34FSwimTime}
        value={formState.eventId34FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId34FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId61FSwimTime"
        error={errorState.eventId61FSwimTime}
        message={errorState.eventId61FSwimTime}
        value={formState.eventId61FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId61FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId7MSwimTime"
        error={errorState.eventId7MSwimTime}
        message={errorState.eventId7MSwimTime}
        value={formState.eventId7MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId7MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId34MSwimTime"
        error={errorState.eventId34MSwimTime}
        message={errorState.eventId34MSwimTime}
        value={formState.eventId34MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId34MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId61MSwimTime"
        error={errorState.eventId61MSwimTime}
        message={errorState.eventId61MSwimTime}
        value={formState.eventId61MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId61MSwimTime', value) }} /></td>
    </tr>
    <tr key={"3000FR"}>
      <td className={style.EventColumn}>3000 FR</td>
      <td><TimeInput
        label=""
        name="eventId8FSwimTime"
        error={errorState.eventId8FSwimTime}
        message={errorState.eventId8FSwimTime}
        value={formState.eventId8FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId8FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId35FSwimTime"
        error={errorState.eventId35FSwimTime}
        message={errorState.eventId35FSwimTime}
        value={formState.eventId35FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId35FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId62FSwimTime"
        error={errorState.eventId62FSwimTime}
        message={errorState.eventId62FSwimTime}
        value={formState.eventId62FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId62FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId8MSwimTime"
        error={errorState.eventId8MSwimTime}
        message={errorState.eventId8MSwimTime}
        value={formState.eventId8MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId8MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId35MSwimTime"
        error={errorState.eventId35MSwimTime}
        message={errorState.eventId35MSwimTime}
        value={formState.eventId35MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId35MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId62MSwimTime"
        error={errorState.eventId62MSwimTime}
        message={errorState.eventId62MSwimTime}
        value={formState.eventId62MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId62MSwimTime', value) }} /></td>
    </tr>
    <tr key={"4000FR"}>
      <td className={style.EventColumn}>4000 FR</td>
      <td><TimeInput
        label=""
        name="eventId9FSwimTime"
        error={errorState.eventId9FSwimTime}
        message={errorState.eventId9FSwimTime}
        value={formState.eventId9FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId9FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId36FSwimTime"
        error={errorState.eventId36FSwimTime}
        message={errorState.eventId36FSwimTime}
        value={formState.eventId36FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId36FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId63FSwimTime"
        error={errorState.eventId63FSwimTime}
        message={errorState.eventId63FSwimTime}
        value={formState.eventId63FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId63FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId9MSwimTime"
        error={errorState.eventId9MSwimTime}
        message={errorState.eventId9MSwimTime}
        value={formState.eventId9MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId9MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId36MSwimTime"
        error={errorState.eventId36MSwimTime}
        message={errorState.eventId36MSwimTime}
        value={formState.eventId36MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId36MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId63MSwimTime"
        error={errorState.eventId63MSwimTime}
        message={errorState.eventId63MSwimTime}
        value={formState.eventId63MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId63MSwimTime', value) }} /></td>
    </tr>
    <tr key={"5000FR"}>
      <td className={style.EventColumn}>5000 FR</td>
      <td><TimeInput
        label=""
        name="eventId10FSwimTime"
        error={errorState.eventId10FSwimTime}
        message={errorState.eventId10FSwimTime}
        value={formState.eventId10FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId10FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId37FSwimTime"
        error={errorState.eventId37FSwimTime}
        message={errorState.eventId37FSwimTime}
        value={formState.eventId37FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId37FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId64FSwimTime"
        error={errorState.eventId64FSwimTime}
        message={errorState.eventId64FSwimTime}
        value={formState.eventId64FSwimTime}
        onChange={(value) => { onFormValueChanged('eventId64FSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId10MSwimTime"
        error={errorState.eventId10MSwimTime}
        message={errorState.eventId10MSwimTime}
        value={formState.eventId10MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId10MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId37MSwimTime"
        error={errorState.eventId37MSwimTime}
        message={errorState.eventId37MSwimTime}
        value={formState.eventId37MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId37MSwimTime', value) }} /></td>
      <td><TimeInput
        label=""
        name="eventId64MSwimTime"
        error={errorState.eventId64MSwimTime}
        message={errorState.eventId64MSwimTime}
        value={formState.eventId64MSwimTime}
        onChange={(value) => { onFormValueChanged('eventId64MSwimTime', value) }} /></td>
    </tr>
  </Fragment>
);

export default LongDistanceIndividualFreestyleEventRowsLarge;