import React from 'react';

import useRecordsListCombobox from './UseRecordsListCombobox';

import Combobox from '../../../../common/components/comboboxes/Combobox';
import global from '../../../../common/components/GlobalStyle.module.css';

const RecordsListCombobox = ({ label, name, valueToMatch, error, message, onChange, recordGroupId }) => {
  const { recordListState } = useRecordsListCombobox(recordGroupId);

  return recordListState.message
    ? <span className={global.LoadingMsg}>{recordListState.message}</span>
    : (
      <Combobox
        label={label}
        name={name}
        isLoading={recordListState.isArrayLoading}
        items={recordListState.items}
        valueToMatch={valueToMatch}
        error={error}
        message={message}
        onChange={onChange} />
    );
};

export default RecordsListCombobox;