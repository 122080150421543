import { Fragment } from 'react';

import UseTopTimesClubSelection from './UseTopTimesClubSelection';

import SelectOrgUnit from '../../../../common/components/selectOrgUnit/SelectOrgUnit';
import PopUpModal from '../../../../common/components/dialogs/PopUpModal';

import { LOADING_MSG } from '../../../../common/utils/Constants';

const TopTimesClubSelection = () => {
  const {
    isLoading,
    CLUB_TOP_TIMES_SEARCH_ROLE_GROUP_ID,
    TOP_TIMES_CLUB_SEARCH_ROUTE,
    isReadyState
  } = UseTopTimesClubSelection();

  return (
    <Fragment>
      {CLUB_TOP_TIMES_SEARCH_ROLE_GROUP_ID && TOP_TIMES_CLUB_SEARCH_ROUTE && isReadyState === true &&
        <SelectOrgUnit
          pageTitle={'Club Top Times Selection'}
          navLink={TOP_TIMES_CLUB_SEARCH_ROUTE}
          roleGroupId={CLUB_TOP_TIMES_SEARCH_ROLE_GROUP_ID} />
      }
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={LOADING_MSG}
        displayPopUp={isLoading} />
    </Fragment>
  );
};

export default TopTimesClubSelection;