import { Fragment } from "react";
import PrimaryButton from "../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../common/components/headings/Headings";
import Constants from '../../../../common/utils/Constants';

import RecordsAdminCleanupGrid from "./RecordsAdminCleanupGrid";
import useRecordsAdminCleanup from './UseRecordsAdminCleanup';

const RecordsAdminCleanup = () => {
  const { 
    recordTimesState, 
    modalState, 
    onDeleteTimeRecord, 
    onDeleteTime, 
    onGoBack, 
    onModalCanceled, 
    onOpenModalClicked,
    onAddRecordTime,
    recordGroupName } = useRecordsAdminCleanup();
  
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Records Admin Cleanup</Headings.H3>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <PrimaryButton type="button" onClick={onAddRecordTime}>Add Record Times</PrimaryButton>&nbsp;
        </div>
      </div>
      <div className="row usas-extra-bottom-margin">
        <div className="col-xs-12">
          <RecordsAdminCleanupGrid
            state={recordTimesState}
            onDeleteTimeRecord={onDeleteTimeRecord}
            onDeleteTime={onOpenModalClicked}
            recordGroupName={recordGroupName}
          />
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 usas-extra-top-margin">
          <SecondaryButton type="button" onClick={onGoBack}>Back</SecondaryButton>&nbsp;
        </div>
      </div>
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={modalState.modalTitle}
        displayPopUp={modalState.displayPopUp}
        onModalCanceled={onModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>Name:</b> {modalState.swimmerName}</p>
            <p><b>Time: </b> {modalState.swimTime}</p>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="button" onClick={onDeleteTime}>Delete</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={recordTimesState.isSaving} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={recordTimesState.isArrayLoading} />
    </Fragment>);
}

export default RecordsAdminCleanup;