import { useEffect, useState } from 'react';

import validate from './TopTimesSearchFormValidation';

import useEventsDwData from '../../../state/topTimes/eventsDw/UseEventsDwData';
import useTimeStandardsDwData from '../../../state/topTimes/timeStandardsDw/UseTimeStandardsDwData';
import useReportPeriodSeasonsDwData from '../../../state/topTimes/reportPeriodSeasonsDw/UseReportPeriodSeasonsDwData';
import useEventCompetitionGendersDwData from '../../../state/topTimes/eventCompetitionGendersDw/UseEventCompetitionGendersDwData';

import UseForm from "../../../../common/utils/UseForm";
import { DEFAULT_ID, BLANK_DATE_STRING, USAS_ORG_UNIT_LABEL_LSC, USAS_ORG_UNIT_LABEL } from '../../../../common/utils/Constants';

const ORG_CODE = 'USAS';

const DEFAULT_MAX_RESULTS = 200;

const DISPLAY_OPTIONS = [
  { id: 1, name: 'Show Best Times Only', value: true },
  { id: 2, name: 'All Times', value: false }
];

const INITIAL_FORM_STATE = {
  clubInputLabel: USAS_ORG_UNIT_LABEL_LSC,
  zoneOrgUnitId: DEFAULT_ID,
  zoneOrgUnitName: '--',
  lscOrgUnitId: DEFAULT_ID,
  lscOrgUnitName: '--',
  clubOrgUnitId: '',
  clubOrgUnitName: '',
  reportPeriodId: DEFAULT_ID,
  reportPeriodName: '--',
  timeStandardId: DEFAULT_ID,
  timeStandardName: '--',
  eventCompetitionGenderTypeId: DEFAULT_ID,
  eventCompetitionGenderTypeName: '--',
  displayOptionId: DISPLAY_OPTIONS[0].id,
  displayOptionName: DISPLAY_OPTIONS[0].name,
  events: [],
  startDate: BLANK_DATE_STRING,
  endDate: BLANK_DATE_STRING,
  minAge: '',
  maxAge: '',
  isUsCitizen: false,
  showBestTimesOnly: DISPLAY_OPTIONS[0].value,
  maxResults: DEFAULT_MAX_RESULTS
};

const useTopTimesSearchForm = (onSubmitFormCallback, lscOrgUnitObj, clubOrgUnitObj) => {
  const { eventCompetitionGendersDwState } = useEventCompetitionGendersDwData();
  const { reportPeriodSeasonsDwState } = useReportPeriodSeasonsDwData();
  const { timeStandardsDwState } = useTimeStandardsDwData();
  const { eventsDwState } = useEventsDwData();
  const { formState, errorState, updateFormState, onValueTextPairChanged, setFormState, handleSubmit, setIsDirty
  } = UseForm(INITIAL_FORM_STATE, formatBeforeSubmit, validate);
  const [reportParametersState, setReportParametersState] = useState(null);

  const onZoneOrgUnitChanged = (zoneOrgUnitId, zoneOrgUnitName) => {
    setFormState({
      ...formState,
      zoneOrgUnitId,
      zoneOrgUnitName,
      lscOrgUnitId: DEFAULT_ID,
      lscOrgUnitName: '--',
      clubInputLabel: getClubInputLabel(lscOrgUnitObj?.orgUnitId || DEFAULT_ID),
      clubOrgUnitId: '',
      clubOrgUnitName: ''
    });
    setIsDirty(true);
  };

  const onLscOrgUnitChanged = (lscOrgUnitId, lscOrgUnitName) => {
    setFormState({
      ...formState,
      zoneOrgUnitId: DEFAULT_ID,
      zoneOrgUnitName: '--',
      lscOrgUnitId,
      lscOrgUnitName,
      clubInputLabel: getClubInputLabel(lscOrgUnitObj?.orgUnitId || lscOrgUnitId),
      clubOrgUnitId: '',
      clubOrgUnitName: ''
    });
    setIsDirty(true);
  };

  const onClubOrgUnitChanged = (clubOrgUnitId, clubOrgUnitName) => {
    if (clubOrgUnitId && clubOrgUnitId !== DEFAULT_ID) {
      setFormState({
        ...formState,
        zoneOrgUnitId: DEFAULT_ID,
        zoneOrgUnitName: '--',
        clubOrgUnitId,
        clubOrgUnitName
      });
    } else {
      setFormState({
        ...formState,
        clubOrgUnitId,
        clubOrgUnitName
      });
    }

    setIsDirty(true);
  };

  const onDisplayOptionChanged = (displayOptionId) => {
    const displayOption = DISPLAY_OPTIONS.find((x) => x.id === displayOptionId);

    setFormState({
      ...formState,
      displayOptionId: displayOption.id,
      displayOptionName: displayOption.name,
      showBestTimesOnly: displayOption.value
    });
    setIsDirty(true);
  };

  const onClearFormClicked = () => {
    const defaultGender = eventCompetitionGendersDwState.options[0];
    const defaultTimeStandard = timeStandardsDwState.options[0];

    setReportParametersState(null);
    setFormState({
      ...INITIAL_FORM_STATE,
      eventCompetitionGenderTypeId: defaultGender.id || DEFAULT_ID,
      eventCompetitionGenderTypeName: defaultGender.name || '--',
      timeStandardId: defaultTimeStandard.id || DEFAULT_ID,
      timeStandardName: defaultTimeStandard.name || '--'
    });
  };

  useEffect(() => {
    if (eventCompetitionGendersDwState.areOptionsLoaded === true 
      && timeStandardsDwState.areOptionsLoaded === true) {
      const defaultGender = eventCompetitionGendersDwState.options[0];
      const defaultTimeStandard = timeStandardsDwState.options[0];

      setFormState({
        ...formState,
        clubInputLabel: getClubInputLabel(lscOrgUnitObj?.orgUnitId || formState.lscOrgUnitId),
        eventCompetitionGenderTypeId: defaultGender.id || DEFAULT_ID,
        eventCompetitionGenderTypeName: defaultGender.name || '--',
        timeStandardId: defaultTimeStandard.id || DEFAULT_ID,
        timeStandardName: defaultTimeStandard.name || '--'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventCompetitionGendersDwState, timeStandardsDwState, lscOrgUnitObj]);

  function formatBeforeSubmit(formState) {
    const zoneOrgUnitId = formState.zoneOrgUnitId;
    const lscOrgUnitId = lscOrgUnitObj?.orgUnitId || formState.lscOrgUnitId;
    const clubOrgUnitId = clubOrgUnitObj?.orgUnitId || formState.clubOrgUnitId;

    const submitObj = {
      zoneOrgUnitId: zoneOrgUnitId && zoneOrgUnitId !== DEFAULT_ID ? zoneOrgUnitId : undefined,
      lscOrgUnitId: lscOrgUnitId && lscOrgUnitId !== DEFAULT_ID ? lscOrgUnitId : undefined,
      clubOrgUnitId: clubOrgUnitId && clubOrgUnitId !== DEFAULT_ID ? clubOrgUnitId : undefined,

      reportingYearKey: formState.reportPeriodId > 0 ? formState.reportPeriodId : undefined,
      timeStandardKey: formState.timeStandardId > 0 ? formState.timeStandardId : undefined,
      eventCompetitionCategoryKey: formState.eventCompetitionGenderTypeId > 0 ? formState.eventCompetitionGenderTypeId : undefined,
      swimEventKey: formState.events?.length > 0 ? formState.events.map(x => { return x.id }) : undefined,

      startDate: formState.startDate !== BLANK_DATE_STRING ? formState.startDate : undefined,
      endDate: formState.endDate !== BLANK_DATE_STRING ? formState.endDate : undefined,
      minAge: formState.minAge || undefined,
      maxAge: formState.maxAge || undefined,
      eligibleToReprTeamUSA: formState.isUsCitizen === true ? true : false,
      fastestOnly: formState.showBestTimesOnly === true ? true : undefined,
      maxResults: formState.maxResults
    };


    setReportParametersState({
      ...submitObj,
      zoneId: zoneOrgUnitId && zoneOrgUnitId !== DEFAULT_ID ? zoneOrgUnitId : undefined,
      lscId: lscOrgUnitId && lscOrgUnitId !== DEFAULT_ID ? lscOrgUnitId : undefined,
      clubId: clubOrgUnitId && clubOrgUnitId !== DEFAULT_ID ? clubOrgUnitId : undefined,
      swimEventKey: Array.isArray(submitObj.swimEventKey) ? submitObj.swimEventKey.toString() : undefined,
      eligibleToReprTeamUSA: submitObj.eligibleToReprTeamUSA === true ? 1 : 0,
      fastestOnly: submitObj.fastestOnly ? 1 : 0,
      zoneOrgUnitId: undefined,
      lscOrgUnitId: undefined,
      clubOrgUnitId: undefined
    });
    onSubmitFormCallback(submitObj);
  }

  function getClubInputLabel(lscOrgUnitId) {
    if (lscOrgUnitId && lscOrgUnitId !== DEFAULT_ID) {
      return USAS_ORG_UNIT_LABEL;
    } else {
      return USAS_ORG_UNIT_LABEL_LSC;
    }
  }

  return {
    isLoading: eventCompetitionGendersDwState.isArrayLoading || eventsDwState.isArrayLoading || timeStandardsDwState.isArrayLoading || reportPeriodSeasonsDwState.isArrayLoading,
    organizationCode: ORG_CODE,
    displayOptions: DISPLAY_OPTIONS,
    reportParametersState,
    formState,
    errorState,
    updateFormState,
    onValueTextPairChanged,
    handleSubmit,
    onZoneOrgUnitChanged,
    onLscOrgUnitChanged,
    onClubOrgUnitChanged,
    onDisplayOptionChanged,
    onClearFormClicked
  };
};

export default useTopTimesSearchForm;