import { Fragment } from 'react';

import TimesEvents from '../../../../common/components/searches/searchTime/components/TimesEvents';
import PrimaryButton from '../../../../common/components/buttons/PrimaryButton';
import SecondaryButton from '../../../../common/components/buttons/SecondaryButton';
import YesNoSwitch from '../../../../common/components/yesNoSwitch/YesNoSwitch';
import DatePicker from '../../../../common/components/datepickers/DatePicker';
import Dropdown from '../../../../common/components/dropdowns/Dropdown';
import Input from '../../../../common/components/inputs/Input';
import Headings from '../../../../common/components/headings/Headings';
import TimeInput from '../../../../common/components/inputs/TimeInput';
import SessionDropdown from '../../../../common/components/dropdowns/sessionDropdown/SessionDropdown';

import PopUpModal from '../../../../common/components/dialogs/PopUpModal';
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const AddEditForm = ({ state, formState, errorState, timeState, eventState, onFormValueChanged, onValueTextPairChanged,
  onSubmit, resetForm, onShowPopup }) => {
  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>Time Info</Headings.H3>
        </div>
      </div>
      <form onSubmit={onSubmit} noValidate>
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimesEvents
              label="Event*"
              eventName={"eventId"}
              eventValue={formState.eventName}
              eventId={errorState.eventId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'eventId', newValueLabel, 'eventName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Event Gender*"
              name="eventGenderId"
              options={state.competitionGenderOptions}
              isLoading={false}
              areValuesIntegers={true}
              value={formState.eventGenderId}
              error={errorState.eventGenderId}
              message={errorState.eventGenderId}
              onChange={(id, name) => { onValueTextPairChanged(id, 'eventGenderId', name, 'eventGenderName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <SessionDropdown
              label="Session"
              name="sessionId"
              value={formState.sessionId}
              error={errorState.sessionId}
              message={errorState.sessionId}
              onChange={(id, name) => { onValueTextPairChanged(id, 'sessionId', name, 'sessionName'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeInput
              label="Entry Time"
              name="entryTime"
              value={formState.entryTime}
              error={errorState.entryTime}
              message={errorState.entryTime}
              onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'entryTime', value, 'entryTimeValue'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <TimeInput
              label="Swim Time*"
              name="swimTime"
              value={formState.swimTime}
              error={errorState.swimTime}
              message={errorState.swimTime}
              onChange={(displayValue, value) => { onValueTextPairChanged(displayValue, 'swimTime', value, 'swimTimeValue'); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker
              label="Swim Date*"
              name="swimDate"
              value={formState.swimDate}
              error={errorState.swimDate}
              message={errorState.swimDate}
              onChange={(value) => { onFormValueChanged('swimDate', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Place"
              name="place"
              type="number"
              min="0"
              value={formState.place}
              error={errorState.place}
              message={errorState.place}
              onChange={(value) => { onFormValueChanged('place', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <YesNoSwitch
              label="Relay Lead-Off?"
              name="isRelayLeadOff"
              checked={formState.isRelayLeadOff === true}
              error={errorState.isRelayLeadOff}
              message={errorState.isRelayLeadOff}
              onChange={(value) => { onFormValueChanged('isRelayLeadOff', value); }} />
          </div>
        </div>
        {errorState.errorOnSubmitAction &&
          <div className="row">
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{errorState.errorOnSubmitAction || ''}</p>
            </div>
          </div>
        }
        <div className="row usas-extra-top-margin usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin">
            <PrimaryButton type="submit">Save</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={resetForm}>Cancel</SecondaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onShowPopup}>Delete</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={timeState.isObjLoading || eventState.isArrayLoading} />
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={timeState.isSaving} />
    </Fragment>
  );
};

export default AddEditForm;