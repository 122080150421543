import React, { createContext, useState } from 'react';

export const TimeStandardFiltersStateContext = createContext();

const INITIAL_STATE = {
  filterObject: {},
  sortBy: ''
};

const TimeStandardFiltersContextProvider = ({ children }) => {
  const stateHook = useState(INITIAL_STATE);

  return (
    <TimeStandardFiltersStateContext.Provider value={stateHook}>
      {children}
    </TimeStandardFiltersStateContext.Provider>
  );
};

export default TimeStandardFiltersContextProvider;