import React, { Fragment } from 'react';

import useSplitsRow from './UseSplitsRow';

import global from '../../../../common/components/GlobalStyle.module.css';
import Input from '../../../../common/components/inputs/Input';

const SplitRow = ({ rowState, onRowChanged }) => {
  const { onTimeChanged } = useSplitsRow(rowState, onRowChanged);
  return (
    <Fragment>
      <div className={global.SmallTableRow} key={rowState.splitId}>
        <div className={global.SmallTableRowHead}>
          <span className='hidden-xs'>Split {rowState.splitNumber}</span>&nbsp;
        </div>
        <div className={global.SmallTableRowInfo}>
          <div className='row'>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Split</div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowData].join(' ')}>{rowState.splitNumber}</div>
            <div className={['col-xs-6 col-sm-3 visible-xs', global.SmallTableRowLabels].join(' ')}>Cumulative Dist.</div>
            <div className={['col-xs-6 col-sm-3 visible-sm', global.SmallTableRowLabels].join(' ')}>Cumulative Distance</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{rowState.cumulativeDistance}</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}><div style={{ paddingTop: "15px" }}>Time</div></div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>
              <Input
                label=""
                name={`splitTime${rowState.splitNumber}`}
                value={rowState.splitTime}
                onChange={onTimeChanged} />
            </div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowLabels].join(' ')}>Calculated Swim Time</div>
            <div className={['col-xs-6 col-sm-3', global.SmallTableRowData].join(' ')}>{rowState.calculatedSwimTime}</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const TimeSplitsIndividualSmallGrid = ({ timeState, splitsState, onRowChanged }) => {
return (
  <div className={[global.SmallTable, 'hidden-md hidden-lg'].join(' ')}>
    {
      timeState.isArrayLoading === true || splitsState.timeSplits === undefined
      ? (<tr>
        <td colSpan="8">Loading...</td>
      </tr>)
      : Array.isArray(splitsState.timeSplits) && splitsState.timeSplits.length > 0
        ? splitsState.timeSplits.map((rowState, i) =>
          <SplitRow onRowChanged={onRowChanged} rowState={rowState} key={i} />)
        : (
          <div className={global.SmallTableRow}>
            <div className={global.SmallTableRowHead}>&nbsp;</div>
            <div className={global.SmallTableRowInfo}>
              <div className={global.SmallTableRowLabels}>No Splits</div>
            </div>
          </div>
        )}
  </div>);
};

export default TimeSplitsIndividualSmallGrid;